import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { AuthGuardService } from '../auth-guard.service';
import { InternalMenuService } from '../internal-menu.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Permission } from '../models/tagpermission';

@Component({
  selector: 'app-internal.workstation',
  templateUrl: './internal.workstation.component.html',
  styleUrls: ['./internal.workstation.component.scss']
})
export class InternalWorkstationComponent implements OnInit {

  @ViewChild('pinInput1') pinInput1: ElementRef;

  menuOpen: boolean = true;
  shadowVisible: boolean = false;

  loginError: string = "";

  pinForm: FormGroup = new FormGroup({
    digit1: new FormControl(''),
    digit2: new FormControl(''),
    digit3: new FormControl(''),
    digit4: new FormControl(''),
  });

  reloadTimer: any = undefined;

  onDigitInput(event: KeyboardEvent){
    let element: HTMLInputElement;
    let nextElement: HTMLInputElement;

    element = event.target as HTMLInputElement;

    let doCommence = false;

    if (event.key.match(/[0-9]/)) {
      element.value = event.key;
      let idx = parseInt(element.id.split('-')[2]);
      this.pinForm.controls['digit' + (idx)].setValue(event.key);
      doCommence = true;
    } else if (event.code === 'Backspace') {
      element.value = '';
      let idx = parseInt(element.id.split('-')[2]);
      this.pinForm.controls['digit' + (idx)].setValue('');
      doCommence = true;
    }
    event.preventDefault();

    if (!doCommence) return;

    this.pinForm.setErrors(null);

    if (event.code !== 'Backspace') nextElement = element.nextElementSibling as HTMLInputElement;
    if (event.code === 'Backspace') nextElement = element.previousElementSibling as HTMLInputElement;

    if (nextElement == null) {
      this.submitLogin();
    } else {
      nextElement.focus();
      nextElement.setSelectionRange(0, 1);
    }
  }

  constructor(private api: ApiService, private router: Router, private authGuard: AuthGuardService, private menuBtnSvc: InternalMenuService) {}

  isKioskMode() {
    return this.user().hasPermission(Permission.kiosk_mode);
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      if (this.isKioskMode()) {
        this.startReloadTimer();
      }
    });

    this.menuBtnSvc.isOpen.subscribe((isOpen) => {
      if (isOpen) {
        this.shadowVisible = true;
        setTimeout(() => {
          this.menuOpen = true;
        }, 10);
      } else {
        this.menuOpen = false;
        setTimeout(() => {
          this.shadowVisible = false;
        }, 300);
      }
    });
  }

  isLoggedIn() {
    return this.api.isLoggedIn();
  }

  user() {
    return this.api.user;
  }

  hasPermission(group: string): boolean | Promise<boolean> {
    return this.authGuard.canActivateUrl(group);
  }

  closeMenu() {
    this.menuBtnSvc.toggleMenu(false);
  }

  toggleMenu() {
    this.menuBtnSvc.toggleMenu();
  }

  startReloadTimer() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }

    this.reloadTimer = setTimeout(() => {
      location.reload();
    }, 1000 * 60 * 60 * 4);
  }

  submitLogin() {
    if (this.pinForm.invalid) return this.resetForm();

    let pin = this.pinForm.value.digit1 + this.pinForm.value.digit2 + this.pinForm.value.digit3 + this.pinForm.value.digit4;

    this.api.pinLogin(pin).then((res) => {
      this.loginError = "";
      this.pinForm.setValue({
        digit1: '',
        digit2: '',
        digit3: '',
        digit4: '',
      });

      if (this.isKioskMode()) {
        this.startReloadTimer();
      }
    }).catch((err) => {
      console.log(err);
      this.loginError = err.message;
      this.pinForm.setErrors({'invalid': true});
      this.resetForm();
    });
  }

  resetForm() {
    this.pinForm.setValue({
      digit1: '',
      digit2: '',
      digit3: '',
      digit4: '',
    });

    this.pinInput1.nativeElement.focus();
  }

  logout() {
    this.api.logout();
    this.router.navigate(['workstation']);
  }

}
