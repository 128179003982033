import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { Permission } from "../models/tagpermission";
import { TAGEvent } from "../models/tagevent";
import { TAGRequest } from "../models/tagrequest";

@Component({
  selector: "app-internalDashboard",
  templateUrl: "./internal.dashboard.component.html",
  styleUrls: ["./internal.dashboard.component.scss"],
})
export class InternalDashboardComponent implements OnInit {
  requests: TAGRequest[] = [];
  events: TAGEvent[] = [];

  constructor(private api: ApiService) {}

  hasPermission(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.api.getEvents(true).then((result) => {
        this.events = result;
      }).catch((reason) => {
        //TODO: Show Error
      });

      this.api.getRequests().then((result) => {
        this.requests = result;
      }).catch((reason) => {
        //TODO: Show Error
      });
    });
  }
}
