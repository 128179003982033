import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { TAGInvItemInstance, TAGInvItemType } from "../models/taginventory";
import { PrinterService } from "../printer.service";
import { getInventoryInstanceByCode } from "./inventoryHelper";

export interface ItemScanDialogData {}

export interface ItemInstanceCombo {
  item: TAGInvItemType;
  instance?: TAGInvItemInstance;
  instanceID: string;
}

@Component({
  selector: "int-inventory-itemscandialog",
  templateUrl: "itemScanDialog.html",
  styleUrls: ["./itemScanDialog.scss"],
})
export class ItemScanDialog {
  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  scannerForm = this.fb.group({
    input: [""],
  });

  scannedInstances: { combo: ItemInstanceCombo; back: boolean }[] = [];

  constructor(
    private api: ApiService,
    private printer: PrinterService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ItemScanDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ItemScanDialogData
  ) {
    this.dialogRef.disableClose = true;
  }

  printerSupported(): boolean {
    return this.printer.isSupported();
  }

  printerConnected(): boolean {
    return this.printer.isConnected();
  }

  printerPrinting(): boolean {
    return this.printer.isPrintingLabels();
  }

  connectPrinter() {
    this.printer.connectToPrinter();
  }

  ngAfterViewInit() {
    this.resetScannerField();
  }

  resetScannerField() {
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  scannedItemHasError(): boolean {
    var error: boolean = false;
    for (let combo of this.scannedInstances) {
      if (!combo.back) {
        error = true;
        break;
      }
    }
    return error;
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: { multiple: true },
    });

    dialogRef.componentInstance.onCodeScan.subscribe((code: string) => {
      this.codeScanned(code);
      this.resetScannerField();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.resetScannerField();
    });
  }

  codeScanned(code: string) {
    getInventoryInstanceByCode(code, this.api).then((result) => {
      let item = result.item;
      let instanceID = result.instanceID;

      console.log(result);

      if (item && instanceID) {
        this.itemScanned(item, item.findInstance(instanceID));
      }
    }).catch((reason) => {
      console.log(reason);
      this.api.getInvItemGroup(code).then((group) => {
        for (let gItem of group.items) {
          this.api.getInvItem(gItem.itemid).then((item) => {
            this.itemScanned(item, item.findInstance(gItem.instanceid));
          }).catch((reason) => {
            console.error(reason);
          });
        }
      }).catch((reason) => {
        console.error(reason);
      });
    }).finally(() => {
      this.resetScannerField();
    });
  }

  itemScanned(item: TAGInvItemType, instance: TAGInvItemInstance) {
    // IF this is not yet scanned
    if (this.scannedInstances.findIndex((combo) => combo.combo.item._id == item._id && combo.combo.instanceID == instance.itemid) == -1) {

      // Add Item to Scanned Instances
      this.scannedInstances.push({
        combo: {
          item: item,
          instance: instance,
          instanceID: instance.itemid,
        } as ItemInstanceCombo,
        back: true,
      });
    }

    // Check if Item is booked somewhere else
    this.api.getInvTransactionsByItem(item._id).then((result) => {
      // Find Transactions with Instance
      var filtered = result.filter((trans) => trans.findItem(item._id, instance.itemid) != null);

      if (filtered.length > 0) {
        // Sort by newest
        filtered.sort((a, b) => {
          return a.startdate > b.startdate ? -1 : 1;
        });
        var lastTrans = filtered[0];

        if (lastTrans) {
          // Set is not yet returned (for display)
          this.scannedInstances.find((combo) => combo.combo.item._id == item._id && combo.combo.instanceID == instance.itemid).back =
            lastTrans.findItem(item._id, instance.itemid).back != null;
        }
      }
    }).catch((reason) => {
      console.log(reason);
    });
  }

  cancel(): void {
    this.dialogRef.close([]);
  }

  finish(): void {
    var filteredCombos = this.scannedInstances.filter((combo) => !combo.combo.instance.deleted);
    this.dialogRef.close(filteredCombos.map((combo) => combo.combo));
  }

  deleteInstance(combo: ItemInstanceCombo) {
    this.scannedInstances.splice(
      this.scannedInstances.findIndex((inst) => inst.combo.item._id == combo.item._id && inst.combo.instanceID == combo.instanceID),
      1
    );
    this.resetScannerField();
  }

  printLabelsAll() {
    if (!this.printer.isConnected()) {
      this.printer
        .connectToPrinter()
        .then((result) => {
          this.scannedInstances.forEach((combo) => {
            this.printer.printItemLabels(combo.combo.item, [combo.combo.item.instances.find((inst) => inst.itemid == combo.combo.instanceID)]);
          });
        })
        .catch((reason) => {
          console.error(reason);
        });
    } else {
      this.scannedInstances.forEach((combo) => {
        this.printer.printItemLabels(combo.combo.item, [combo.combo.item.instances.find((inst) => inst.itemid == combo.combo.instanceID)]);
      });
    }
  }
}
