import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Socket } from "ngx-socket-io";
import { BreakState, PlayerState, Song, SongWish } from "../internal.schulradio/internal.schulradio.component";

@Component({
  selector: "app-schulradio",
  templateUrl: "./schulradio.component.html",
  styleUrls: ["./schulradio.component.scss"],
})
export class SchulradioComponent implements OnInit {
  loggedIn: boolean = false;
  loginError: string = "";

  playerState: PlayerState = new PlayerState({});
  playlist: Song[] = [];
  voteList: SongWish[] = [];
  breakState: BreakState = new BreakState({});

  suggestedSongs: number = 0;
  maxSuggestedSongs: number = 0;
  upvotes: number[] = [];
  downvotes: number[] = [];

  timeInterpolationTimer: any = null;

  constructor(private socket: Socket, public dialog: MatDialog) {}

  hasVoted(songWish: number) {
    return this.upvotes.includes(songWish) || this.downvotes.includes(songWish);
  }
  nextBreakRemaining(): number {
    return Math.round((this.breakState.nextBreak.getTime() - new Date().getTime()) / 1000);
  }

  ngOnInit(): void {
    this.socket.on("playerState", (data) => {
      console.log(data);
      this.playerState = new PlayerState(data);
    });

    this.socket.on("playlistState", (data) => {
      this.playlist = data.playlist;
      this.voteList = data.voteList;

      console.log(data);
    });

    this.socket.on("breakState", (data) => {
      this.breakState = new BreakState(data);
      console.log(this.breakState);
    });

    this.socket.on("login", (data) => {
      this.loggedIn = data.success;
      localStorage.setItem("schulradio-clientID", data.clientID);
      if (!data.success) {
        this.loginError = data.message;
      }
    });

    this.socket.on("suggestSong", (data) => {
      console.log(data);
    });

    this.socket.on("voteSong", (data) => {
      console.log(data);
    });

    this.socket.on("clientUpdate", (data) => {
      console.log(data);
      this.suggestedSongs = data.suggestedSongs;
      this.maxSuggestedSongs = data.maxSuggestedSongs;
      this.upvotes = data.upvotes;
      this.downvotes = data.downvotes;
    });

    this.socket.emit("login", { clientID: localStorage.getItem("schulradio-clientID") });

    this.startTimeInterpolator();
  }

  startTimeInterpolator() {
    if (this.timeInterpolationTimer) {
      this.stopTimeInterpolator();
    }

    this.timeInterpolationTimer = setInterval(() => {
      if (this.breakState && this.breakState.break) this.breakState.remaining = Math.max(this.breakState.remaining - 1, 0);
    }, 1000);
  }

  stopTimeInterpolator() {
    clearInterval(this.timeInterpolationTimer);
  }

  openSuggestionDialog() {
    const dialogRef = this.dialog.open(SchulradioSuggestionDialog, {
      width: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Search result: " + result);
      if (result && result.trim() != "") {
        this.socket.emit("suggestSong", { query: result.trim() });
      }
    });
  }

  voteSong(id, positive) {
    this.socket.emit("voteSong", { id: id, positive: positive });
  }
}

@Component({
  selector: "sr-suggestion-dialog",
  templateUrl: "schulradio-suggestion.dialog.html",
})
export class SchulradioSuggestionDialog {
  query: string = "";

  constructor(public dialogRef: MatDialogRef<SchulradioSuggestionDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
