import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "img[imgFallback]",
})
export class ImgFallbackDirective {
  @Input() imgFallback: string;

  constructor(private eRef: ElementRef) {}

  @HostListener("error")
  loadFallbackOnError() {
    const element = this.eRef.nativeElement;
    element.src = this.imgFallback;
  }
}
