<div id="gallery-wrapper">
  <div id="categorycard">
    <div class="categorytitle">{{ "gallery.categories" | translate }}</div>
    <div *ngFor="let cat of this.categories" [ngClass]="{ selected: this.selectedCategory == cat }" class="categorylink" (click)="this.changeCategory(cat)">
      {{ cat.title }}
    </div>
  </div>

  <div id="imageview">
    <div *ngIf="(this.selectedCategory ? this.selectedCategory.title : '') != ''" id="imageview_title">
      {{ this.selectedCategory ? this.selectedCategory.title : "" }}
    </div>

    <div
      *ngIf="(this.selectedCategory ? this.getFormattedHTML(this.selectedCategory.description) : '') != ''"
      id="imageview_description"
      innerHTML="{{ this.selectedCategory ? this.getFormattedHTML(this.selectedCategory.description) : '' }}"></div>

    <div id="imageview_spacer"></div>

    <div *ngIf="this.images.length < 1" id="imageview_noimage">
      <img src="assets/images/noimages.png" alt="No Images Placeholder" />
    </div>

    <ngx-masonry [options]="{ transitionDuration: '0.2s', gutter: 10, resize: true, initLayout: true, fitWidth: true }" [useImagesLoaded]="true">
      <div ngxMasonryItem *ngFor="let item of images" class="masonry-item" (click)="openDialog(item)">
        <img src="{{ this.getThumbLink(item._id) }}" alt="Thumbnail: {{ item.title }}" />
      </div>
    </ngx-masonry>
  </div>
</div>
