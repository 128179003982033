<div id="int-dashboard-container">
  <internal-menu-button id="menuBtn"></internal-menu-button>

  <div id="int-dashboard-title">
    {{ "internal.dashboard.title" | translate }}
  </div>

  <div id="dashboard-actiongrid-container">
    <div *ngIf="hasPermission('create_requests')" class="grid-item square touch" routerLink="../requests" routerLinkActive="active">
      <mat-icon class="grid-item_bigicon">upload_file</mat-icon>
      <div class="grid-item_text">
        {{ "internal.dashboard.quick.submitRequest" | translate }}
      </div>
    </div>

    <div *ngIf="hasPermission('create_events')" class="grid-item square touch" routerLink="../events" routerLinkActive="active">
      <mat-icon class="grid-item_bigicon">event</mat-icon>
      <div class="grid-item_text">
        {{ "internal.dashboard.quick.createEvent" | translate }}
      </div>
    </div>

    <div *ngIf="hasPermission('create_image')" class="grid-item square touch" routerLink="../gallery" routerLinkActive="active">
      <mat-icon class="grid-item_bigicon">add_a_photo</mat-icon>
      <div class="grid-item_text">
        {{ "internal.dashboard.quick.uploadImages" | translate }}
      </div>
    </div>

    <div *ngIf="hasPermission('view_presence')" class="grid-item square touch" routerLink="../presence" routerLinkActive="active">
      <mat-icon class="grid-item_bigicon">how_to_reg</mat-icon>
      <div class="grid-item_text">
        {{ "internal.dashboard.quick.viewPresence" | translate }}
      </div>
    </div>
  </div>

  <div class="hor-spacer"></div>

  <div id="dashboard-infogrid-container">
    <div class="grid-item">
      <div class="grid-item_title">
        {{ "internal.dashboard.requestsummary" | translate }}
      </div>

      <div class="grid-item_content">
        <div class="int-dashboard-listcontainer">
          <div class="int-dashboard-listitem" *ngFor="let request of this.requests">
            <div class="int-dashboard-listitem-requestname">{{ request.name }}</div>
            <div class="int-dashboard-listitem-requestdate">{{ request.startdate | date }}</div>
            <div class="int-dashboard-listitem-spacer"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-item">
      <div class="grid-item_title">
        {{ "internal.dashboard.eventsummary" | translate }}
      </div>

      <div class="grid-item_content">
        <div class="int-dashboard-listcontainer">
          <div class="int-dashboard-listitem" *ngFor="let event of this.events">
            <div class="int-dashboard-listitem-requestname">{{ event.name }}</div>
            <div class="int-dashboard-listitem-requestdate">{{ event.startdate | date }}</div>
            <div class="int-dashboard-listitem-spacer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
