<div id="musicwish-box">
  <div id="musicwish-title">
    {{ "musicwish.title" | translate }}
  </div>

  <div *ngIf="!api.config.enableMusicWishes" class="musicwish-timeout">
    {{ "musicwish.disabled" | translate }}
  </div>

  <div *ngIf="api.config.enableMusicWishes" style="display: contents;">

    <div class="musicwish-subtitle">
      {{ "musicwish.wishformTitle" | translate }}
    </div>

    <div *ngIf="timeout() > 300" id="musicwish-form">
      <mat-form-field appearance="outline">
        <mat-label>{{ "musicwish.inputTitle" | translate }}</mat-label>
        <input matInput [(ngModel)]="musicwish.title" />
        <mat-icon matSuffix>music_note</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "musicwish.inputArtist" | translate }}</mat-label>
        <input matInput [(ngModel)]="musicwish.artist" />
        <mat-icon matSuffix>face</mat-icon>
      </mat-form-field>

      <button mat-stroked-button color="primary" (click)="sendWish()">{{ "musicwish.send" | translate }}</button>
    </div>

    <div *ngIf="timeout() <= 300" class="musicwish-timeout">
      {{ "musicwish.timeout" | translate: { seconds: timeoutStr() } }}
    </div>

    <div class="h-spacer"></div>

    <div class="musicwish-list">

      <div class="musicwish-subtitle">
        {{ "musicwish.votelistTitle" | translate }}
      </div>

      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="{{ 'general.search' | translate }}" />
        <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="filterValue = ''; applyFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div *ngFor="let wish of filteredMusicWishes" class="musicwish-item">
        <span class="mwi-title">{{wish.title}}</span>
        <span class="mwi-artist">{{wish.artist}}</span>
        <span class="mwi-votes">{{wish.votes}}</span>

        <button [disabled]="timeoutVote() <= 300" class="mwi-btn" mat-icon-button (click)="vote(wish._id)"><mat-icon>{{timeoutVote() > 300? "voting_chip": "hourglass_empty"}}</mat-icon></button>
      </div>
    </div>

    <div class="h-spacer"></div>

    <div class="musicwish-list">

      <div class="musicwish-subtitle">
        {{ "musicwish.playedListTitle" | translate }}
      </div>

      <div *ngFor="let wish of playedWishes()" class="musicwish-item">
        <span class="mwi-title">{{wish.title}}</span>
        <span class="mwi-artist">{{wish.artist}}</span>
        <span class="mwi-votes">{{wish.votes}}</span>
      </div>
    </div>
  </div>
</div>
