import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { ApiService } from '../api.service';
import { openDeleteDialog } from '../dialogs/deleteDialog/deleteDialog';
import { Permission } from '../models/tagpermission';
import { TAGEvent } from '../models/tagevent';
import { TAGTransaction } from '../models/tagfinances';
import { TAGUser } from '../models/taguser';
import { FinanceTransactionEditDialog } from './financeTransactionEditDialog';

@Component({
  selector: 'app-internal.finances',
  templateUrl: './internal.finances.component.html',
  styleUrls: ['./internal.finances.component.scss']
})
export class InternalFinancesComponent implements OnInit {
  displayedColumns: string[] = ['date', 'reason', 'user', 'event', 'amount', 'edit'];

  users: TAGUser[] = [];
  events: TAGEvent[] = [];

  transactions: TAGTransaction[] = [];
  selectedTransactions: string[] = [];

  userControl = new FormControl("");
  eventControl = new FormControl("");

  filteredUsers: Observable<TAGUser[]>;
  filteredEvents: Observable<TAGEvent[]>;

  filterActive: boolean = false;
  filterReason: string = undefined;
  filterUser: string = undefined;
  filterEvent: string = undefined;
  filterIncome: boolean = undefined;
  filterPending: boolean = undefined;

  filteredTransactions: TAGTransaction[] = [];

  constructor(private api: ApiService, public dialog: MatDialog, private router: Router) {
    this.filteredUsers = this.userControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._userFilter(value || ""))
    );

    this.filteredEvents = this.eventControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._eventFilter(value || ""))
    );
  }

  _userFilter(filter: String): TAGUser[] {
    const filterValue = filter.toLowerCase();

    return this.users.filter((user) =>
      user.username.toLowerCase().includes(filterValue) ||
      user.firstname.toLowerCase().includes(filterValue) ||
      user.lastname.toLowerCase().includes(filterValue) ||
      user.email.toLowerCase().includes(filterValue)
    );
  }

  _eventFilter(filter: String): TAGEvent[] {
    const filterValue = filter.toLowerCase();

    return this.events.filter((event) =>
      event.name.toLowerCase().includes(filterValue)
    );
  }

  getUserName(username: string) {
    var usr = this.users.find((u) => u.username == username);
    if (usr != undefined) return usr.firstname + " " + usr.lastname;

    return username;
  }

  getEventName(eventId: string) {
    var ev = this.events.find((e) => e._id == eventId);
    if (ev != undefined) return ev.name;

    return eventId;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  totalAmount(withPending: boolean = false) {
    var total = 0;
    let trans = withPending ? this.filteredTransactions : this.filteredTransactions.filter((t) => !t.pending);

    trans.forEach((t) => {
      let nAmount = typeof t.amount == "string" ? parseFloat(t.amount) : t.amount;
      total += (t.isIncome? nAmount : -nAmount);
    });
    return total;
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.reloadTransactions();
      this.api.getUsers().then((result) => {
        this.users = result;
      }).catch((reason) => {

      });

      this.api.getEvents(false).then((result) => {
        this.events = result;
      }).catch((reason) => {
        console.error(reason);
      });
    });
  }

  filter() {
    this.filterActive = true;

    this.filteredTransactions = this.transactions.filter((t) => {
      if (this.filterReason != undefined) {
        if (!t.reason.toLowerCase().includes(this.filterReason.toLowerCase())) return false;
      }

      if (this.filterUser != undefined) {
        if (t.user == undefined) return false;
        if (t.user != this.filterUser && !this.getUserName(t.user).toLowerCase().includes(this.filterUser.toLowerCase())) return false;
      }

      if (this.filterEvent != undefined) {
        if (t.event == undefined) return false;
        if (t.event != this.filterEvent && !this.getEventName(t.event).toLowerCase().includes(this.filterEvent.toLowerCase())) return false;
      }

      if (this.filterIncome != undefined && t.isIncome != this.filterIncome) return false;

      if (this.filterPending != undefined && t.pending != this.filterPending) return false;

      return true;
    });
  }

  cancelFilter() {
    this.filterActive = false;
    this.filterReason = undefined;
    this.filterUser = undefined;
    this.filterEvent = undefined;
    this.filterIncome = undefined;
    this.filterPending = undefined;

    this.filteredTransactions = this.transactions;
  }

  selectTransaction(transaction: TAGTransaction) {
    if (this.selectedTransactions.includes(transaction._id)) {
      this.selectedTransactions.splice(this.selectedTransactions.indexOf(transaction._id), 1);
    } else {
      this.selectedTransactions.push(transaction._id);
    }
  }

  startInvoice() {
    this.router.navigate(["/internal/invoice"], { state: {
      user: this.api.user.username,
      availableItems: this.transactions.filter((t) => this.selectedTransactions.includes(t._id) && !t.isIncome)
    }});
  }

  reloadTransactions() {
    this.api.getFinanceTransactions().then((result) => {
      this.transactions = result.sort((a, b) => {
        return b.date.getTime() - a.date.getTime();
      });

      this.filter();
    }).catch((reason) => {
      console.error(reason);
    });
  }

  newTransaction() {
    const dialogRef = this.dialog.open(FinanceTransactionEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { transaction: new TAGTransaction(), users: this.users, events: this.events },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadTransactions();
    });
  }

  editTransaction(transaction: TAGTransaction) {
    const dialogRef = this.dialog.open(FinanceTransactionEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { transaction: transaction, users: this.users, events: this.events },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadTransactions();
    });
  }

  deleteTransaction(transaction: TAGTransaction) {
    openDeleteDialog(this.dialog, "Transaction", [transaction.reason]).afterClosed().subscribe((result) => {
      if (result) {
        this.api.deleteFinanceTransaction(transaction._id).then((result) => {
          this.reloadTransactions();
        }).catch((reason) => {
          console.error(reason);
        });
      }
    });
  }
}
