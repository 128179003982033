<div *ngIf="loggedIn" id="sr-content">
  <div *ngIf="!breakState.break && breakState.enabled" id="break-info">
    <div>{{ "radio.noBreak" | translate }}</div>
    <span>{{ "radio.nextBreakIn" | translate }} {{ nextBreakRemaining() | formatTime }}</span>
  </div>

  <div *ngIf="!breakState.break && !breakState.enabled" id="break-info">
    <div>{{ "radio.disabled" | translate }}</div>
  </div>

  <div *ngIf="breakState.break" id="media-info">
    <player-widget [playerState]="playerState" [showYoutubeLink]="true"></player-widget>

    <div id="playlist">
      <div *ngFor="let song of playlist; let i = index">
        <div *ngIf="!song.isVote || song.title != ''" class="song">
          <div class="song-info">
            <div class="song-title">
              {{ song.title }}
            </div>
            <div class="song-artist">
              {{ song.artist }}
            </div>
          </div>

          <div class="song-info2">
            <div class="song-status">
              {{ (i == 0 ? "radio.next" : "") | translate }}
            </div>
            <div class="song-duration">
              {{ song.duration | formatTime }}
            </div>
          </div>
        </div>

        <div *ngIf="song.isVote && song.title == ''" class="song">
          <div class="song-info">
            <div class="song-voting">
              {{ "radio.openForVoting" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="playlist.length < 1" id="empty-playlist">
        {{ "radio.noSongsInPlaylist" | translate }}
      </div>
    </div>

    <div id="votelist">
      <div id="votelist-topbar">
        <div id="votelist-title">
          {{ "radio.voting" | translate }}
        </div>
        <button mat-stroked-button (click)="openSuggestionDialog()">{{ "radio.suggest" | translate }}</button>
      </div>

      <div *ngFor="let vote of voteList; let i = index">
        <div class="vote">
          <div class="vote-info">
            <div class="vote-title">
              {{ vote.title }}
            </div>
            <div class="vote-artist">
              {{ vote.artist }}
            </div>
          </div>

          <div class="vote-buttons">
            <button [disabled]="downvotes.includes(vote.id)" [style.color]="upvotes.includes(vote.id) ? '#66bb6a' : ''" mat-icon-button (click)="voteSong(vote.id, true)">
              <mat-icon>thumb_up</mat-icon>
            </button>
            <button [disabled]="upvotes.includes(vote.id)" [style.color]="downvotes.includes(vote.id) ? '#ef5350' : ''" mat-icon-button (click)="voteSong(vote.id, false)">
              <mat-icon>thumb_down</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="voteList.length < 1" id="empty-votelist">
        {{ "radio.makeASuggestion" | translate }}
      </div>
    </div>
  </div>
</div>
