import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import localeDe from "@angular/common/locales/de";

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { ZXingScannerModule } from "@zxing/ngx-scanner";

import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { GalerieComponent } from "./galerie/galerie.component";
import { AboutusComponent } from "./aboutus/aboutus.component";
import { EventsComponent } from "./events/events.component";
import { InternalComponent } from "./internal/internal.component";
import { ImagedialogComponent } from "./imagedialog/imagedialog.component";
import { CopyrightdialogComponent } from "./copyrightdialog/copyrightdialog.component";
import { InternalDashboardComponent } from "./internal.dashboard/internal.dashboard.component";
import { InternalRequestsComponent } from "./internal.requests/internal.requests.component";
import { InternalEventsComponent } from "./internal.events/internal.events.component";
import { InternalMusicwishesComponent } from "./internal.musicwishes/internal.musicwishes.component";
import { InternalUsersComponent } from "./internal.users/internal.users.component";
import { MusicWishFormComponent } from "./musicwishform/musicwishform.component";
import { InternalSettingsComponent } from "./internal.settings/internal.settings.component";
import { InternalGalleryComponent } from "./internal.gallery/internal.gallery.component";
import { InternalPresenceComponent } from "./internal.presence/internal.presence.component";

import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatChipsModule } from "@angular/material/chips";
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatDatetimepickerModule } from "@mat-datetimepicker/core";
import { DateAdapter, NativeDateAdapter } from "@angular/material/core";
import { DatetimeAdapter, MAT_DATETIME_FORMATS, NativeDatetimeAdapter } from "@mat-datetimepicker/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { NgxMasonryModule } from "ngx-masonry";
import { CookieService } from "ngx-cookie-service";
import { DragDropDirective } from "./drag-drop.directive";
import { AuthInterceptor } from "./auth.interceptor";
import { registerLocaleData } from "@angular/common";
import { AuthGuardService } from "./auth-guard.service";
import { InternalUsersEditComponent } from "./internal.users/internal.users.edit.component";
import { InternalPresenceEditComponent } from "./internal.presence/internal.presence.edit.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { FormatTimePipe } from "./formatTimer.pipe";
import { InternalTodosComponent } from "./internal.todos/internal.todos.component";
import { InternalSchulradioComponent } from "./internal.schulradio/internal.schulradio.component";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { SchulradioComponent, SchulradioSuggestionDialog } from "./schulradio/schulradio.component";
import { environment } from "src/environments/environment";
import { ImgFallbackDirective } from "./img-fallback.directive";
import { PlayerWidgetComponent } from "./components/player-widget/player-widget.component";
import { PlaceholderComponent } from "./components/placeholder/placeholder.component";
import { InternalEmailComponent } from "./internal.email/internal.email.component";

import { InternalEmailEditComponent } from "./internal.email/internal.email.edit.component";
import { OAuthLoginComponent } from "./oauth/login/login.component";

import { NgxMaskModule } from 'ngx-mask'
import { NgDompurifyModule } from "@tinkoff/ng-dompurify";
import { EditorModule } from "@tinymce/tinymce-angular";
import { InternalInventoryComponent } from "./internal.inventory/internal.inventory.component";
import { ItemEditDialog } from "./internal.inventory/itemEditDialog";
import { ItemScanDialog } from "./internal.inventory/itemScanDialog";
import { DeleteDialog } from "./dialogs/deleteDialog/deleteDialog";
import { ItemBookDialog } from "./internal.inventory/itemBookDialog";
import { TransactionEditDialog } from "./internal.inventory/transactionEditDialog";
import { TransactionListDialog } from "./internal.inventory/transactionListDialog";
import { GroupListDialog } from "./internal.inventory/groupListDialog";
import { GroupEditDialog } from "./internal.inventory/groupEditDialog";
import { MissingItemsDialog } from "./internal.inventory/missingItemsDialog";
import { BarcodescannerdialogComponent } from "./dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { InventoryCheckDialog } from "./internal.inventory/inventoryCheckDialog";
import { UseHttpImageSourcePipe } from "./authimage.pipe";
import { InternalFinancesComponent } from './internal.finances/internal.finances.component';
import { FinanceTransactionEditDialog } from "./internal.finances/financeTransactionEditDialog";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { EventListitemComponent } from './components/event-listitem/event-listitem.component';
import { TimeSpanPipe } from './timespan.pipe';
import { InternalLocationsComponent } from './internal.locations/internal.locations.component';
import { InternalLocationsEditComponent, IntLocationsDeleteDialog } from './internal.locations/internal.locations.edit.component';
import { RequestComponent } from './request/request.component';
import { InternalMenuButtonComponent } from "./internalMenuButton.component";
import { ProgressDialog } from "./dialogs/progressDialog/progressDialog";
import { DocumentGridComponent } from "./components/document-grid/document-grid.component";
import { InvoiceEditorComponent } from './invoice-editor/invoice-editor.component';
import { MailFolderEditDialog } from "./internal.email/mailFolderEditDialog";
import { EventEditDialog } from "./internal.events/eventEditDialog";
import { DocumentViewerComponent } from './documentviewer/documentviewer.component';
import { InvTransactionListItemComponent } from './components/inv-transaction-list-item/inv-transaction-list-item.component';
import { FinanceTransactionListItemComponent } from './components/finance-transaction-list-item/finance-transaction-list-item.component';
import { InvTransactionImportDialog } from "./internal.events/InvTransactionImportDialog";
import { InternalWorkstationComponent } from './internal.workstation/internal.workstation.component';
import { TodoEditDialog } from "./internal.todos/todoEditDialog";
import { RequestEditDialog } from "./internal.requests/requestEditDialog";
import { FinanceTransactionListTotalItemComponent } from "./components/finance-transaction-list-totalitem/finance-transaction-list-totalitem.component";
import { BarcodeEditDialog } from "./internal.inventory/barcodeEditDialog";
import { MailLoginInformationDialog } from "./internal.email/mailLoginInformationDialog";
import { ClickToCopyComponent } from './components/click-to-copy/click-to-copy.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';


registerLocaleData(localeDe);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const socketIOConfig: SocketIoConfig = { url: environment.wsUrl, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MusicWishFormComponent,
    GalerieComponent,
    AboutusComponent,
    EventsComponent,
    OAuthLoginComponent,
    DocumentGridComponent,

    SchulradioComponent,
    SchulradioSuggestionDialog,
    ItemEditDialog,
    ItemScanDialog,
    ItemBookDialog,
    TransactionEditDialog,
    TransactionListDialog,
    GroupListDialog,
    GroupEditDialog,
    MissingItemsDialog,
    InventoryCheckDialog,
    BarcodescannerdialogComponent,
    DeleteDialog,
    FinanceTransactionEditDialog,
    ProgressDialog,
    MailFolderEditDialog,
    EventEditDialog,
    InvTransactionImportDialog,
    TodoEditDialog,
    RequestEditDialog,

    PlayerWidgetComponent,
    PlaceholderComponent,
    ImagedialogComponent,
    CopyrightdialogComponent,
    PagenotfoundComponent,
    EventListitemComponent,
    InternalMenuButtonComponent,

    InternalComponent,
    InternalDashboardComponent,
    InternalRequestsComponent,
    InternalEventsComponent,
    InternalMusicwishesComponent,
    InternalUsersComponent,
    InternalUsersEditComponent,
    InternalSettingsComponent,
    InternalGalleryComponent,
    InternalPresenceComponent,
    InternalPresenceEditComponent,
    PagenotfoundComponent,
    InternalTodosComponent,
    InternalSchulradioComponent,
    InternalEmailComponent,
    InternalEmailEditComponent,
    InternalInventoryComponent,
    InternalLocationsComponent,
    InternalLocationsEditComponent,
    InternalFinancesComponent,

    UseHttpImageSourcePipe,
    DragDropDirective,
    ImgFallbackDirective,
    IntLocationsDeleteDialog,
    FormatTimePipe,
    TimeSpanPipe,
    RequestComponent,
    InvoiceEditorComponent,
    DocumentViewerComponent,
    InvTransactionListItemComponent,
    FinanceTransactionListItemComponent,
    FinanceTransactionListTotalItemComponent,
    InternalWorkstationComponent,
    BarcodeEditDialog,
    MailLoginInformationDialog,
    ClickToCopyComponent,
    EmptyStateComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LazyLoadImageModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,

    NgDompurifyModule,
    EditorModule,
    NgxMasonryModule,
    NgxMaskModule.forRoot(),
    ZXingScannerModule,

    DragDropModule,

    MatMomentDateModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTooltipModule,
    MatChipsModule,
    MatDatetimepickerModule,
    MatDatepickerModule,
    MatTreeModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,

    SocketIoModule.forRoot(socketIOConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthGuardService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "de-DE",
    },
    {
      provide: MAT_DATE_LOCALE,
      useExisting: LOCALE_ID,
    },
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
    },
    {
      provide: DatetimeAdapter,
      useClass: NativeDatetimeAdapter,
    },
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: {
        parse: {},
        display: {
          dateInput: { year: "numeric", month: "2-digit", day: "2-digit" },
          monthInput: { month: "long" },
          datetimeInput: { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" },
          timeInput: { hour: "2-digit", minute: "2-digit" },
          monthYearLabel: { year: "numeric", month: "short" },
          dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
          monthYearA11yLabel: { year: "numeric", month: "long" },
          popupHeaderDateLabel: { weekday: "short", month: "short", day: "2-digit" },
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private iconReg: MatIconRegistry) {
      console.log('Registering icons');
      iconReg.setDefaultFontSetClass('material-symbols-outlined');
  }
}
