import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { TAGInvItemInstance, TAGInvItemType, TAGInvTransaction } from "../models/taginventory";
import { PrinterService } from "../printer.service";
import { getInventoryInstanceByCode } from "./inventoryHelper";

export interface InventoryCheckDialogData {}

@Component({
  selector: "int-inventory-inventoryCheckDialog",
  templateUrl: "inventoryCheckDialog.html",
  styleUrls: ["./inventoryCheckDialog.scss"],
})
export class InventoryCheckDialog {
  //TODO: Form with validation

  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  scannerForm = this.fb.group({
    input: [""],
  });

  items: TAGInvItemType[] = [];
  transactions: TAGInvTransaction[] = [];

  foundItems: { itemid: string; instanceid: string }[] = [];
  returnedItems: { itemid: string; instanceid: string }[] = [];

  filteredItems(): TAGInvItemType[] {
    return this.items.filter((item) => !item.deleted || item.instances.some((inst) => this.instanceFound(item._id, inst.itemid)));
  }

  filteredInstances(itemid: string) {
    var item = this.items.find((item) => item._id == itemid);
    if (!item) return [];
    return item.instances.filter((inst) => (!inst.deleted && !item.deleted) || this.instanceFound(itemid, inst.itemid));
  }

  constructor(public api: ApiService, private printer: PrinterService, private fb: UntypedFormBuilder, public dialog: MatDialog, public dialogRef: MatDialogRef<InventoryCheckDialog>, @Inject(MAT_DIALOG_DATA) public data: InventoryCheckDialogData) {
    api.waitForAPI().then(() => {
      api.getInvItems().then((items) => {
        this.items = items;

        api.getInvTransactions().then((result) => {
          this.transactions = result;
        }).catch((reason) => {
          console.error(reason);
        });
      }).catch((reason) => {
        console.error(reason);
      });
    });

    this.dialogRef.disableClose = true;
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  save(): void {

    console.log(this.returnedItems);
    console.log(this.transactions);
    // Book Back found Items that were booked somewhere
    let backTransactions: { [key: string]: {itemid: string; instanceid: string}[] } = {};
    for (let item of this.returnedItems) {
      let transactions = this.unfinishedTransactionsForItem(item.itemid);

      console.log(transactions);

      if (transactions.length > 0) {
        for (let tran of transactions) {
          let instance = tran.findItem(item.itemid, item.instanceid);
          if (instance != null && instance.back == null) {
            if (backTransactions[tran._id] == undefined) backTransactions[tran._id] = [];
            backTransactions[tran._id].push({ itemid: item.itemid, instanceid: item.instanceid });
          }
        }
      } else {
        console.error("No unfinished transaction found for booked item " + item.itemid + " instance " + item.instanceid);
      }
    }

    console.log(backTransactions);
    console.log(Object.keys(backTransactions));
    for (let tranid of Object.keys(backTransactions)) {
      console.log("Returning Items back of Transaction " + tranid + " ...");
      console.log(backTransactions[tranid]);
      this.api.returnTransactionItems(
        tranid,
        backTransactions[tranid]).then((result) => {
          console.log("Returned Items of Transaction " + tranid);
        }).catch((reason) => {
          console.error(reason);
        });
    }

    // Book out Items that were not found
    var trans = new TAGInvTransaction();
    trans.startdate = new Date();
    trans.location = "Inventory Check";

    var notFoundItems: { itemid: string; instanceid: string; back: Date }[] = [];
    for (let item of this.items.filter((item) => !item.deleted)) {
      for (let instance of item.instances.filter((inst) => !inst.deleted)) {
        if (!this.instanceFound(item._id, instance.itemid) && !this.instanceBooked(item._id, instance.itemid)) {
          notFoundItems.push({ itemid: item._id, instanceid: instance.itemid, back: null });
        }
      }
    }

    trans.items = notFoundItems;

    if (notFoundItems.length > 0) {
      this.api.startTransaction(
        trans).then((result) => {
          this.dialogRef.close(result);
        }).catch((reason) => {
          console.error(reason);
        });
    } else {
      this.dialogRef.close(null);
    }
  }

  ngAfterViewInit() {
    this.resetScannerField();
  }

  resetScannerField() {
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: { multiple: true },
    });

    dialogRef.componentInstance.onCodeScan.subscribe((code: string) => {
      this.codeScanned(code);
      this.resetScannerField();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  codeScanned(code: string) {
    getInventoryInstanceByCode(code, this.api).then((result) => {
      let item = result.item;
      let instanceID = result.instanceID;

      console.log(result);

      if (item && instanceID) {
        this.itemScanned(item._id, instanceID);
      } else {
        console.error("No Item or Instance found for code " + code);
      }
    }).catch((reason) => {
      console.log(reason);

      if (this.api.config.allowGroupsForBookIn) {
        this.api.getInvItemGroup(code).then((group) => {
          if (group) {
            for (let gItem of group.items) {
              this.itemScanned(gItem.itemid, gItem.instanceid);
            }
          }
        }).catch((reason) => {
          console.error(reason);
        });
      }
    }).finally(() => {
      this.resetScannerField();
    });

    console.log(this.foundItems);
    console.log(this.returnedItems);
  }

  itemScanned(itemID: string, instID: string) {
    if (!this.items.some((item) => item._id == itemID)) {
      this.resetScannerField();
      return;
    }

    if (!this.items.find((item) => item._id == itemID)?.instances.some((inst) => inst.itemid == instID)) {
      this.resetScannerField();
      return;
    }

    var found = this.instanceFound(itemID, instID);
    console.log("Instance complete: " + found);

    if (!found) {
      let booked = this.instanceBooked(itemID, instID);
      console.log("Instance booked: " + booked);

      if (booked) {
        this.returnedItems.push({ itemid: itemID, instanceid: instID });
      } else {
        this.foundItems.push({ itemid: itemID, instanceid: instID });
      }
    }
  }

  transactionsForItem(itemid: string) {
    return this.transactions.filter((tran) => tran.items.findIndex((comb) => comb.itemid == itemid) != -1);
  }

  unfinishedTransactionsForItem(itemid: string) {
    return this.transactionsForItem(itemid).filter((tran) => !tran.allItemsBack());
  }

  instanceBooked(itemid: string, instanceid: string) {
    var transactions = this.unfinishedTransactionsForItem(itemid);

    if (transactions.length > 0) {
      for (let tran of transactions) {
        let instance = tran.findItem(itemid, instanceid);
        if (instance != null && instance.back == null) {
          return true;
        }
      }
    }

    return false;
  }

  instanceStatus(itemid: string, instanceid: string) {
    if (this.instanceFound(itemid, instanceid)) {
      return "internal.inventory.inventoryCheck.found";
    } else if (this.instanceBooked(itemid, instanceid)) {
      return "internal.inventory.inventoryCheck.booked";
    } else {
      return "";
    }
  }

  instanceFound(itemid: string, instanceid: string) {
    let found = this.foundItems.findIndex((comb) => comb.itemid == itemid && comb.instanceid == instanceid) != -1;
    found = found || this.returnedItems.findIndex((comb) => comb.itemid == itemid && comb.instanceid == instanceid) != -1;

    return found;
  }

  itemComplete(itemid: string) {
    var complete = true;
    var item = this.items.find((item) => item._id == itemid);

    for (let instance of item.instances) {
      complete = complete && this.instanceFound(itemid, instance.itemid);
    }

    return complete;
  }

  printLabel(item: TAGInvItemType, instance: TAGInvItemInstance) {
    if (!this.printer.isConnected())
      this.printer
        .connectToPrinter()
        .then((result) => {
          this.printer.printItemLabels(item, [instance]);
        })
        .catch((reason) => {
          console.error(reason);
        });
    else this.printer.printItemLabels(item, [instance]);
  }
}
