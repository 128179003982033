import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { TAGEvent } from '../models/tagevent';
import { TAGImage } from '../models/tagimage';
import { TAGLocation } from '../models/taglocation';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  randomimage: TAGImage;
  events: TAGEvent[] = [];
  locations: TAGLocation[] = [];

  getRandomImageLink() { return (this.randomimage)? this.api.getImageLink(this.randomimage._id) : "assets/images/noimages.png"; }
  eventLocation(locationid: string) { return this.locations.find(l => l._id == locationid); }
  locationName(locationid: string) {
    let location = this.eventLocation(locationid);
    if (location != null) return location.name;
    return locationid;
  }

  constructor(public api: ApiService, private router: Router) { }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.api.getRandomImage("").then((result) => {
        this.randomimage = result;
      }).catch((reason) => {

      });

      this.api.getLocations().then((result) => {
        this.locations = result;

        this.api.getEvents(true).then((result) => {
          this.events = result.filter(e => e.enddate > new Date()).sort((a: TAGEvent, b: TAGEvent) => <any>a.startdate - <any>b.startdate);
        }).catch((reason) => {
          console.error(reason);
        });
      }).catch((reason) => {
        console.error(reason);
      });
    });
  }

  openEventDetails(eventid: number) {
    this.router.navigate(['/events'], { queryParams: { eventid: eventid } });
  }

}
