<div id="itemscan-header">
  <div id="itemscan-header-title">{{ 'internal.inventory.itemScan.title' | translate }}</div>
</div>

<div id="itemscan-form">
  <form [formGroup]="scannerForm" (ngSubmit)="scanCode()">
    <mat-form-field>
      <input #scannerInput matInput placeholder="{{ 'internal.inventory.scanner' | translate }}" formControlName="input" />
      <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
    </mat-form-field>
  </form>

  <div id="instance-list">
    <div id="instance-list-header">
      <div id="instance-list-header-title">{{ 'internal.inventory.itemScan.scannedInstances' | translate }}</div>
      <div id="instance-list-header-actions">
        <button mat-stroked-button class="desktop" (click)="printLabelsAll()" [disabled]="scannedInstances.length <= 0">
          {{ 'internal.inventory.printLabels' | translate }}
        </button>

        <button mat-icon-button class="mobile" (click)="printLabelsAll()" [disabled]="scannedInstances.length <= 0">
          <mat-icon>print</mat-icon>
        </button>
        <!-- <button mat-stroked-button [disabled]="true">{{ 'internal.inventory.itemScan.addManual' | translate }}</button> -->
      </div>
    </div>

    <div id="hspacer"></div>

    <div id="instance-list-content">
      <div *ngFor="let comb of scannedInstances" class="instance" [ngClass]="{inuse: !comb.back}">
        <div class="instance-name">{{comb.combo.item.name}}</div>
        <div *ngIf="comb.combo.item.additionalInfo" class="instance-addInfo">{{comb.combo.item.additionalInfo}}</div>
        <div class="instance-id">{{comb.combo.instanceID}}</div>
        <div *ngIf="comb.combo.instance.deleted || comb.combo.item.deleted" class="instance-status">
          {{ 'internal.inventory.deleted' | translate }}
        </div>
        <div class="instance-status">{{comb.back? "" : "internal.inventory.inUse" | translate}}</div>
        <div class="instance-actions">
          <button mat-icon-button (click)="deleteInstance(comb.combo)"><mat-icon>delete</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="itemscan-actions">
  <button mat-stroked-button color="warn" (click)="cancel()">{{ 'general.cancel' | translate }}</button>
  <button mat-stroked-button [disabled]="scannedItemHasError()" (click)="finish()">{{ 'general.finish' | translate }}</button>
</div>
