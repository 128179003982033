<div id="event-details">

  <div id="event-details-list">
    <div class="maintitle">{{ "events.nextEvents" | translate }}</div>

    <div class="event-list">
      <div *ngIf="events.length == 0" id="event-list-empty">
        <div>{{ "events.currentNoEvents" | translate }}</div>
      </div>

      <div *ngFor="let event of events" style="display: contents;">
        <event-item [event]="event" [location]="locationName(event.location)" (eventClicked)="selectEvent(event)"></event-item>
        <div class="spacer"></div>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <div id="event-details-maininfo">
    <div *ngIf="selectedEvent" style="display: contents;">
      <div id="title">{{selectedEvent.name}}</div>
      <div id="date">{{selectedEvent.startdate | date:"dd MMM yyyy HH:mm"}}   •   {{(selectedEvent.enddate - selectedEvent.startdate) / 1000 | timespan}}</div>
      <div id="date">{{locationName(selectedEvent.location)}}</div>
      <div *ngIf="selectedEvent.description != ''" class="h-spacer"></div>
      <div id="description">{{selectedEvent.description}}</div>

      <div *ngIf="eventCategory && eventImages.length > 0" style="display: contents;">
        <div class="h-spacer"></div>
        <div class="subtitle">{{ "events.images" | translate }}</div>
        <ngx-masonry
          [options]="{ transitionDuration: '0.2s', gutter: 10, resize: true, initLayout: true, fitWidth: false }"
          [useImagesLoaded]="true">

          <div ngxMasonryItem *ngFor="let item of eventImages;" class="masonry-item" (click)="openDialog(item)">
            <img src="{{this.getThumbLink(item._id)}}" alt="Thumbnail: {{item.title}}"/>
          </div>
        </ngx-masonry>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <div id="event-details-addinfo">
    <div *ngIf="selectedEvent" style="display: contents;">

      <!-- Locations removed -->

      <!-- <div class="subtitle">Google Maps</div>
      <iframe
        frameborder="0" style="border:0"
        referrerpolicy="no-referrer-when-downgrade"
        [src]="mapsURL"
        allowfullscreen>
      </iframe>
      <div class="h-spacer"></div> -->

      <!-- <div class="subtitle">{{ "events.access" | translate }}</div>
      <img [src]="getLocationImageLink(eventLocation(selectedEvent.location)._id)" (click)="openLocationImage(eventLocation(selectedEvent.location))" />
      <div class="h-spacer"></div> -->
    </div>
  </div>
</div>
