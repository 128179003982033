import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-documentviewer',
  templateUrl: './documentviewer.component.html',
  styleUrls: ['./documentviewer.component.scss']
})
export class DocumentViewerComponent implements OnInit {

  pdfData: any = null;
  error: string = null;

  constructor(private api: ApiService, private route: ActivatedRoute, private sanitzer: DomSanitizer) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.api.waitForAPI().then(() => {
        this.api.getPublicFinanceTransactionInvoice(params.docid).then((result) => {
          console.log(result);
          console.log(result.type);

          if (result.type == "application/pdf") {
            this.pdfData = this.sanitzer.bypassSecurityTrustResourceUrl(URL.createObjectURL(result));
          } else {
            result.text().then((result) => {
              let json = JSON.parse(result);
              this.error = "There was an error loading this document";
            }).catch((reason) => {
              console.error(reason);
            });
          }
        }).catch((reason) => {
          console.error(reason);
        });
      });
    });
  }

}
