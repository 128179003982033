import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map, Observable, startWith } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { openProgressDialog, ProgressDialog, ProgressDialogStep, ProgressDialogStepState } from "../dialogs/progressDialog/progressDialog";
import { TAGEvent } from "../models/tagevent";
import { TAGTransaction } from "../models/tagfinances";
import { TAGUser } from "../models/taguser";

export interface FinanceTransactionEditDialogData {
  transaction: TAGTransaction;
  users: TAGUser[];
  events: TAGEvent[];
}

@Component({
  selector: "int-finance-transactioneditdialog",
  templateUrl: "financeTransactionEditDialog.html",
  styleUrls: ["./financeTransactionEditDialog.scss"],
})
export class FinanceTransactionEditDialog {

  transaction: TAGTransaction = new TAGTransaction();

  newFiles: {fileName: string, mimeType: string, fileData: string}[] = [];
  deletedFiles: string[] = [];

  userControl = new FormControl("");
  eventControl = new FormControl("");

  filteredUsers: Observable<TAGUser[]>;
  filteredEvents: Observable<TAGEvent[]>;

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FinanceTransactionEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: FinanceTransactionEditDialogData
  ) {
    this.transaction = new TAGTransaction(Object.assign({}, data.transaction));

    if (this.transaction.amount == 0) {
      this.transaction.amount = undefined;
    }

    let user = this.data.users.find((usr) => usr.username == this.transaction.user);
    if (user) {
      this.transaction.user = this.getUserName(user.username);
    }

    let event = this.data.events.find((ev) => ev._id == this.transaction.event);
    if (event) {
      this.transaction.event = event.name;
    }

    this.filteredUsers = this.userControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._userFilter(value || ""))
    );

    this.filteredEvents = this.eventControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._eventFilter(value || ""))
    );

    this.dialogRef.disableClose = true;
  }

  _userFilter(filter: String): TAGUser[] {
    const filterValue = filter.toLowerCase();

    return this.data.users.filter((user) =>
      user.username.toLowerCase().includes(filterValue) ||
      user.firstname.toLowerCase().includes(filterValue) ||
      user.lastname.toLowerCase().includes(filterValue) ||
      user.email.toLowerCase().includes(filterValue)
    );
  }

  _eventFilter(filter: String): TAGEvent[] {
    const filterValue = filter.toLowerCase();

    return this.data.events.filter((event) =>
      event.name.toLowerCase().includes(filterValue)
    );
  }

  getUserName(username: string) {
    var usr = this.data.users.find((u) => u.username == username);
    if (usr != undefined) return usr.firstname + " " + usr.lastname;

    return username;
  }

  allFiles() {
    let allFiles = this.transaction.files.concat(this.newFiles);
    return allFiles.filter((f) => !this.deletedFiles.includes(f.fileName));
  }

  apiUrl() { return environment.apiUrl; }

  ngOnInit() {
    this.api.waitForAPI().then(() => {

    });
  }

  uploadFile(event) {
    console.log(event);
    this.newFiles.push(event);
  }

  deleteFile(fileName: string) {
    if (this.newFiles.find((nf) => nf.fileName == fileName)) {
      this.newFiles = this.newFiles.filter((nf) => nf.fileName != fileName);
    } else {
      this.deletedFiles.push(fileName);
    }
  }

  cancelEdit(): void {
    this.dialogRef.close(null);
  }

  saveEdit(): void {
    this.transaction.amount = parseFloat(this.transaction.amount.toString());

    let user = this.data.users.find((usr) => this.getUserName(usr.username) == this.transaction.user);
    if (user) {
      this.transaction.user = user.username;
    }

    let event = this.data.events.find((ev) => ev.name == this.transaction.event);
    if (event) {
      this.transaction.event = event._id;
    }

    let processingStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Processing",
    }

    let uploadStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Upload Files",
      showProgress: true,
      progress: 0,
    }

    if (this.newFiles.length == 0) {
      uploadStep.state = ProgressDialogStepState.SKIPPED;
    }

    const progressDialog = openProgressDialog(this.dialog, [processingStep, uploadStep]);

    progressDialog.afterOpened().subscribe(() => {
      if (this.transaction._id == undefined) {
        processingStep.state = ProgressDialogStepState.PROCESSING;

        this.api.newFinanceTransaction(this.transaction).then((result) => {
          processingStep.state = ProgressDialogStepState.SUCCESS;

          // Update Files of Transaction
          if (this.newFiles.length > 0 || this.deletedFiles.length > 0) {

            this.saveFiles(this.transaction._id, uploadStep).then(() => {
              progressDialog.componentInstance.finishDialog();
              this.dialogRef.close(this.transaction);
            }).catch((error) => {
              progressDialog.componentInstance.finishDialog(-1);
              console.log(error);
            });
          } else {
            progressDialog.componentInstance.finishDialog();
            this.dialogRef.close(this.transaction);
          }
        }).catch((reason) => {
          processingStep.state = ProgressDialogStepState.ERROR;
          processingStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
          console.log(reason);
        });
      } else {
        processingStep.state = ProgressDialogStepState.PROCESSING;

        this.api.updateFinanceTransaction(this.transaction).then((result) => {
          processingStep.state = ProgressDialogStepState.SUCCESS;

          // Update Files of Transaction
          if (this.newFiles.length > 0 || this.deletedFiles.length > 0) {

            this.saveFiles(this.transaction._id, uploadStep).then(() => {
              progressDialog.componentInstance.finishDialog();
              this.dialogRef.close(this.transaction);
            }).catch((error) => {
              progressDialog.componentInstance.finishDialog(-1);
              console.log(error);
            });
          } else {
            progressDialog.componentInstance.finishDialog();
            this.dialogRef.close(this.transaction);
          }
        }).catch((reason) => {
          processingStep.state = ProgressDialogStepState.ERROR;
          processingStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
          console.log(reason);
        });
      }
    });
  }

  saveFiles(transactionID: string, uploadStep: ProgressDialogStep) {
    return new Promise<void>((resolve, reject) => {
      uploadStep.state = ProgressDialogStepState.PROCESSING;

      let promises = [];
      let allFiles = this.newFiles.length;
      let doneFiles = 0;

      for (let f of this.newFiles) {
        promises.push(new Promise<void>((resolve, reject) => {
          this.api.uploadFinanceTransactionAttachment(transactionID, f.fileName, f.fileData, progress => {
            uploadStep.progress = (doneFiles / allFiles) + (progress / allFiles);
          }).then((result) => {
            resolve();
            doneFiles++;
          }).catch((reason) => {
            reject(reason);
            console.log(reason);
            uploadStep.state = ProgressDialogStepState.ERROR;
            uploadStep.subtitle = reason.message;
          });
        }));
      }

      for (let f of this.deletedFiles) {
        promises.push(new Promise<void>((resolve, reject) => {
          this.api.deleteFinanceTransactionAttachment(transactionID, f).then((result) => {
            resolve();
          }).catch((reason) => {
            reject(reason);
            console.log(reason);
            uploadStep.state = ProgressDialogStepState.ERROR;
            uploadStep.subtitle = reason.message;
          });
        }));
      }

      Promise.all(promises).then(() => {
        uploadStep.state = ProgressDialogStepState.SUCCESS;
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
