import { Component, OnInit, Inject, ViewChild, EventEmitter } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import { BarcodeFormat } from "@zxing/library";
import { MatIcon } from "@angular/material/icon";

interface BarcodescannerDialogData {
  multiple?: boolean;
}

@Component({
  selector: "app-barcodescannerdialog",
  templateUrl: "./barcodescannerdialog.component.html",
  styleUrls: ["./barcodescannerdialog.component.scss"],
})
export class BarcodescannerdialogComponent implements OnInit {
  @ViewChild("scanner", { static: false }) scanner: ZXingScannerComponent;
  @ViewChild("successIcon") successIcon: MatIcon;

  constructor(public dialogRef: MatDialogRef<BarcodescannerdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: BarcodescannerDialogData) {}

  onCodeScan = new EventEmitter<string>();

  torch: boolean = false;
  torchSupported: boolean = false;

  allowedFormats = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.EAN_13,
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.AZTEC,
    BarcodeFormat.PDF_417,
    BarcodeFormat.ITF,
    BarcodeFormat.MAXICODE,
  ];

  ngOnInit(): void {}

  camerasNotFoundHandler(e) {
    this.dialogRef.close({ error: true, message: "No Cameras found" });
  }

  onTorchCompatible(e) {
    console.log(e);
    if (e != undefined) {
      this.torchSupported = true;
    }
  }

  camerasFoundHandler(e) {
    console.log(e);
    this.scanner.device = e[0].deviceId;
  }

  scanSuccessHandler(e) {
    console.log(e);
  }

  scanErrorHandler(e) {
    console.log(e);
  }

  scanFailureHandler(e) {
    //console.log(e);
  }

  backgroundAnimationTimer: any = null;
  scanCompleteHandler(e) {
    if (e == undefined) return;

    if (this.data.multiple) {
      this.onCodeScan.emit(e.text.trim());

      if (this.backgroundAnimationTimer) {
        clearTimeout(this.backgroundAnimationTimer);
        this.backgroundAnimationTimer = null;
        this.successIcon._elementRef.nativeElement.style.animation = null;
        this.successIcon._elementRef.nativeElement.offsetHeight;
      }

      this.successIcon._elementRef.nativeElement.style.animation = "1.5s ease-in 0s success";

      this.backgroundAnimationTimer = setTimeout(() => {
        this.successIcon._elementRef.nativeElement.style.animation = null;
        this.successIcon._elementRef.nativeElement.offsetHeight;
        this.backgroundAnimationTimer = null;
      }, 1500);
    } else {
      this.dialogRef.close({ error: false, code: e.text.trim() });
    }
  }
}
