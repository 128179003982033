<div id="int-content-topbar">
    <internal-menu-button id="menuBtn"></internal-menu-button>
    <button *ngIf="hasPerm('create_finances')" mat-stroked-button (click)="newTransaction()">{{ "internal.finances.newTransaction" | translate }}</button>
    <button *ngIf="hasPerm('create_finances')" mat-stroked-button (click)="startInvoice()">{{ "internal.finances.startInvoice" | translate }}</button>
</div>

<div id="int-presence-content">
    <div class="transaction-filter">
        <mat-form-field appearance="standard" style="grid-area: reason;">
            <mat-label>{{ 'internal.finances.transaction.reason' | translate }}</mat-label>
            <input matInput placeholder="Reason" [(ngModel)]="filterReason" (change)="filter()"/>
            <mat-icon matSuffix>edit</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="standard" style="grid-area: user;">
            <mat-label>{{ 'internal.finances.transaction.user' | translate }}</mat-label>
            <input matInput placeholder="User" [(ngModel)]="filterUser" (change)="filter()" [formControl]="userControl" [matAutocomplete]="userAutocomplete" />
            <mat-icon matSuffix>edit</mat-icon>

            <mat-autocomplete #userAutocomplete="matAutocomplete">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user.username"> {{getUserName(user.username)}} </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="standard" style="grid-area: event;">
            <mat-label>{{ 'internal.finances.transaction.event' | translate }}</mat-label>
            <input matInput placeholder="Event" [(ngModel)]="filterEvent" (change)="filter()" [formControl]="eventControl" [matAutocomplete]="eventAutocomplete" />
            <mat-icon matSuffix>edit</mat-icon>

            <mat-autocomplete #eventAutocomplete="matAutocomplete">
            <mat-option *ngFor="let event of filteredEvents | async" [value]="event._id"> {{event.name}} </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div id="filter-checks" style="grid-area: checks;">
            <mat-checkbox [(ngModel)]="filterIncome" (change)="filter()" [indeterminate]="filterIncome == undefined">{{ 'internal.finances.transaction.isIncome' | translate }}</mat-checkbox>
            <mat-checkbox [(ngModel)]="filterPending" (change)="filter()" [indeterminate]="filterPending == undefined">{{ 'internal.finances.transaction.pending' | translate }}</mat-checkbox>
        </div>

        <div id="space" style="grid-area: space;"></div>

        <div id="filter-buttons" style="grid-area: buttons;">
            <button mat-icon-button (click)="filter()" style="grid-area: filter;">
                <mat-icon>search</mat-icon>
            </button>

            <button mat-icon-button [disabled]="!filterActive" (click)="cancelFilter()" style="grid-area: cancel;">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <!--<div id="divider" style="grid-area: divider;"></div>-->
    </div>

    <div class="transaction-list">
        <finance-transaction-list-totalitem
            class="transaction-totalitem"
            [total]="totalAmount()"
            [totalPending]="totalAmount(true)">
        </finance-transaction-list-totalitem>

        <finance-transaction-list-item
            *ngFor="let trans of filteredTransactions"
            [users]="users"
            [events]="events"
            [transaction]="trans"
            [showEditButton]="hasPerm('edit_finances')"
            [showDeleteButton]="hasPerm('delete_finances')"
            (edit)="editTransaction(trans)"
            (delete)="deleteTransaction(trans)"
            class="transaction-item"
            [ngClass]="{'selected': selectedTransactions.includes(trans._id)}"
            (click)="selectTransaction(trans)">
        </finance-transaction-list-item>
    </div>
</div>
