import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { TAGEvent } from 'src/app/models/tagevent';
import { TAGTransaction } from 'src/app/models/tagfinances';
import { TAGUser } from 'src/app/models/taguser';

@Component({
  selector: 'finance-transaction-list-item',
  templateUrl: './finance-transaction-list-item.component.html',
  styleUrls: ['./finance-transaction-list-item.component.scss']
})
export class FinanceTransactionListItemComponent implements OnInit {

  @Input() transaction: TAGTransaction;
  @Input() users: TAGUser[];
  @Input() events: TAGEvent[];

  @Input() showEditButton: boolean = true;
  @Input() showDeleteButton: boolean = true;
  @Input() showEvent: boolean = true;

  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  getUserName(username: string) {
    var usr = this.users.find((u) => u.username == username);
    if (usr != undefined) return usr.firstname + " " + usr.lastname;

    return username;
  }

  getEventName(eventId: string) {
    var ev = this.events.find((e) => e._id == eventId);
    if (ev != undefined) return ev.name;

    return eventId;
  }

  constructor(public api: ApiService) { }

  ngOnInit(): void {
  }

  editTransaction(transaction: TAGTransaction) {
    this.edit.emit(transaction);
  }

  deleteTransaction(transaction: TAGTransaction) {
    this.delete.emit(transaction);
  }

  openDocument(transactionId: string, fileName: string) {
    this.api.getFinanceTransactionAttachment(transactionId, fileName).then((result) => {
      var pdfUrl = window.URL.createObjectURL(result);

      var link = document.createElement("a");
      link.download = fileName;
      link.href = pdfUrl;

      window.open(pdfUrl, "_blank");
    }).catch((reason) => {
      console.error(reason);
    });
  }
}
