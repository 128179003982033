export class Permission {
  name: string = "";
  category: string = "";

  constructor(name: string, category: string) {
    this.name = name;
    this.category = category;
  }

  static fromName(name: string) {
    return this.allPermissions.find((v) => v.name == name);
  }

  // Base
  static pin_login: Permission = new Permission("pin_login", "Base");
  static edit_config: Permission = new Permission("edit_config", "Base");
  static kiosk_mode: Permission = new Permission("kiosk_mode", "Base");

  // OAuth
  static login_wiki: Permission = new Permission("login_wiki", "OAuth");
  static login_cloud: Permission = new Permission("login_cloud", "OAuth");
  static login_mattermost: Permission = new Permission("login_mattermost", "OAuth");

  // Presence
  static view_presence: Permission = new Permission("view_presence", "Presence");
  static create_presence: Permission = new Permission("create_presence", "Presence");
  static edit_presence: Permission = new Permission("edit_presence", "Presence");
  static delete_presence: Permission = new Permission("delete_presence", "Presence");

  // Users
  static view_users: Permission = new Permission("view_users", "Users");
  static create_users: Permission = new Permission("create_users", "Users");
  static edit_users: Permission = new Permission("edit_users", "Users");
  static delete_users: Permission = new Permission("delete_users", "Users");

  // Inventory
  static view_inventory: Permission = new Permission("view_inventory", "Inventory");
  static create_inventory_item: Permission = new Permission("create_inventory_item", "Inventory");
  static edit_inventory_item: Permission = new Permission("edit_inventory_item", "Inventory");
  static delete_inventory_item: Permission = new Permission("delete_inventory_item", "Inventory");

  static inventory_booking: Permission = new Permission("inventory_booking", "Inventory");

  // Finances
  static view_finances: Permission = new Permission("view_finances", "Finances");
  static create_finances: Permission = new Permission("create_finances", "Finances");
  static edit_finances: Permission = new Permission("edit_finances", "Finances");
  static delete_finances: Permission = new Permission("delete_finances", "Finances");

  // Todos
  static view_todos: Permission = new Permission("view_todos", "Todos");
  static create_todos: Permission = new Permission("create_todos", "Todos");
  static edit_todos: Permission = new Permission("edit_todos", "Todos");
  static delete_todos: Permission = new Permission("delete_todos", "Todos");

  // Events
  static create_events: Permission = new Permission("create_events", "Events");
  static edit_events: Permission = new Permission("edit_events", "Events");
  static delete_events: Permission = new Permission("delete_events", "Events");
  static view_events: Permission = new Permission("view_events", "Events");

  // Requests
  static create_requests: Permission = new Permission("create_requests", "Requests");
  static edit_requests: Permission = new Permission("edit_requests", "Requests");
  static delete_requests: Permission = new Permission("delete_requests", "Requests");
  static view_requests: Permission = new Permission("view_requests", "Requests");
  static promote_to_event: Permission = new Permission("promote_to_event", "Requests");

  //Locations
  static create_locations: Permission = new Permission("create_locations", "Locations");
  static edit_locations: Permission = new Permission("edit_locations", "Locations");
  static delete_locations: Permission = new Permission("delete_locations", "Locations");
  static view_locations: Permission = new Permission("view_locations", "Locations");

  // Music Wishes
  static view_musicwishes: Permission = new Permission("view_musicwishes", "Music Wishes");
  static edit_musicwishes: Permission = new Permission("edit_musicwishes", "Music Wishes");
  static delete_musicwishes: Permission = new Permission("delete_musicwishes", "Music Wishes");

  // Schulradio
  static view_schulradio: Permission = new Permission("view_schulradio", "Schulradio");
  static control_breaks: Permission = new Permission("control_breaks", "Schulradio");
  static control_playback: Permission = new Permission("control_playback", "Schulradio");
  static control_playlist: Permission = new Permission("control_playlist", "Schulradio");
  static control_player: Permission = new Permission("control_player", "Schulradio");

  // Images and Categories
  static create_category: Permission = new Permission("create_category", "Images");
  static view_private_categories: Permission = new Permission("view_private_categories", "Images");
  static edit_category: Permission = new Permission("edit_category", "Images");
  static delete_category: Permission = new Permission("delete_category", "Images");
  static create_image: Permission = new Permission("create_image", "Images");
  static view_private_images: Permission = new Permission("view_private_images", "Images");
  static edit_image: Permission = new Permission("edit_image", "Images");
  static delete_image: Permission = new Permission("delete_image", "Images");

  static allPermissions: Permission[] = [
    // Base
    Permission.pin_login,
    Permission.edit_config,
    Permission.kiosk_mode,

    // OAuth
    Permission.login_wiki,
    Permission.login_cloud,
    Permission.login_mattermost,

    // Presence
    Permission.view_presence,
    Permission.create_presence,
    Permission.edit_presence,
    Permission.delete_presence,

    // Users
    Permission.view_users,
    Permission.create_users,
    Permission.edit_users,
    Permission.delete_users,

    // Inventory
    Permission.view_inventory,
    Permission.create_inventory_item,
    Permission.edit_inventory_item,
    Permission.delete_inventory_item,
    Permission.inventory_booking,

    // Finances
    Permission.view_finances,
    Permission.create_finances,
    Permission.edit_finances,
    Permission.delete_finances,

    // Todos
    Permission.view_todos,
    Permission.create_todos,
    Permission.edit_todos,
    Permission.delete_todos,

    // Events
    Permission.create_events,
    Permission.edit_events,
    Permission.delete_events,
    Permission.view_events,

    // Requests
    Permission.create_requests,
    Permission.edit_requests,
    Permission.delete_requests,
    Permission.view_requests,
    Permission.promote_to_event,

    // Locations
    Permission.create_locations,
    Permission.edit_locations,
    Permission.delete_locations,
    Permission.view_locations,

    // Music Wishes
    Permission.view_musicwishes,
    Permission.edit_musicwishes,
    Permission.delete_musicwishes,

    // Schulradio
    Permission.view_schulradio,
    Permission.control_breaks,
    Permission.control_playback,
    Permission.control_playlist,
    Permission.control_player,

    // Images and Categories
    Permission.create_category,
    Permission.view_private_categories,
    Permission.edit_category,
    Permission.delete_category,
    Permission.create_image,
    Permission.view_private_images,
    Permission.edit_image,
    Permission.delete_image,
  ];
}
