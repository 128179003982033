<div id="mail-content">
  <div id="mail-topbar" [ngClass]="{'hide': selectedMail != -1}">
    <internal-menu-button id="menuBtn"></internal-menu-button>

    <button mat-stroked-button (click)="refreshMails()">{{ "general.refresh" | translate }}</button>
    <button mat-stroked-button (click)="sendNewMail()">{{ "internal.mails.newMail" | translate }}</button>
    <button mat-stroked-button (click)="newFolder()">{{ "internal.mails.newFolder" | translate }}</button>
    <button *ngIf="canEditFolder(getFolderFromPath(selectedFolder))" mat-stroked-button (click)="editFolder()">{{ "internal.mails.editFolder" | translate }}</button>
    <button *ngIf="canEditFolder(getFolderFromPath(selectedFolder))" color="warn" mat-stroked-button (click)="deleteFolder()">{{ "internal.mails.deleteFolder" | translate }}</button>
    <button mat-stroked-button (click)="showMailLogin()">{{ "internal.mails.showLoginInformation" | translate }}</button>
  </div>

  <div id="folders-list" [ngClass]="{'hide': selectedMail != -1}">
    <mat-tree [dataSource]="folderDataSource" [treeControl]="treeControl" class="folder-tree">
      <!-- This is the tree node template for leaf nodes -->
      <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="selectFolder(node)">
        <mat-icon class="folder-icon">
          {{getFolderIcon(node)}}
        </mat-icon>

        {{node.name}}
      </mat-tree-node>

      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node" (click)="selectFolder(node)">
          <mat-icon class="folder-icon">
            {{getFolderIcon(node)}}
          </mat-icon>

          {{node.name}}

          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
        </div>

        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.folder-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

  <div id="mail-list" [ngClass]="{'hide': selectedMail != -1}">

    <div id="mail-search">
      <mat-form-field appearance="outline">
        <mat-label>{{ "general.search" | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
      </mat-form-field>
    </div>

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="seen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="read_indicator"></div></th>
        <td mat-cell *matCellDef="let row" (click)="markMailRead(row)">
          <div class="read_indicator" [ngClass]="{ unread: !isFlagged(row, '\\Seen') }"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="important">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><mat-icon style="font-variation-settings: 'FILL' 1">label_important</mat-icon></th>
        <td mat-cell *matCellDef="let row" (click)="markMailFlagged(row)">
          <mat-icon [style.font-variation-settings]="isFlagged(row, '\\Flagged')? '\'FILL\' 1': ''">label_important</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.mails.subject" | translate }}</th>
        <td mat-cell class="large-cell" *matCellDef="let row" (click)="selectMail(row)" [ngClass]="{ unread: !isFlagged(row, '\\Seen') }">{{ cutText(row.subject, 40) }}</td>
      </ng-container>

      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.mails.from" | translate }}</th>
        <td mat-cell class="large-cell" *matCellDef="let row" (click)="selectMail(row)" [ngClass]="{ unread: !isFlagged(row, '\\Seen') }">{{ row.from.text }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.mails.date" | translate }}</th>
        <td mat-cell *matCellDef="let row" (click)="selectMail(row)" [ngClass]="{ unread: !isFlagged(row, '\\Seen') }">
          {{ row.date | date: "dd.MM.yyyy HH:mm" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="input.value == ''" class="mat-cell" colspan="5">{{ "internal.mails.noMails" | translate }}</td>
        <td *ngIf="input.value != ''" class="mat-cell" colspan="5">{{ "internal.mails.noMailsFilter" | translate: input }}</td>
      </tr>
    </table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of mails"></mat-paginator>
  </div>

  <div style="display: contents;" [ngClass]="{'hide': selectedMail == -1}">
    <div id="mail-detail" *ngIf="selectedMail == -1">
      <div id="no-mail-selected">{{ "internal.mails.noMailSelected" | translate }}</div>
    </div>

    <div id="mail-detail" *ngIf="selectedMail != -1">
      <div id="mail-detail-topbar">
        <button mat-icon-button matTooltip="{{ 'general.back' | translate }}" (click)="selectedMail = -1">
          <mat-icon>arrow_back</mat-icon>
        </button>

        <button mat-icon-button matTooltip="{{ 'internal.mails.reply' | translate }}" (click)="resendOldMail(mails[selectedMail], 'reply')">
          <mat-icon>reply</mat-icon>
        </button>

        <button mat-icon-button matTooltip="{{ 'internal.mails.replyAll' | translate }}" (click)="resendOldMail(mails[selectedMail], 'replyAll')">
          <mat-icon>reply_all</mat-icon>
        </button>

        <button mat-icon-button matTooltip="{{ 'internal.mails.forward' | translate }}" (click)="resendOldMail(mails[selectedMail], 'forward')">
          <mat-icon>forward</mat-icon>
        </button>

        <button
          mat-icon-button
          matTooltip="{{ isFlagged(mails[selectedMail], '\\Seen') ? ('internal.mails.markMailUnread' | translate) : ('internal.mails.markMailRead' | translate) }}"
          (click)="markMailRead(mails[selectedMail])">
          <mat-icon>{{ isFlagged(mails[selectedMail], "\\Seen") ? "mark_email_unread" : "mark_email_read" }}</mat-icon>
        </button>

        <button
          mat-icon-button
          matTooltip="{{
            isFlagged(mails[selectedMail], '\\Flagged') ? ('internal.mails.markMailUnimportant' | translate) : ('internal.mails.markMailImportant' | translate)
          }}"
          (click)="markMailFlagged(mails[selectedMail])">
          <mat-icon [style.font-variation-settings]="isFlagged(mails[selectedMail], '\\Flagged')? '\'FILL\' 1': ''">label_important</mat-icon>
        </button>

        <button mat-icon-button matTooltip="{{ 'internal.mails.moveMail' | translate }}" [matMenuTriggerFor]="moveMenu">
          <mat-icon>move_to_inbox</mat-icon>
        </button>

        <mat-menu #moveMenu="matMenu">
          <ng-template matMenuContent>
            <button *ngFor="let folder of foldersFlat" mat-menu-item (click)="moveMail(mails[selectedMail], folder)">
              {{ folder.name }}
            </button>
          </ng-template>
        </mat-menu>

        <button *ngIf="selectedFolder == 'Trash'" mat-icon-button matTooltip="{{ 'general.undelete' | translate }}" (click)="undeleteMail(mails[selectedMail])">
          <mat-icon>restore_from_trash</mat-icon>
        </button>

        <button *ngIf="selectedFolder == 'Trash'" mat-icon-button color="warn" matTooltip="{{ 'general.deleteForever' | translate }}" (click)="deleteMailForever(mails[selectedMail])">
          <mat-icon>delete_forever</mat-icon>
        </button>

        <button *ngIf="selectedFolder != 'Trash'" mat-icon-button color="warn" matTooltip="{{ 'general.delete' | translate }}" (click)="deleteMail(mails[selectedMail])">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <div class="h-spacer" style="margin-top: 0"></div>

      <div id="mail-detail-header">
        <div id="mail-detail-header-subject">
          {{ mails[selectedMail].subject }}
        </div>

        <div id="mail-detail-header-from">
          <mat-icon>person</mat-icon>
          {{ "internal.mails.from" | translate }}: <span class="name">{{ mails[selectedMail].from.addresses[0].name }}</span>
          <span>&lt;<span class="address">{{ mails[selectedMail].from.addresses[0].address }}</span>&gt;</span>
        </div>

        <div id="mail-detail-header-to">
          <mat-icon>people</mat-icon>
          {{ "internal.mails.to" | translate }}: <span class="address">{{ to(mails[selectedMail]) }}</span>
        </div>

        <div *ngIf="mails[selectedMail].cc.addresses.length > 0" id="mail-detail-header-cc">
          <mat-icon>people</mat-icon>
          Cc: <span class="address">{{ cc(mails[selectedMail]) }}</span>
        </div>

        <div id="mail-detail-header-date">
          <mat-icon>schedule</mat-icon>
          <span>{{ mails[selectedMail].date | date: "dd.MM.yyyy HH:mm" }}</span>
        </div>
      </div>

      <div *ngIf="mails[selectedMail].attachments.length > 0" style="display: contents">
        <div class="h-spacer"></div>

        <div id="mail-detail-attachments">
          <div
            *ngFor="let att of mails[selectedMail].attachments"
            class="mail-attachment"
            [matMenuTriggerFor]="attachMenu"
            [matMenuTriggerData]="{ attachment: att }">
            <mat-icon>attach_file</mat-icon>
            {{ att.fileName }}
          </div>

          <mat-menu #attachMenu="matMenu">
            <ng-template matMenuContent let-att="attachment">
              <button mat-menu-item (click)="downloadAttachment(mails[selectedMail], att.fileName, false)">
                {{ "internal.mails.view" | translate }}
              </button>

              <button mat-menu-item (click)="downloadAttachment(mails[selectedMail], att.fileName, true)">
                {{ "internal.mails.download" | translate }}
              </button>
            </ng-template>
          </mat-menu>
        </div>
      </div>

      <div class="h-spacer"></div>

      <div id="mail-detail-content">
        <div [innerHTML]="replaceMailAddresses(mails[selectedMail].html ? mails[selectedMail].html : mails[selectedMail].textAsHtml)" (click)="processMailClick($event)"></div>
      </div>
    </div>
  </div>

  <div *ngIf="loadingMails || loading" class="loading-bg">
    <mat-spinner diameter="100"></mat-spinner>
  </div>
</div>
