import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { TAGMusicWish } from "../models/tagmusicwish";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { ApiService } from "../api.service";
import { Permission } from "../models/tagpermission";

@Component({
  selector: "app-internalMusicwishes",
  templateUrl: "./internal.musicwishes.component.html",
  styleUrls: ["./internal.musicwishes.component.scss"],
})
export class InternalMusicwishesComponent implements OnInit {
  pageSize: number = 25;
  currentPage: number = 0;
  displayedColumns: string[] = ["username", "title", "artist", "votes", "played"];
  dataSource: MatTableDataSource<TAGMusicWish>;

  musicwishes: TAGMusicWish[] = [];

  reloadTimer: any = undefined;

  constructor(private api: ApiService, public dialog: MatDialog) {}

  wishesEnabled(): boolean {
    return this.api.config.enableMusicWishes;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  switchPage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;

    var startIndex = this.currentPage * this.pageSize;
    this.dataSource = new MatTableDataSource(this.musicwishes.slice(startIndex, startIndex + this.pageSize));
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.reloadMusicWishes();
    });
  }

  ngOnDestroy(): void {
    if (this.reloadTimer) clearInterval(this.reloadTimer);
  }

  toggleAutoReload(event) {
    if (event.checked) {
      if (this.reloadTimer) clearInterval(this.reloadTimer);
      this.reloadTimer = setInterval(() => {
        this.reloadMusicWishes();
      }, 1000);
    } else {
      if (this.reloadTimer) clearInterval(this.reloadTimer);
      this.reloadTimer = undefined;
    }
  }

  reloadMusicWishes() {
    this.api.getMusicWishes().then((result) => {
      this.musicwishes = result;
      this.switchPage({ pageIndex: 0, pageSize: this.pageSize });
    }).catch((reason) => {
      this.musicwishes = [];
      this.switchPage({ pageIndex: 0, pageSize: this.pageSize });
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toggleMusicWishPlayed(musicwish: TAGMusicWish) {
    musicwish.played = !musicwish.played;

    this.api.updateMusicWish(musicwish).then((result) => {
      console.log(result);
      this.reloadMusicWishes();
    }).catch((reason) => {
      console.log(reason);
      //TODO: Show Error
    });
  }

  deleteMusicWish(musicwish: TAGMusicWish) {
    if (!this.hasPerm("delete_musicwishes")) return;

    openDeleteDialog(this.dialog, "Music Wish", [musicwish.title])
      .afterClosed()
      .subscribe((result) => {
        if (result)
          this.api.deleteMusicWishes(musicwish._id).then((result) => {
            this.musicwishes.splice(this.musicwishes.indexOf(musicwish), 1);
            this.reloadMusicWishes();
          }).catch((reason) => {
            //TODO: Show Error
          });
      });
  }

  deleteAllMusicwishes() {
    openDeleteDialog(this.dialog, "Music Wish", ["All Musicwishes"])
      .afterClosed()
      .subscribe((result) => {
        if (result)
          this.api.deleteAllMusicWishes().then((result) => {
            this.reloadMusicWishes();
          }).catch((reason) => {
            //TODO: Show Error
          });
      });
  }
}
