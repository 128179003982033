import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { TAGEvent } from 'src/app/models/tagevent';
import { TAGTransaction } from 'src/app/models/tagfinances';
import { TAGUser } from 'src/app/models/taguser';

@Component({
  selector: 'finance-transaction-list-totalitem',
  templateUrl: './finance-transaction-list-totalitem.component.html',
  styleUrls: ['./finance-transaction-list-totalitem.component.scss']
})
export class FinanceTransactionListTotalItemComponent implements OnInit {

  @Input() total: number;
  @Input() totalPending: number;

  constructor() { }

  ngOnInit(): void {
  }
}
