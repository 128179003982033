<div id="int-content-topbar">
  <button mat-stroked-button (click)="this.cancelPresence()">{{ "general.cancel" | translate }}</button>
  <button mat-stroked-button (click)="this.savePresence()">{{ "general.save" | translate }}</button>
  <button
    mat-stroked-button
    class="deletebtn"
    (click)="this.deletePresence()"
    *ngIf="this.presence != null && this.presence._id != undefined && hasPerm('delete_presence')">
    {{ "general.delete" | translate }}
  </button>
</div>

<div id="int-presence-content">
  <div class="int-presence-edittitle">{{ "internal.presence.editTitle" | translate }} {{ this.presence.startdate | date }}</div>
  <div id="int-presence-textgrid">
    <mat-form-field>
      <mat-placeholder>{{ "internal.presence.startdate" | translate }}</mat-placeholder>
      <input matInput [(ngModel)]="this.presence.startdate" [min]="today" [max]="max" [matDatetimepicker]="startdatepicker" required />
      <mat-datetimepicker-toggle [for]="startdatepicker" matSuffix></mat-datetimepicker-toggle>
      <mat-datetimepicker #startdatepicker type="datetime" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-placeholder>{{ "internal.presence.enddate" | translate }}</mat-placeholder>
      <input matInput [(ngModel)]="this.presence.enddate" [min]="this.presence.startdate" [max]="max" [matDatetimepicker]="enddatepicker" required />
      <mat-datetimepicker-toggle [for]="enddatepicker" matSuffix></mat-datetimepicker-toggle>
      <mat-datetimepicker #enddatepicker type="datetime" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
    </mat-form-field>
  </div>

  <div class="int-presence-edittitle">{{ "internal.presence.editInfoTitle" | translate }}</div>

  <div id="int-presence-legend-grid">
    <!-- Removed 1, 2 for no covid -->
    <div *ngFor="let pres of [0, 3, 4, 5, 'default']; let i = index" class="int-presence-col">
      <div class="int-presence-cell" [style.background]="getPresenceColor(pres)">
        <mat-icon class="noselect">{{ getPresenceIcon(pres) }}</mat-icon>
      </div>

      <div class="int-presence-cell">
        <div class="noselect" style="font-size: 16px">{{ "internal.presence.tooltips." + pres | translate }}</div>
      </div>

      <div></div>
    </div>
  </div>

  <div id="int-presence-grid">
    <div class="int-presence-cell text-vertically" [style.grid-area]="'1 / 1'">
      <div class="noselect">
        {{ "internal.presence.id" | translate }}
      </div>
    </div>

    <div class="int-presence-cell text-vertically" style="line-height: 150px" [style.grid-area]="'1 / 2'">
      <div class="noselect">
        {{ "internal.presence.name" | translate }}
      </div>
    </div>

    <div class="int-presence-cell text-vertically" [style.grid-area]="'1 / 3'">
      <div class="noselect">
        {{ "internal.presence.presence" | translate }}
      </div>
    </div>

<!--     <div class="int-presence-cell text-vertically" style="line-height: 200px" [style.grid-area]="'1 / 4'">
      <div class="noselect">
        {{ "internal.presence.timer" | translate }}
      </div>
    </div> -->

    <div *ngFor="let username of seenUsers; let i = index" style="display: contents">
      <div class="int-presence-cell noselect" [style.grid-area]="i + 2 + ' / 1'">
        {{ i + 1 }}
      </div>

      <div class="int-presence-cell" [style.grid-area]="i + 2 + ' / 2'">
        <div class="noselect" style="font-size: 16px">
          <mat-icon *ngIf="getUserName(username) == username" class="noselect">delete</mat-icon>
          {{ getUserName(username) }}
        </div>
      </div>

      <div class="int-presence-cell touch" [style.grid-area]="i + 2 + ' / 3'" (click)="changePresence(username)" [style.background]="getUserColor(username)">
        <mat-icon class="noselect">{{ getUserIcon(username) }}</mat-icon>
      </div>

      <!-- <div
        class="int-presence-cell noselect"
        style="display: flex; width: calc(100% - 10px); height: calc(100% - 10px); padding: 5px"
        [style.grid-area]="i + 2 + ' / 4'"
        [style.background-color]="getDoneTimer(username) ? '#ffc107' : !getRunningTimer(username) ? '#ffffff33' : '#f44336'">
        <div class="int-presence-timer-text">
          {{ getRemainingTimer(username) | formatTime }}
        </div>
        <button class="int-presence-timer-reset" (click)="resetTimer(username)" style="margin-left: auto" mat-icon-button>
          <mat-icon>update</mat-icon>
        </button>
        <button *ngIf="!getDoneTimer(username)" class="int-presence-timer-startstop" (click)="startstopTimer(username)" mat-icon-button>
          <mat-icon>{{ getRunningTimer(username) ? "pause" : "play_arrow" }}</mat-icon>
        </button>
        <button *ngIf="getDoneTimer(username)" class="int-presence-timer-negative" (click)="acceptTimer(username, true)" mat-icon-button>
          <mat-icon>check</mat-icon>
        </button>
        <button *ngIf="getDoneTimer(username)" class="int-presence-timer-positive" (click)="acceptTimer(username, false)" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div> -->
    </div>
  </div>
</div>
