<div id="int-content-topbar">
  <internal-menu-button id="menuBtn"></internal-menu-button>
  <button *ngIf="hasPerm('create_users')" mat-stroked-button routerLink="new" routerLinkActive="active">{{ "internal.users.newUser" | translate }}</button>
</div>

<div id="int-users-content">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'general.search' | translate }}" />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table class="table-large" mat-table [dataSource]="dataSource" matSort>
      <ng-container class="column-wide" matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.users.username" | translate }}</th>
        <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_users') ? row._id : './'" routerLinkActive="active">{{ row.username ?? "" }}</td>
      </ng-container>

      <ng-container class="column-wide" matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.users.email" | translate }}</th>
        <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_users') ? row._id : './'" routerLinkActive="active">
          {{ (row.email == "" ? "internal.users.notSet" : row.email) | translate }}
        </td>
      </ng-container>

      <ng-container class="column-wide" matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.users.name" | translate }}</th>
        <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_users') ? row._id : './'" routerLinkActive="active">
          {{ (row.firstname ?? "") + " " + (row.lastname ?? "") }}
        </td>
      </ng-container>

      <ng-container class="column-narrow" matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.users.active" | translate }}</th>
        <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_users') ? row._id : './'" routerLinkActive="active">
          <div *ngIf="row.active">{{ "internal.users.active" | translate }}</div>
          <div *ngIf="!row.active">{{ "internal.users.inactive" | translate }}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table class="table-small" mat-table [dataSource]="dataSource" matSort>
      <ng-container class="column-wide" matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.users.username" | translate }}</th>
        <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_users') ? row._id : './'" routerLinkActive="active">{{ row.username ?? "" }}</td>
      </ng-container>

      <ng-container class="column-wide" matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.users.name" | translate }}</th>
        <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_users') ? row._id : './'" routerLinkActive="active">
          {{ (row.firstname ?? "") + " " + (row.lastname ?? "") }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsSmall"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsSmall"></tr>
    </table>

    <mat-paginator [length]="users.length" [pageSize]="pageSize" (page)="switchPage($event)" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
