import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DeleteDialogData {
  type: string;
  objects?: string[];
}

@Component({
  selector: "deleteDialog",
  templateUrl: "deleteDialog.html",
  styleUrls: ["./deleteDialog.scss"],
})
export class DeleteDialog {
  constructor(public dialogRef: MatDialogRef<DeleteDialog>, @Inject(MAT_DIALOG_DATA) public data: DeleteDialogData) {}

  cancel(): void {
    this.dialogRef.close(false);
  }

  delete(): void {
    this.dialogRef.close(true);
  }
}

export function openDeleteDialog(dialog: MatDialog, type: string, objects?: string[]) {
  return dialog.open(DeleteDialog, {
    minWidth: "200px",
    width: "400px",
    data: {
      type: type,
      objects: objects,
    },
  });
}
