<div id="groupedit-header">
  {{ 'internal.inventory.groupEdit.title' | translate }}
  <div>{{group._id}}</div>
</div>

<div id="groupedit-form">
  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.inventory.groupEdit.name' | translate }}</mat-label>
    <input matInput placeholder="Blaue Kabel Kiste" [disabled]="editingDisabled" [(ngModel)]="group.name" />
    <mat-icon matSuffix>edit</mat-icon>
  </mat-form-field>

  <form [formGroup]="scannerForm" (ngSubmit)="scanCode()">
    <mat-form-field>
      <input #scannerInput matInput placeholder="{{ 'internal.inventory.scanner' | translate }}" formControlName="input" />
      <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
    </mat-form-field>
  </form>

  <div id="item-list">
    <div id="item-list-header">
      <div id="item-list-header-title">{{ 'internal.inventory.items' | translate }}</div>
    </div>

    <div id="hspacer"></div>

    <div id="item-list-content">
      <div *ngFor="let types of itemTypes" class="item">
        <div class="item-name">{{types.name}}</div>
        <div class="instance-list">
          <div *ngFor="let instance of itemFilter(types._id)" class="instance">
            <div class="instance-name">{{types.name}}</div>
            <div *ngIf="types.additionalInfo" class="instance-addInfo">{{types.additionalInfo}}</div>
            <div class="instance-id">{{instance.instanceid}}</div>
            <div class="instance-status">{{(instanceBooked(types._id, instance.instanceid)? "internal.inventory.notReturned" : "") | translate}}</div>
            <div *ngIf="instanceDeleted(types._id, instance.instanceid)" class="instance-status">{{ 'internal.inventory.deleted' | translate }}</div>
            <div class="instance-actions">
              <button mat-icon-button (click)="selectItem(types, [instance.instanceid])"><mat-icon>visibility</mat-icon></button>
              <button mat-icon-button (click)="removeItem(types._id, instance.instanceid)"><mat-icon>delete</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="groupedit-actions">
  <button *ngIf="group._id" mat-stroked-button [disabled]="deletionDisabled" color="warn" (click)="deleteGroup()">{{ 'general.delete' | translate }}</button>
  <button mat-stroked-button color="warn" (click)="cancelEdit()">{{ 'general.cancel' | translate }}</button>
  <button mat-stroked-button [disabled]="editingDisabled" (click)="saveEdit()">
    {{ 'general.save' | translate }}
  </button>
</div>
