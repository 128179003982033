<div id="requestEdit-header">
  {{ 'internal.requests.editTitle' | translate: request }}
</div>

<div id="requestEdit-form">

  <mat-form-field appearance="standard">
    <mat-label>{{ "internal.requests.name" | translate }}</mat-label>
    <input matInput [(ngModel)]="request.name" required/>
    <mat-icon matSuffix>info</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.requests.location' | translate }}</mat-label>
    <input matInput placeholder="Location" [(ngModel)]="request.location" [formControl]="locationControl" [matAutocomplete]="locationAutocomplete" />
    <mat-icon matSuffix>location_on</mat-icon>

    <mat-autocomplete #locationAutocomplete="matAutocomplete">
      <mat-option *ngFor="let loc of filteredLocations | async" [value]="loc.name"> {{loc.name}} </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ "internal.requests.contact" | translate }}</mat-label>
    <input matInput [(ngModel)]="request.contact" required/>
    <mat-icon matSuffix>person</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ "internal.requests.contactMail" | translate }}</mat-label>
    <input matInput [(ngModel)]="request.contactMail" required/>
    <mat-icon matSuffix>mail</mat-icon>
  </mat-form-field>

  <mat-form-field>
    <mat-placeholder>{{ "internal.requests.startdate" | translate }}</mat-placeholder>
    <input matInput [(ngModel)]="request.startdate" [min]="today" [max]="max" [matDatetimepicker]="startdatepicker" required />
    <mat-datetimepicker-toggle [for]="startdatepicker" matSuffix></mat-datetimepicker-toggle>
    <mat-datetimepicker #startdatepicker type="datetime" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-placeholder>{{ "internal.requests.enddate" | translate }}</mat-placeholder>
    <input matInput [(ngModel)]="request.enddate" [min]="request.startdate" [max]="max" [matDatetimepicker]="enddatepicker" required />
    <mat-datetimepicker-toggle [for]="enddatepicker" matSuffix></mat-datetimepicker-toggle>
    <mat-datetimepicker #enddatepicker type="datetime" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
  </mat-form-field>

  <mat-form-field class="fullwidth" appearance="standard">
    <mat-label>{{ "internal.requests.equipment" | translate }}</mat-label>
    <textarea matInput [(ngModel)]="request.equipment" ></textarea>
    <mat-icon matSuffix>memory</mat-icon>
  </mat-form-field>

  <!-- <div class="form-subtitle">
    {{ 'internal.events.documents' | translate }}
  </div>

  <document-grid
    [documents]="allFiles()"
    [urlTemplate]="getDocumentUrl(event._id)"
    [showDownload]="true"
    [showView]="true"
    [showUpload]="true"
    [showDelete]="true"
    (uploadClicked)="uploadFile($event)"
    (deleteClicked)="deleteFile($event)">
  </document-grid> -->
</div>

<div id="requestEdit-actions">
  <button mat-stroked-button (click)="promoteRequest()" *ngIf="(request?._id ?? undefined) != undefined && hasPerm('promote_to_event')">{{ "internal.requests.promoteRequest" | translate }}</button>
  <button mat-stroked-button color="warn" (click)="deleteRequest()" *ngIf="request._id != undefined && hasPerm('delete_requests')">
    {{ "general.delete" | translate }}
  </button>
  <button mat-stroked-button (click)="cancelRequest()">{{ "general.cancel" | translate }}</button>
  <button mat-stroked-button (click)="saveRequest()">{{ "general.save" | translate }}</button>
</div>
