<div id="invTransactionImport-header">
  {{ 'internal.events.invTransactionImportTitle' | translate }}
</div>

<div id="invTransactionImport-list">
  <inv-transaction-list-item
    *ngFor="let trans of transactions"
    [transaction]="trans"
    [showViewButton]="false"
    [showPrintButton]="false"
    [showSelectButton]="true"
    (selected)="selectedTransaction($event)">
  </inv-transaction-list-item>
</div>
