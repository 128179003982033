import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from "../api.service";
import { TAGUser } from "../models/taguser";
import { Permission } from "../models/tagpermission";

@Component({
  selector: "app-internalUsers",
  templateUrl: "./internal.users.component.html",
  styleUrls: ["./internal.users.component.scss"],
})
export class InternalUsersComponent implements OnInit {
  pageSize: number = 25;
  currentPage: number = 0;
  displayedColumns: string[] = ["username", "email", "name", "active"];
  displayedColumnsSmall: string[] = ["username", "name"];
  dataSource: MatTableDataSource<TAGUser>;

  users: TAGUser[] = [];

  constructor(private api: ApiService) {}

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  switchPage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;

    var startIndex = this.currentPage * this.pageSize;
    this.dataSource = new MatTableDataSource(this.users.slice(startIndex, startIndex + this.pageSize));
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.reloadUsers();
    });
  }

  reloadUsers() {
    this.api.getUsers().then((result) => {
      this.users = result;
      this.switchPage({ pageIndex: 0, pageSize: this.pageSize });
    }).catch((reason) => {
      //TODO: Show Error
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
