import { DBModel } from "./DBModel";

export class TAGRequest extends DBModel {
  name: string = "";
  contact: string = "";
  contactMail: string = undefined;

  location: string = "";

  createdate: Date = new Date();
  startdate: Date = new Date();
  enddate: Date = new Date();

  equipment: string = "";
  owner: string = "";

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  serialize(): any {
    var obj: any = super.serialize();

    obj.name = this.name;
    obj.contact = this.contact;
    obj.contactMail = this.contactMail;

    obj.location = this.location;

    obj.createdate = this.createdate;
    obj.startdate = this.startdate;
    obj.enddate = this.enddate;

    obj.equipment = this.equipment;
    obj.owner = this.owner;

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.name != null) this.name = data.name;
    if (data.contact != null) this.contact = data.contact;
    if (data.contactMail != null) this.contactMail = data.contactMail;

    if (data.location != null) this.location = data.location;

    if (data.createdate != null) this.createdate = new Date(data.createdate);
    if (data.startdate != null) this.startdate = new Date(data.startdate);
    if (data.enddate != null) this.enddate = new Date(data.enddate);

    if (data.equipment != null) this.equipment = data.equipment;
    if (data.owner != null) this.owner = data.owner;
  }
}
