import { Component, OnInit } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { ApiService } from "../api.service";
import { Permission } from "../models/tagpermission";

@Component({
  selector: "app-internal.schulradio",
  templateUrl: "./internal.schulradio.component.html",
  styleUrls: ["./internal.schulradio.component.scss"],
})
export class InternalSchulradioComponent implements OnInit {
  loggedIn: boolean = false;

  playerState: PlayerState = new PlayerState({});
  playlist: Song[] = [];
  votelist: SongWish[] = [];
  breakState: BreakState = new BreakState({});

  timeInterpolationTimer: any = null;

  constructor(private api: ApiService, private socket: Socket) {}

  nextBreakRemaining() {
    return Math.floor(this.breakState.nextBreak.getTime() / 1000) - Math.floor(new Date().getTime() / 1000);
  }

  hasControlPermissions() {
    return this.api.user.hasPermission(Permission.control_playback);
  }

  hasBreakPermissions() {
    return this.api.user.hasPermission(Permission.control_breaks);
  }

  hasPlayerPermissions() {
    return this.api.user.hasPermission(Permission.control_player);
  }

  ngOnInit(): void {
    this.socket.on("playerState", (data) => {
      console.log(data);
      this.playerState = new PlayerState(data);
    });

    this.socket.on("playlistState", (data) => {
      this.playlist = data.playlist;
      this.votelist = data.voteList;
      console.log(data);
    });

    this.socket.on("breakState", (data) => {
      this.breakState = new BreakState(data);
      console.log(data);
    });

    this.socket.on("login", (data) => {
      this.loggedIn = data.success;
    });

    this.socket.emit("login", {});

    this.startTimeInterpolator();
  }

  startTimeInterpolator() {
    if (this.timeInterpolationTimer) {
      this.stopTimeInterpolator();
    }

    this.timeInterpolationTimer = setInterval(() => {
      if (this.breakState && this.breakState.break) this.breakState.remaining = Math.max(this.breakState.remaining - 1, 0);
    }, 1000);
  }

  stopTimeInterpolator() {
    clearInterval(this.timeInterpolationTimer);
  }

  forceStartBreak() {
    this.api.setSchulradioBreak(true);
  }

  forceEndBreak() {
    this.api.setSchulradioBreak(false);
  }

  playPauseSong() {
    this.api.setSchulradioPlayback(!this.playerState.playing);
  }

  skipSong() {
    this.api.skipSchulradioTitle();
  }

  updatePlayer() {
    this.api.initSchulradioPlayerUpdate();
  }
}

export class BreakState {
  enabled: boolean;
  break: boolean;
  duration: number;
  remaining: number;
  nextBreak: Date;

  constructor(data: any) {
    if (!data) return;

    this.enabled = data.enabled;
    this.break = data.break;
    this.duration = data.duration;
    this.remaining = data.remaining;
    this.nextBreak = new Date(data.nextBreak);
  }
}

export class PlayerState {
  playing: boolean;
  currentTime: number;
  duration: number;
  song: Song;
  nextSong: Song;

  constructor(data: any) {
    if (!data) return;

    this.playing = data.playing;
    this.currentTime = data.currentTime;
    this.duration = data.duration;
    this.song = new Song(data.song);
    this.nextSong = new Song(data.nextSong);
  }
}

export class Song {
  title: string = "";
  artist: string = "";
  duration: number = 0;
  url: string = "";
  cover: string = "";

  isVote: boolean = false;

  constructor(data: any) {
    if (!data) return;

    this.title = data.title;
    this.artist = data.artist;
    this.duration = data.duration;
    this.url = data.url;
    this.cover = data.cover;
    this.isVote = data.isVote;
  }
}

export class SongWish extends Song {
  id: number = -1;

  upvotes: number = 0;
  downvotes: number = 0;

  constructor(data: any) {
    super(data);

    if (!data) return;

    this.id = data.id;
    this.upvotes = data.upvotes;
    this.downvotes = data.downvotes;
  }

  fromSong(song: Song) {
    this.title = song.title;
    this.artist = song.artist;
    this.duration = song.duration;
    this.url = song.url;
    this.cover = song.cover;

    return this;
  }
}
