import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { TAGInvItemType, TAGInvTransaction } from "../models/taginventory";
import { ItemEditDialog } from "./itemEditDialog";
import { getInventoryInstanceByCode } from "./inventoryHelper";

export interface TransactionEditDialogData {
  transaction: TAGInvTransaction;
}

@Component({
  selector: "int-inventory-transactionEditDialog",
  templateUrl: "transactionEditDialog.html",
  styleUrls: ["./transactionEditDialog.scss"],
})
export class TransactionEditDialog {
  //TODO: Form with validation

  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  scannerForm = this.fb.group({
    input: [""],
  });

  transaction: TAGInvTransaction = new TAGInvTransaction();
  itemTypes: TAGInvItemType[] = [];

  returnedItems: { itemid: string; instanceid: string }[] = [];
  username: string = "";

  instanceDeleted(itemid: string, instanceid: string) {
    var item = this.itemTypes.find((itm) => itm._id == itemid);
    if (!item) return false;

    var instance = item.instances.find((inst) => inst.itemid == instanceid);
    if (!instance) return false;

    return item.deleted || instance.deleted;
  }

  constructor(
    public api: ApiService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TransactionEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionEditDialogData
  ) {
    api.waitForAPI().then(() => {
      api.getInvItems().then((items) => {
        this.transaction = new TAGInvTransaction(Object.assign({}, data.transaction));

        console.log(this.transaction);
        this.itemTypes = items.filter((item) => this.transaction.items.findIndex((itm) => itm.itemid == item._id) != -1);
        console.log(this.itemTypes);

        console.log(this.itemFilter(this.itemTypes[0]._id));

        if (this.transaction.allItemsBack()) {
          this.scannerInput.nativeElement.disabled = true;
        }
      }).catch((reason) => {
        console.error(reason);
      });

      api.getUser(data.transaction.user).then((user) => {
        this.username = user.fullname;
      }).catch((reason) => {

      });
    });

    this.dialogRef.disableClose = true;
  }

  cancelEdit(): void {
    this.dialogRef.close(false);
  }

  saveEdit(): void {
    this.api.returnTransactionItems(
      this.transaction._id,
      this.returnedItems).then((result) => {
        this.dialogRef.close(true);
      }).catch((reason) => {
        console.error(reason);
      });
  }

  ngAfterViewInit() {
    this.resetScannerField();
  }

  selectItem(item: TAGInvItemType, selectedInstances?: string[]) {
    let width = "50%";
    if (window.innerWidth < 600) {
      width = "100%";
    }

    const dialogRef = this.dialog.open(ItemEditDialog, {
      minWidth: "300px",
      width: width,
      data: { item: item, selectedInstances: selectedInstances },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.resetScannerField();
    });
  }

  resetScannerField() {
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: { multiple: true },
    });

    dialogRef.componentInstance.onCodeScan.subscribe((code: string) => {
      this.codeScanned(code);
      this.resetScannerField();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  codeScanned(code: string) {
    getInventoryInstanceByCode(code, this.api).then((result) => {
      let item = result.item;
      let instanceID = result.instanceID;

      console.log(result);

      if (item && instanceID) {
        this.itemScanned(item._id, instanceID);
      }
    }).catch((reason) => {
      console.log(reason);

      if (this.api.config.allowGroupsForBookIn) {
        this.api.getInvItemGroup(code).then((group) => {
          if (group) {
            for (let gItem of group.items) {
              this.itemScanned(gItem.itemid, gItem.instanceid);
            }
          }
        }).catch((reason) => {
          console.error(reason);
        });
      }
    }).finally(() => {
      this.resetScannerField();
    });
  }

  itemScanned(itemID: string, instID: string) {
    var index: number = this.transaction.items.findIndex((comb) => comb.itemid == itemID && comb.instanceid == instID);
    if (index != -1 && this.transaction.items[index].back == null) {
      this.transaction.items[index].back = new Date();
      this.returnedItems.push({ itemid: itemID, instanceid: instID });
    }
  }

  itemFilter(itemid: string) {
    return this.transaction.items.filter((comb) => comb.itemid == itemid);
  }

  itemComplete(itemid: string) {
    return this.itemFilter(itemid).filter((comb) => comb.back == null).length == 0;
  }
}
