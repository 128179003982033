import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { sha256 } from "js-sha256";
import { ApiService } from "src/app/api.service";
import { Permission } from "src/app/models/tagpermission";

export interface OAuthAuthorizationRequest {
  client_id: string;
  response_type: string;
  redirect_uri: string;
  scope: string;
  state: string;
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class OAuthLoginComponent implements OnInit {
  issuerURL: string = "https://technikflg.com";

  authRequest: OAuthAuthorizationRequest = undefined;
  requiredPermission: Permission = null;

  clientName: string = "";

  error: string = "";
  loading: boolean = true;

  loginform = this.fb.group({
    username: ["", Validators.required],
    password: ["", Validators.required],
  });

  formError: string = "";

  constructor(private api: ApiService, private fb: UntypedFormBuilder, private route: ActivatedRoute, private router: Router) {}
  loggedIn() {
    return this.api.isLoggedIn();
  }
  username() {
    return this.api.getFullname();
  }

  ngOnInit(): void {
    this.api
      .waitForAPI()
      .then((result) => {
        this.route.queryParams.subscribe((params) => {
          this.authRequest = params as OAuthAuthorizationRequest;

          if (this.authRequest.client_id == undefined) {
            this.error = "client_id is missing";
            return;
          }

          if (this.authRequest.redirect_uri == undefined) {
            this.error = "response_type is missing";
            return;
          }

          // Check Client ID and Redirect URI with API
          this.api
            .checkOAuthClient(this.authRequest)
            .then((result) => {
              this.requiredPermission = Permission.fromName(result[0]);
              this.clientName = result[1];

              if (this.authRequest.response_type == undefined || (this.authRequest.scope == undefined && result[2]) || this.authRequest.state == undefined) {
                this.redirectError("invalid_request", "Invalid Authorization Request", this.authRequest.state);
                return;
              }

              if (this.authRequest.response_type != "code") {
                this.redirectError("unsupported_response_type", "The response type is not supported.", this.authRequest.state);
                return;
              }

              if (result[2] && !this.authRequest.scope.includes("openid")) {
                this.redirectError("invalid_scope", "The scope parameter must include the openid scope.", this.authRequest.state);
                return;
              }

              this.showLogin();
            })
            .catch((reason) => {
              this.error = reason;
            });
        });
      })
      .catch((reason) => {
        this.error = reason;
      });
  }

  showLogin() {
    if (!this.api.isLoggedIn() || this.api.hasPermission(this.requiredPermission)) {
      this.loading = false;
    } else {
      this.error = "You don't have the required permission to use this application.";
    }
  }

  login() {
    if (!this.loginform.valid) return;

    this.loading = true;

    this.api.login(this.loginform.value.username,sha256(this.loginform.value.password)).then((result) => {
      this.showLogin();
    }).catch((reason) => {
      this.loading = false;
      if (reason.error == 2) {
        this.formError = "login.error.invalid";
      } else if (reason.error == 3 || reason.error == 7) {
        this.formError = "login.error.wronguser";
      } else {
        this.formError = "An unknown error occured.";
      }
    });
  }

  logout() {
    this.api.logout();
  }

  decline() {
    this.redirectError("access_denied", "The resource owner denied the request.", this.authRequest.state);
  }

  accept() {
    this.api
      .acceptOAuthRequest(this.authRequest)
      .then((result) => {
        window.location.href = result;
      })
      .catch((reason) => {
        this.error = reason;
      });
  }

  redirectError(error: string, error_description: string, state: string) {
    window.location.href = this.authRequest.redirect_uri + "?iss=" + this.issuerURL + "&error=" + error + "&error_description=" + error_description + "&state=" + state;
  }

  goBack() {
    window.history.back();
  }
}
