import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../api.service";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { Permission } from "../models/tagpermission";
import { TAGPresence } from "../models/tagpresence";
import { TAGUser } from "../models/taguser";

class Timer {
  timerId;
  start: number;
  time: number;
  remaining: number;
  done: boolean = false;

  constructor(time: number) {
    this.time = time * 1000;
    this.remaining = time * 1000;
  }

  pause() {
    clearTimeout(this.timerId);
    this.timerId = null;
    this.remaining -= Date.now() - this.start;
  }

  resume() {
    this.start = Date.now();
    clearTimeout(this.timerId);
    this.timerId = null;
    this.timerId = setTimeout(
      () => {
        if (this.remaining > 1000) this.remaining -= 1000;
        else this.remaining = 0;

        if (this.remaining <= 0) {
          this.timerId = null;
          this.done = true;
        } else {
          this.resume();
        }
      },
      this.remaining > 1000 ? 1000 : this.remaining
    );
  }

  startstop() {
    if (!this.running()) this.resume();
    else this.pause();
  }

  reset() {
    this.pause();

    this.remaining = this.time;
    this.done = false;
  }

  running() {
    return this.timerId != null;
  }

  getRemaining() {
    if (!this.running()) return Math.floor(this.remaining / 1000);

    return Math.floor((this.remaining - (Date.now() - this.start)) / 1000);
  }
}

@Component({
  selector: "app-internal.presence",
  templateUrl: "./internal.presence.edit.component.html",
  styleUrls: ["./internal.presence.edit.component.scss"],
})
export class InternalPresenceEditComponent implements OnInit {
  presence: TAGPresence = new TAGPresence({});
  users: TAGUser[] = [];
  seenUsers: string[] = [];

  today = new Date();
  max = new Date();

  timers: Map<string, Timer> = new Map<string, Timer>();

  constructor(private api: ApiService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute) {
    this.max.setDate(this.today.getDate() + 365);
  }

  getUserColor(user: string) {
    return this.presence.getUserColor(user);
  }

  getUserIcon(user: string) {
    return this.presence.getUserIcon(user);
  }

  getPresenceColor(value: number) {
    return TAGPresence.getColor(value);
  }

  getPresenceIcon(value: number) {
    return TAGPresence.getIcon(value);
  }

  getUserName(username: string) {
    var usr = this.users.find((u) => u.username == username);
    if (usr != undefined) return usr.firstname + " " + usr.lastname;

    return username;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.api.waitForAPI().then(() => {
        this.loadPresence(params.presenceid);
        this.api.getUsers().then((result) => {
          this.users = result;
          this.users.forEach((u) => {
            if (!this.seenUsers.includes(u.username)) this.seenUsers.push(u.username);
            console.log("Initiating Timer for: " + u.username);
            this.timers.set(u.username, new Timer(15 * 60));
          });
        }).catch((reason) => {
          console.error(reason);
        });
      });
    });
  }

  startstopTimer(username: string) {
    this.timers.get(username).startstop();
  }

  resetTimer(username: string) {
    this.timers.get(username).reset();
  }

  getRemainingTimer(username: string) {
    if (!this.timers.has(username)) return 0;

    return this.timers.get(username).getRemaining();
  }

  getRunningTimer(username: string) {
    if (!this.timers.has(username)) return false;

    return this.timers.get(username).running();
  }

  getDoneTimer(username: string) {
    if (!this.timers.has(username)) return false;

    return this.timers.get(username).done;
  }

  acceptTimer(username: string, negative: boolean) {
    this.presence.presenceInfo[username] = negative ? 2 : 1;
  }

  loadPresence(presenceid: string) {
    if (presenceid == "new") {
      this.presence = new TAGPresence();
    } else
      this.api.getPresence(presenceid).then((result) => {
        this.presence = result;
        Object.keys(this.presence.presenceInfo).forEach((key, val) => {
          if (!this.seenUsers.includes(key)) this.seenUsers.push(key);
          console.log("Initiating Timer for: " + key);
          this.timers.set(key, new Timer(15 * 60));
        });
      }).catch((reason) => {
        //TODO: Show Error
      });
  }

  changePresence(user: string) {
    var oldVal = this.presence.presenceInfo[user] ?? -1;
    oldVal++;
    if (oldVal > 5) oldVal = -1;

    if (oldVal == 1) oldVal = 3; // Skip due to no covid
    if (oldVal == 2) oldVal = 3; // Skip due to no covid

    this.presence.presenceInfo[user] = oldVal;
  }

  savePresence() {
    console.log(this.presence);

    Object.keys(this.presence.presenceInfo).forEach((key, val) => {
      if (val == -1) delete this.presence.presenceInfo[key];
    });

    if (this.presence._id != undefined) {
      this.api.updatePresence(
        this.presence,
        (data) => {
          this.goBack();
        },
        (error) => {
          //TODO: Show Error
        }
      );
    } else {
      this.api.newPresence(this.presence).then((result) => {
        this.goBack();
      }).catch((reason) => {
        //TODO: Show Error
      });
    }
  }

  cancelPresence() {
    this.goBack();
  }

  goBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  deletePresence() {
    if (this.presence._id == undefined) return;

    openDeleteDialog(this.dialog, "Presence Information", [this.presence.startdate.toDateString()])
      .afterClosed()
      .subscribe((result) => {
        if (result)
          this.api.deletePresence(
            this.presence._id,
            (data) => {
              this.goBack();
            },
            (error) => {
              //TODO: Show Error
            }
          );
      });
  }
}
