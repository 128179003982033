import { DBModel } from "./DBModel";

export class TAGMusicWish extends DBModel {
  artist: string = "";
  title: string = "";

  owner: string = "";
  createdate: Date = new Date();

  votes: number = 1;
  played: boolean = false;

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  serialize(): any {
    var obj: any = super.serialize();

    obj.artist = this.artist;
    obj.title = this.title;

    obj.owner = this.owner;
    obj.createdate = this.createdate;

    obj.votes = this.votes;
    obj.played = this.played;

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.artist != null) this.artist = data.artist;
    if (data.title != null) this.title = data.title;

    if (data.owner != null) this.owner = data.owner;
    if (data.createdate != null) this.createdate = new Date(data.createdate);

    if (data.votes != null) this.votes = data.votes;
    if (data.played != null) this.played = data.played;
  }
}
