<style>
  #copyright-header {
    width: 100%;
    height: 64px;
    font-size: 64px;
    line-height: 64px;
    font-weight: 100;
    margin-bottom: 15px;
  }

  .copyright_title {
    width: 100%;
    height: 32px;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .copyright_text {
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }
</style>

<div id="copyright-header">Copyright Information</div>
<div id="Copyright-content">
  <div class="copyright_title">1. Website, designs and code.</div>

  <div class="copyright_text">1.1. The website and its design, style(CSS) and code(HTML) are not to be copied or redistributed without previous permission.</div>

  <div class="copyright_title">2. Images.</div>

  <div class="copyright_text">
    2.1. All images on this website are property of TechnikFLG.<br />
    2.2. Images may be copied or used for non-commercial purposes as long as the source is mentioned.<br />
    2.3. Watermarked images can be used without mentioning owner or source.<br />
    2.4. For commercial use please contact us.<br />
  </div>

  <div class="copyright_title">3. 3rd party technologies.</div>

  <div class="copyright_text">tbd</div>
</div>
