import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TAGEvent } from 'src/app/models/tagevent';
import { TAGLocation } from 'src/app/models/taglocation';

@Component({
  selector: 'event-item',
  templateUrl: './event-listitem.component.html',
  styleUrls: ['./event-listitem.component.scss']
})
export class EventListitemComponent implements OnInit {

  @Input() event: TAGEvent;
  @Input() location: string;
  @Output() eventClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  buttonClicked() {
    this.eventClicked.emit();
  }

}
