<div id="todoEdit-header">
  {{ 'internal.todos.editTitle' | translate: todo }}
</div>

<div id="todoEdit-form">

  <mat-form-field appearance="standard">
    <mat-label>{{ "internal.todos.status" | translate }}</mat-label>
    <mat-select [(ngModel)]="todo.status">
      <mat-option *ngFor="let status of allStatus()" [value]="status">
        {{ "status." + status.name | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ "internal.todos.priority" | translate }}</mat-label>
    <mat-select [(ngModel)]="todo.priority">
      <mat-option *ngFor="let priority of allPriorities()" [value]="priority">
        {{ "priority." + priority.name | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ "internal.todos.assignee" | translate }}</mat-label>
    <input matInput [matAutocomplete]="auto" [formControl]="userControl" [(ngModel)]="todo.assignee" />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let user of filteredUsers | async" [value]="user.fullname">
        {{ user.fullname }}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix>info</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="standard" style="grid-column-start: 1; grid-column-end: 3">
    <mat-label>{{ "internal.todos.description" | translate }}</mat-label>
    <textarea matInput style="min-height: 200px" [(ngModel)]="todo.description"></textarea>
    <mat-icon matSuffix>notes</mat-icon>
  </mat-form-field>

  <mat-form-field>
    <mat-placeholder>{{ "internal.todos.duedate" | translate }}</mat-placeholder>
    <input matInput [(ngModel)]="todo.duedate" [min]="today" [max]="max" [matDatetimepicker]="duedatepicker" />
    <mat-datetimepicker-toggle [for]="duedatepicker" matSuffix></mat-datetimepicker-toggle>
    <mat-datetimepicker #duedatepicker type="datetime" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-placeholder>{{ "internal.todos.notification" | translate }}</mat-placeholder>
    <input matInput [(ngModel)]="todo.notification" [min]="today" [max]="max" [matDatetimepicker]="notificationpicker" />
    <mat-datetimepicker-toggle [for]="notificationpicker" matSuffix></mat-datetimepicker-toggle>
    <mat-datetimepicker #notificationpicker type="datetime" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
  </mat-form-field>

</div>

<div id="todoEdit-actions">
  <button mat-stroked-button color="warn" (click)="deleteTodo()" *ngIf="todo._id != undefined && hasPerm('delete_todos')">
    {{ "general.delete" | translate }}
  </button>
  <button mat-stroked-button (click)="cancelTodo()">{{ "general.cancel" | translate }}</button>
  <button mat-stroked-button (click)="saveTodo()">{{ "general.save" | translate }}</button>
</div>
