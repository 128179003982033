import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { ApiService } from '../api.service';
import { TAGLocation } from '../models/taglocation';
import { TAGRequest } from '../models/tagrequest';
import { ProgressDialogStep, ProgressDialogStepState, openProgressDialog } from '../dialogs/progressDialog/progressDialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  isLoading = false;
  showSuccess = false;

  requestForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    location: new FormControl(''),
    contact: new FormControl('', [Validators.required]),
    contactMail: new FormControl('', [Validators.required, Validators.email]),
    startdate: new FormControl(new Date(), [Validators.required]),
    enddate: new FormControl(new Date(), [Validators.required]),
    equipment: new FormControl('', [Validators.required]),
  });

  locations: TAGLocation[] = [];
  filteredLocations: Observable<TAGLocation[]>;

  today = new Date();
  max = new Date();

  constructor(private api: ApiService, private dialog: MatDialog) {
    this.max.setDate(this.today.getDate() + 365);

    this.filteredLocations = this.requestForm.controls["location"].valueChanges.pipe(
      startWith(""),
      map((value) => this._locationFilter(value || ""))
    );
  }

  _locationFilter(filter: String): TAGLocation[] {
    const filterValue = filter.toLowerCase();

    return this.locations.filter((loc) =>
      loc.name.toLowerCase().includes(filterValue) ||
      loc.address.toLowerCase().includes(filterValue)
    );
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.api.getLocations().then((result) => {
        this.locations = result;
      }).catch((reason) => {
        console.error(reason);

      });
    });
  }

  submitForm() {
    console.log(this.requestForm.value);
    if (this.requestForm.valid && !this.isLoading) {
      this.isLoading = true;

      let submitStep: ProgressDialogStep = {
        state: ProgressDialogStepState.WAITING,
        title: "Submitting Request",
      }

      const progressDialog = openProgressDialog(this.dialog, [submitStep]);
      progressDialog.afterOpened().subscribe(() => {

        submitStep.state = ProgressDialogStepState.PROCESSING;

        let request = new TAGRequest(this.requestForm.value);
        let location = this.locations.find(loc => loc.name == request.location);
        if (location) {
          request.location = location._id;
        }

        this.api.newRequest(request).then((result) => {
          this.showSuccess = true;
          submitStep.state = ProgressDialogStepState.SUCCESS;
          progressDialog.componentInstance.finishDialog(0);
        }).catch((reason) => {
          this.isLoading = false;
          submitStep.state = ProgressDialogStepState.ERROR;
          submitStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
          console.error(reason);
        });

      });
    }
  }

}
