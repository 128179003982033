<div id="mailsLoginInformation-header">
  {{ 'internal.mails.mailsLoginInformation.title' | translate }}
</div>

<div *ngIf="!loading" class="mailsLoginInformation-group">
  <span class="title">{{ 'internal.mails.mailsLoginInformation.easytitle' | translate }}</span>
  <div class="grid">
    <span>{{ 'internal.mails.mailsLoginInformation.domain' | translate }}</span>
    <click-to-copy text="technikflg.com"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.username' | translate }}</span>
    <click-to-copy [text]="userMailInfo.email"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.password' | translate }}</span>
    <click-to-copy [text]="userMailInfo.password"></click-to-copy>
  </div>
</div>

<div *ngIf="!loading" class="mailsLoginInformation-group">
  <span class="title">{{ 'internal.mails.mailsLoginInformation.advancedtitleimap' | translate }}</span>
  <div class="grid">
    <span>{{ 'internal.mails.mailsLoginInformation.domain' | translate }}</span>
    <click-to-copy text="technikflg.com"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.encryption' | translate }}</span>
    <click-to-copy text="SSL"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.port' | translate }}</span>
    <click-to-copy text="993"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.username' | translate }}</span>
    <click-to-copy [text]="userMailInfo.email"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.password' | translate }}</span>
    <click-to-copy [text]="userMailInfo.password"></click-to-copy>

  </div>
</div>

<div *ngIf="!loading" class="mailsLoginInformation-group">
  <span class="title">{{ 'internal.mails.mailsLoginInformation.advancedtitlesmtp' | translate }}</span>
  <div class="grid">
    <span>{{ 'internal.mails.mailsLoginInformation.domain' | translate }}</span>
    <click-to-copy text="technikflg.com"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.encryption' | translate }}</span>
    <click-to-copy text="SSL"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.port' | translate }}</span>
    <click-to-copy text="587"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.username' | translate }}</span>
    <click-to-copy [text]="userMailInfo.email"></click-to-copy>
    <span>{{ 'internal.mails.mailsLoginInformation.password' | translate }}</span>
    <click-to-copy [text]="userMailInfo.password"></click-to-copy>

  </div>
</div>

<div *ngIf="loading" class="loading-spinner">
  <mat-spinner diameter="100"></mat-spinner>
</div>

<div id="mailsLoginInformation-actions">
  <button mat-stroked-button [mat-dialog-close]="true">{{ 'general.close' | translate }}</button>
</div>
