<div id="barcodelist-header">
  <div id="barcodelist-header-title">{{ 'internal.inventory.barcodeEdit.title' | translate }}</div>
</div>

<div id="barcodelist-form">
  <form [formGroup]="scannerForm" (ngSubmit)="scanCode()">
    <mat-form-field>
      <input
        #scannerInput
        matInput
        placeholder="{{ 'internal.inventory.scanner' | translate }}"
        formControlName="input"
        (change)="codeScanned(scannerInput.value)" />
      <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
    </mat-form-field>
  </form>

  <div id="barcode-list">
    <div *ngFor="let code of barcodes; let i = index" class="barcode">
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="barcodes[i]">
        <button matSuffix mat-icon-button (click)="removeBarcode(i)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>

<div id="barcodelist-actions">
  <button mat-stroked-button (click)="save()">{{ 'general.save' | translate }}</button>
  <button mat-stroked-button (click)="cancel()">{{ 'general.close' | translate }}</button>
</div>
