<div *ngIf="!hideMenu" id="topbar" class="noselect">
  <a class="topbar_button" routerLink="/gallery" routerLinkActive="active">
    {{ "mainmenu.gallery" | translate }}
  </a>

  <a class="topbar_button" routerLink="/home" routerLinkActive="active">
    {{ "mainmenu.home" | translate }}
  </a>

  <div class="topbar_button" [ngClass]="{ hover: openloginform }" (click)="openloginform = !openloginform">
    {{ "mainmenu.internal" | translate }}
  </div>
</div>

<div *ngIf="openloginform" id="loginform">

  <ng-container *ngIf="!loggedin()">
    <form [formGroup]="loginform" (ngSubmit)="login()" style="display: contents;">
      <mat-form-field class="loginformfield" appearance="outline">
        <input id="input_username" matInput placeholder="{{ 'login.username' | translate }}" formControlName="username" required />
      </mat-form-field>

      <mat-form-field class="loginformfield" appearance="outline">
        <input id="input_password" matInput placeholder="{{ 'login.password' | translate }}" type="password" formControlName="password" required />
      </mat-form-field>

      <div id="login-error" class="alert alert-danger" *ngIf="forminvalid">
        {{ errormessage | translate }}
      </div>

      <button id="login-button" mat-stroked-button type="submit" [disabled]="!loginform.valid">{{ "login.login" | translate }}</button>
    </form>
  </ng-container>

  <ng-container *ngIf="loggedin()">
    <span id="welcome-text">
      {{ "login.hello" | translate: { username: username() } }}
    </span>

    <button class="profile-button" mat-stroked-button routerLink="/internal" routerLinkActive="active" (click)="openloginform = false">{{ "login.dashboard" | translate }}</button>
    <button class="profile-button" style="color: #f44336;" mat-stroked-button (click)="logout()">{{ "login.logout" | translate }}</button>
  </ng-container>

</div>

<div *ngIf="!hideMenu" id="topbar-spacer"></div>

<div id="content-wrapper" [ngClass]="{'kiosk': isKioskMode()}">
  <router-outlet></router-outlet>
</div>

<div *ngIf="!hideMenu" id="footer-spacer"></div>

<div *ngIf="!hideMenu" id="footer" class="blurry"></div>
<div *ngIf="!hideMenu" id="footer">
  <div id="legal">
    <a style="cursor: pointer" (click)="openCopyrightDialog()"> Copyright &copy; {{ currentYear() }} Erik Aab, Yannik Bocksch. </a>
  </div>
  <div id="footer-banner">Technik AG</div>
  <div id="social">
    <a id="ig-link" target="_blank" href="https://www.instagram.com/technikflg/">Instagram</a>
  </div>
</div>
