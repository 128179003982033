import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map, Observable, startWith } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { openProgressDialog, ProgressDialog, ProgressDialogStep, ProgressDialogStepState } from "../dialogs/progressDialog/progressDialog";
import { TAGEvent } from "../models/tagevent";
import { TAGTransaction } from "../models/tagfinances";
import { TAGMailFolder } from "../models/tagmail";
import { TAGUser } from "../models/taguser";

export interface MailFolderEditDialogData {
  foldersFlat: TAGMailFolder[];
  folder?: TAGMailFolder;
}

@Component({
  selector: "int-mails-foldereditdialog",
  templateUrl: "mailFolderEditDialog.html",
  styleUrls: ["./mailFolderEditDialog.scss"],
})
export class MailFolderEditDialog {

  foldersFlat: TAGMailFolder[] = [];
  folder: TAGMailFolder = new TAGMailFolder();
  isNewFolder: boolean = false;

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MailFolderEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MailFolderEditDialogData
  ) {
    this.foldersFlat = data.foldersFlat;

    if (data.folder) {
      this.folder = new TAGMailFolder(Object.assign({}, data.folder));
      this.folder.name = this.folder.path.split(this.folder.delimiter).pop();
      this.folder.path = this.folder.path.substring(0, this.folder.path.length - this.folder.name.length - 1);

      console.log(this.folder);
    } else {
      this.folder = new TAGMailFolder();
      this.isNewFolder = true;
    }

    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {

    });
  }

  cancelEdit(): void {
    console.log(this.folder);
    this.dialogRef.close(null);
  }

  saveEdit(): void {

    if (this.folder.path == "") {
      this.folder.path = this.folder.name;
    } else {
      this.folder.path = this.folder.path + this.folder.delimiter + this.folder.name;
    }

    console.log(this.folder.path);

    if (this.isNewFolder) {
      this.api.newFolder(this.folder.path).then((result) => {
        this.dialogRef.close(result);
      }).catch((reason) => {
        console.log(reason);
      });
    } else {
      this.api.editFolder(this.data.folder.path, this.folder.path).then((result) => {
        this.dialogRef.close(result);
      }).catch((reason) => {
        console.log(reason);
      });
    }
  }
}
