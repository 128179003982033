import { DBModel } from "./DBModel";
import { Permission } from "./tagpermission";

export class TAGUser extends DBModel {
  username: string = "";
  firstname: string = "";
  lastname: string = "";
  password: string = "";
  pin: string = "";

  schoolclass: string = "";

  active: boolean = true;

  registerdate: Date = new Date();

  // Email
  email: string = "";
  technikEmail: string = "";
  technikEmailPassword: string = "";

  // Permissions
  permissions: Set<Permission> = new Set<Permission>();

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  get fullname() {
    return this.firstname + " " + this.lastname;
  }

  hasPermission(permission: Permission): boolean {
    return this.permissions.has(permission);
  }

  serialize(): any {
    var obj: any = super.serialize();

    obj.username = this.username;
    obj.firstname = this.firstname;
    obj.lastname = this.lastname;
    obj.password = this.password;
    obj.pin = this.pin;

    obj.schoolclass = this.schoolclass;

    obj.active = this.active;

    obj.registerdate = this.registerdate;

    obj.email = this.email;
    obj.technikEmail = this.technikEmail;
    obj.technikEmailPassword = this.technikEmailPassword;

    obj.permissions = Array.from(this.permissions).map((p) => p.name);

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.username != null) this.username = data.username;
    if (data.firstname != null) this.firstname = data.firstname;
    if (data.lastname != null) this.lastname = data.lastname;
    if (data.password != null) this.password = data.password;
    if (data.pin != null) this.pin = data.pin;

    if (data.schoolclass != null) this.schoolclass = data.schoolclass;

    if (data.active != null) this.active = data.active;

    if (data.registerdate != null) this.registerdate = new Date(data.registerdate);

    if (data.email != null) this.email = data.email;
    if (data.technikEmail != null) this.technikEmail = data.technikEmail;
    if (data.technikEmailPassword != null) this.technikEmailPassword = data.technikEmailPassword;

    if (data.permissions != null) {
      let knownPermissions = data.permissions.filter((p: string) => Permission.fromName(p) != undefined);
      this.permissions = new Set(knownPermissions.map((p) => Permission.fromName(p)));
    }
  }
}
