import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-copyrightdialog",
  templateUrl: "./copyrightdialog.component.html",
  styleUrls: ["./copyrightdialog.component.css"],
})
export class CopyrightdialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<CopyrightdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}
}
