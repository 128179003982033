<div id="int-content-topbar">
  <internal-menu-button id="menuBtn"></internal-menu-button>
  <button *ngIf="hasPerm('create_requests')" mat-stroked-button (click)="newRequest()">{{ "internal.requests.newRequest" | translate }}</button>
</div>
<div id="int-requests-content">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'general.search' | translate }}" />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container class="column-wide" matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.requests.name" | translate }}</th>
        <td mat-cell *matCellDef="let row" (click)="editRequest(row)">{{ row.name }}</td>
      </ng-container>

      <ng-container class="column-wide" matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.requests.location" | translate }}</th>
        <td mat-cell *matCellDef="let row" (click)="editRequest(row)">{{ locationName(row.location) }}</td>
      </ng-container>

      <ng-container class="column-wide" matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.requests.date" | translate }}</th>
        <td mat-cell *matCellDef="let row" (click)="editRequest(row)">{{ row.startdate | date }}</td>
      </ng-container>

      <ng-container class="column-wide" matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.requests.contact" | translate }}</th>
        <td mat-cell *matCellDef="let row" (click)="editRequest(row)">{{ row.contact }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="requests.length" [pageSize]="pageSize" (page)="switchPage($event)" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
