<div id="transactionEdit-header">
  {{ 'internal.finances.transactionEdit.title' | translate }}
</div>

<div id="transactionEdit-form">
  <mat-slide-toggle [(ngModel)]="transaction.isIncome">{{ 'internal.finances.transaction.isIncome' | translate }}</mat-slide-toggle>

  <mat-slide-toggle [(ngModel)]="transaction.pending">{{ 'internal.finances.transaction.pending' | translate }}</mat-slide-toggle>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.finances.transaction.reason' | translate }}</mat-label>
    <input matInput placeholder="Fannyball 2023" [(ngModel)]="transaction.reason" />
    <mat-icon matSuffix>edit</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-placeholder>{{ "internal.finances.transaction.date" | translate }}</mat-placeholder>
    <input matInput [(ngModel)]="transaction.date" [matDatepicker]="datepicker" required />
    <mat-datepicker-toggle [for]="datepicker" matSuffix ></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.finances.transaction.user' | translate }}</mat-label>
    <input matInput placeholder="User" [(ngModel)]="transaction.user" [formControl]="userControl" [matAutocomplete]="userAutocomplete" />
    <mat-icon matSuffix>edit</mat-icon>

    <mat-autocomplete #userAutocomplete="matAutocomplete">
      <mat-option *ngFor="let user of filteredUsers | async" [value]="getUserName(user.username)"> {{getUserName(user.username)}} </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.finances.transaction.event' | translate }}</mat-label>
    <input matInput placeholder="Event" [(ngModel)]="transaction.event" [formControl]="eventControl" [matAutocomplete]="eventAutocomplete" />
    <mat-icon matSuffix>edit</mat-icon>

    <mat-autocomplete #eventAutocomplete="matAutocomplete">
      <mat-option *ngFor="let event of filteredEvents | async" [value]="event.name"> {{event.name}} </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.finances.transaction.notes' | translate }}</mat-label>
    <!-- <textarea matInput [(ngModel)]="transaction.notes"></textarea> -->
    <input matInput [(ngModel)]="transaction.notes" />
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.finances.transaction.amount' | translate }}</mat-label>
    <input matInput mask="0*.00" [dropSpecialCharacters]="false" type="text" [placeholder]="0" inputmode="numeric" [(ngModel)]="transaction.amount" >
    <span matPrefix>€&nbsp;</span>
  </mat-form-field>

  <div class="form-subtitle">
    {{ 'internal.finances.transaction.attachments' | translate }}
  </div>

  <document-grid
    [urlTemplate]="apiUrl() + 'finances/transactions/' + transaction._id + '/file/<filename>'"
    [documents]="allFiles()"
    [showUpload]="true"
    [showDownload]="true"
    [showView]="true"
    [showDelete]="true"
    (uploadClicked)="uploadFile($event)"
    (deleteClicked)="deleteFile($event)">
  </document-grid>
</div>

<div id="transactionEdit-actions">
  <button mat-stroked-button color="warn" (click)="cancelEdit()">{{ 'general.cancel' | translate }}</button>
  <button mat-stroked-button (click)="saveEdit()">{{ 'general.save' | translate }}</button>
</div>
