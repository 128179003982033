import { Component, OnInit } from "@angular/core";

@Component({
  selector: "placeholder",
  templateUrl: "./placeholder.component.html",
  styleUrls: ["./placeholder.component.scss"],
})
export class PlaceholderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
