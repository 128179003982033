import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { ApiService } from 'src/app/api.service';
import { ProgressDialogStep, ProgressDialogStepState, openProgressDialog } from 'src/app/dialogs/progressDialog/progressDialog';
import { TransactionEditDialog } from 'src/app/internal.inventory/transactionEditDialog';
import { TAGEvent } from 'src/app/models/tagevent';
import { TAGInvItemType, TAGInvTransaction } from 'src/app/models/taginventory';
import { TAGUser } from 'src/app/models/taguser';

@Component({
  selector: 'inv-transaction-list-item',
  templateUrl: './inv-transaction-list-item.component.html',
  styleUrls: ['./inv-transaction-list-item.component.scss']
})
export class InvTransactionListItemComponent implements OnInit {

  @Input() transaction: TAGInvTransaction;

  @Input() showPrintButton: boolean = true;
  @Input() showViewButton: boolean = true;
  @Input() showSelectButton: boolean = false;

  @Output() selected: EventEmitter<TAGInvTransaction> = new EventEmitter<TAGInvTransaction>();
  @Output() changed: EventEmitter<void> = new EventEmitter<void>();

  constructor(private api: ApiService, public dialog: MatDialog) { }

  ngOnInit(): void {
  }



  getItemInfo(itemID: string) {
    return new Promise<TAGInvItemType>((resolve, reject) => {
      this.api.getInvItem(itemID).then((item) => {
        resolve(item);
      }).catch((reason) => {
        console.log(reason);
        reject(reason);
      });
    });
  }

  showTransaction(transaction: TAGInvTransaction) {
    const dialogRef = this.dialog.open(TransactionEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { transaction: transaction },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.changed.emit();
    });
  }

  selectTransaction(transaction: TAGInvTransaction) {
    this.selected.emit(transaction);
  }

  printTransactionChecklist(transaction: TAGInvTransaction) {
    return new Promise<string>(async (resolve, reject) => {
      let loadingStep: ProgressDialogStep = {
        state: ProgressDialogStepState.WAITING,
        title: "Loading Inventory Information",
        showProgress: true,
        progress: 0,
      }

      let generatingStep: ProgressDialogStep = {
        state: ProgressDialogStepState.WAITING,
        title: "Generating PDF",
      }

      const progressDialog = openProgressDialog(this.dialog, [loadingStep, generatingStep]);
      progressDialog.afterOpened().subscribe(async () => {
        loadingStep.state = ProgressDialogStepState.PROCESSING;

        let itemAmount = new Set(transaction.items.map((item) => item.itemid)).size;

        let itemInfo: { [key: string]: TAGInvItemType } = {};
        try {
          for (let item of transaction.items) {
            if (itemInfo[item.itemid] == null) {
              itemInfo[item.itemid] = await this.getItemInfo(item.itemid);
              loadingStep.progress = Object.keys(itemInfo).length / itemAmount * 100;
            }
          }
        } catch (reason) {
          console.log(reason);
          loadingStep.state = ProgressDialogStepState.ERROR;
          loadingStep.subtitle = reason;
          progressDialog.componentInstance.finishDialog(-1);
          return;
        }

        let bookingUser = await this.api.getUser(transaction.user);
        let bookingEvent = await this.api.getEvent(transaction.event);

        console.log(bookingEvent);

        loadingStep.state = ProgressDialogStepState.SUCCESS;
        generatingStep.state = ProgressDialogStepState.PROCESSING;

        let pdf = pdfMake.createPdf(this.generatePDFContent(transaction, itemInfo, bookingUser, bookingEvent), null, {
          "Arial": {
            normal: location.origin + "/assets/fonts/arial.ttf",
            bold: location.origin + "/assets/fonts/arialbd.ttf",
            bolditalics: location.origin + "/assets/fonts/arialbi.ttf",
            italics: location.origin + "/assets/fonts/ariali.ttf"
          },
          "Roboto": {
            normal: "Roboto-Regular.ttf",
            bold: "Roboto-Medium.ttf",
          },
          "Icon": {
            normal: location.origin + "/assets/fonts/material-symbols-outlined.ttf",
          }
        });

        generatingStep.state = ProgressDialogStepState.SUCCESS;
        progressDialog.componentInstance.finishDialog(1000);

        pdf.print();
      });
    });
  }


  generatePDFContent(transaction: TAGInvTransaction, itemInfo: { [key: string]: TAGInvItemType }, bookingUser: TAGUser, bookingEvent: TAGEvent) {
    console.log(transaction.items);

    let logoURL = location.origin + "/assets/images/logo.png";

    let itemTableArr: any[] = transaction.items.map((item, i) => {
      let description: any[] = [{ text: itemInfo[item.itemid].name, bold: true }];
      if (itemInfo[item.itemid].additionalInfo != "") {
        description.push({ text: itemInfo[item.itemid].additionalInfo });
      }

      let checkboxText = item.back != null? "check_box" : "check_box_outline_blank";
      let style = ["tableItemsRight", "icon"];
      if (item.back != null) style.push("tableItemsGrey");

      return [
        { text: i + 1, style: "tableItemsGrey" },
        description,
        { text: item.instanceid, style: "tableItemsGrey" },
        { text: checkboxText, style: style },
        { text: checkboxText, style: style },
        { text: checkboxText, style: style },
        { text: checkboxText, style: style },
        { text: checkboxText, style: style },
        { text: checkboxText, style: style },
        { text: checkboxText, style: style },
        { text: checkboxText, style: style },
        { text: checkboxText, style: style },

      ]
    });

    let checkboxText = transaction.allItemsBack()? "check_box" : "check_box_outline_blank";
    let style = ["tableItemsRight", "icon"];
    if (transaction.allItemsBack()) style.push("tableItemsGrey");

    itemTableArr.push([
      { text: "", style: "tableItemsGrey" },
      { text: "", style: "tableItemsGrey" },
      { text: "", style: "tableItemsGrey" },
      { text: checkboxText, style: style },
      { text: checkboxText, style: style },
      { text: checkboxText, style: style },
      { text: checkboxText, style: style },
      { text: checkboxText, style: style },
      { text: checkboxText, style: style },
      { text: checkboxText, style: style },
      { text: checkboxText, style: style },
      { text: checkboxText, style: style },
    ]);

    console.log(itemTableArr);

    let docDefinition: TDocumentDefinitions = {
      info: {
        title: "Inventarbuchungs-Übersicht " + transaction.location,
      },
      pageMargins: [70, 57, 43, 57 * 2 + 20],
      content: [
        {
          layout: "noBorders",
          table: {
            widths: ["*", 100],
            body: [[
              { image: "logo", width: 132 },
              //qrObject
            ]]
          }
        },

        { text: ["Inventarbuchungs Übersicht bezüglich ", {text: transaction.location, decoration: "underline"}], style: "header", marginTop: 20/*, marginTop: -50*/ },

        {
          marginTop: 8,
          table: {
            widths: [141, '*'],
            body: [
              ["Buchungsdatum", (transaction.startdate as Date).toLocaleDateString(["de"], {day: "2-digit", month: "2-digit", year: "numeric"})],
              ["Buchungs-Event", bookingEvent && bookingEvent.name != ""? bookingEvent.name : "N/A"],
              ["Sachbearbeiter", bookingUser.firstname + " " + bookingUser.lastname],
            ]
          },
          layout: 'noBorders'
        },

        {
          marginTop: 38,
          style: "tableItems",
          table: {
            widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            heights: 16,
            headerRows: 1,
            body: [
              [
                { text: "Pos.", style: ["tableItemsGrey"] },
                { text: "Beschreibung" },
                { text: "Instance", style: ["tableItemsGrey"] },
                { text: "", style: ["tableItemsRight"] },
                { text: "", style: ["tableItemsRight"] },
                { text: "", style: ["tableItemsRight"] },
                { text: "", style: ["tableItemsRight"] },
                { text: "", style: ["tableItemsRight"] },
                { text: "", style: ["tableItemsRight"] },
                { text: "", style: ["tableItemsRight"] },
                { text: "", style: ["tableItemsRight"] },
                { text: "Zurück", style: ["tableItemsRight"] },
              ],
              ...itemTableArr
            ]
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 0;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 0;
            },
            hLineColor: function (i, node) {
              return '#7f7f7f';
            },
            vLineColor: function (i, node) {
              return '#7f7f7f';
            },
            fillColor: function (i, node) {
              return i === 0 || i === (node.table.body.length - 1) ? "#8c8c8c" : "";
            },
            paddingTop: (i, node) => {
              if (node.text && (node.text as string).includes("\n")){
                return 0;
              } else {
                return 4;
              }
            }
          }
        },
        {
          columns: [
            { text: [{text: "Datum                         ", decoration: "overline"}] },
            { text: [{text: "Unterschrift                  ", decoration: "overline"}] },
          ],
          columnGap: 20,
          marginTop: 30,
          marginLeft: 200,
        }
      ],

      footer: {
        margin: [70, 0, 43, 57],
        stack: [
          {
            columns: [
              {
                style: { alignment: "left" },
                stack: [
                  {text: "Bankverbindung", bold: true},
                  {
                    table: {
                      widths: [50, '*'],
                      body: [
                        ["Name", "Andreas Nickel"],
                        ["BIC", "SOLADEST600"],
                        ["IBAN", "DE34 6005 0101 7006 8411 01"],
                      ]
                    },
                    layout: 'noBorders'
                  },
                ],
              },
              {
                style: { alignment: "right" },
                stack: [
                  {text: "Kontakt", bold: true},
                  {
                    table: {
                      widths: ['*'],
                      body: [
                        ["TechnikFLG"],
                        ["Fanny-Leicht-Straße 13"],
                        ["70563 Stuttgart"],
                      ]
                    },
                    layout: 'noBorders'
                  },
                ]
              },
            ]
          },
          {
            bold: true,
            style: { alignment: "center" },
            marginTop: 10,
            columns: [
              { text: "info@technikflg.com", alignment: "right", marginRight: 10 },
              { text: "www.technikflg.com", alignment: "left", marginLeft: 10 },
            ]
          }
        ]
      },

      images: {
        logo: logoURL,
        //barcode: barcode,
      },

      defaultStyle: {
        font: "Roboto",
        fontSize: 10
      },

      styles: {
        addressSender: {
          font: "Arial",
          fontSize: 9,
          //color: "#8c8c8c",
          color: "#595959",
          bold: true,
        },
        addressText: {
          font: "Arial",
          fontSize: 11,
          lineHeight: 1.1,
        },
        header: {
          fontSize: 12,
          bold: true,
        },
        tableItems: {

        },
        icon: {
          font: "Icon",
          fontSize: 18,
        },
        tableItemsGrey: {
          color: "#3d3d3d",
        },
        tableItemsRight: {
          alignment: "right"
        }
      },
    };

    return docDefinition;
  }

}
