import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export enum ProgressDialogStepState {
  WAITING = "waiting",
  PROCESSING = "processing",
  SUCCESS = "success",
  ERROR = "error",
  SKIPPED = "skipped",
}

export interface ProgressDialogStep {
  state: ProgressDialogStepState;
  title: string;
  subtitle?: string;
  icon?: string;
  iconColor?: string;

  showProgress?: boolean;
  progress?: number;
}

export interface ProgressDialogData {
  steps: ProgressDialogStep[];
}

@Component({
  selector: "progressDialog",
  templateUrl: "progressDialog.html",
  styleUrls: ["./progressDialog.scss"],
})
export class ProgressDialog {
  constructor(public dialogRef: MatDialogRef<ProgressDialog>, @Inject(MAT_DIALOG_DATA) public data: ProgressDialogData) {
    data.steps = data.steps || [];

    dialogRef.disableClose = true;
  }

  finishDialog(timeout: number = 1000) {
    this.dialogRef.disableClose = false;

    if (timeout != -1) {
      setTimeout(() => {
        this.dialogRef.close();
      }, timeout);
    }
  }
}

export function openProgressDialog(dialog: MatDialog, steps: ProgressDialogStep[]) {
  return dialog.open(ProgressDialog, {
    minWidth: "200px",
    width: "400px",
    data: { steps: steps },
  });
}
