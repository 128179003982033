import { Component, OnInit } from '@angular/core';
import { InternalMenuService } from './internal-menu.service';

@Component({
    selector: 'internal-menu-button',
    template: `
        <button style="color: white;" mat-icon-button color="white" aria-label="Menu" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>
    `,
    styles: [`
            button {
                transform: translateY(-2px);
                color: $textcolor-light;
            }

            @media screen and (min-width: 1201px) {
                button {
                    display: none;
                }
            }
    `]
})
export class InternalMenuButtonComponent implements OnInit {


    constructor(private menuOpenSvc: InternalMenuService) { }

    ngOnInit(): void {

    }

    toggleMenu() {
        this.menuOpenSvc.toggleMenu();
    }
}
