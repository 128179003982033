import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatTime",
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number, showMinutes: boolean = false): string {
    const days = Math.floor(value / 86400);
    const hours = Math.floor((value % 86400) / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.floor(value % 60);

    var result = "";
    if (days > 0) result += ("00" + days).slice(-2) + ":";
    if (hours > 0) result += ("00" + hours).slice(-2) + ":";
    if (minutes > 0 || showMinutes) result += ("00" + minutes).slice(-2) + ":";
    result += ("00" + seconds).slice(-2);
    return result;
  }
}
