import { NestedTreeControl } from "@angular/cdk/tree";
import { Component, OnInit, SecurityContext, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../api.service";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { TAGMail, TAGMailFolder } from "../models/tagmail";
import { MailFolderEditDialog } from "./mailFolderEditDialog";
import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { MailLoginInformationDialog } from "./mailLoginInformationDialog";

/**
 * TODO:
 * - Drafts?
 */

@Component({
  selector: "app-internal.email",
  templateUrl: "./internal.email.component.html",
  styleUrls: ["./internal.email.component.scss"],
})
export class InternalEmailComponent implements OnInit {
  folders: TAGMailFolder[] = [];
  foldersFlat: TAGMailFolder[] = [];
  selectedFolder: string = "INBOX";

  treeControl = new NestedTreeControl<TAGMailFolder>(folder => folder.children);
  folderDataSource = new MatTreeNestedDataSource<TAGMailFolder>();
  hasChild = (_: number, node: TAGMailFolder) => !!node.children && node.children.length > 0;

  mails: TAGMail[] = [];
  selectedMail: number = -1;

  displayedColumns: string[] = ["seen", "important", "subject", "from", "date"];
  dataSource: MatTableDataSource<TAGMail>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  updateTimer: any = null;
  loading: boolean = false;
  loadingMails: boolean = true;

  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private readonly dompurifySanitizer: NgDompurifySanitizer) {}

  isFlagged(mail: TAGMail, flag: string) {
    return mail.flags.includes(flag);
  }

  to(mail: TAGMail) {
    return mail.to.addresses.map((a) => (a.name == "" ? a.address : a.name + " <" + a.address + ">")).join(", ");
  }

  cc(mail: TAGMail) {
    return mail.cc.addresses.map((a) => (a.name == "" ? a.address : a.name + " <" + a.address + ">")).join(", ");
  }

  replaceMailAddresses(text: string) {
    return this.dompurifySanitizer.sanitize(SecurityContext.HTML, text);
  }

  processMailClick(event) {
    if (event.target.tagName == "A") {
      event.preventDefault();
      event.stopPropagation();

      if (event.target.href.startsWith("mailto:")) {
        let match = event.target.href.match(/mailto:([^?]+)(?:\?([^?]+))?/);
        if (match) {
          console.log(match);
          let to = match[1].replace(/mailto:/g, "").split(",");
          let cc = [];
          let subject = "";
          if (match[2]) {
            let params = match[2].split("&");
            for (let param of params) {
              let parts = param.split("=");
              if (parts[0] == "cc") {
                cc = parts[1].split(",");
              } else if (parts[0] == "subject") {
                subject = decodeURIComponent(parts[1]);
              }
            }
          }

          console.log(to, cc, subject);

          this.router.navigate(["./new"], { relativeTo: this.route, state: { to: to, cc: cc, subject: subject } });
        }
      } else {
        window.open(event.target.href, "_blank");
      }
    }
  }

  cutText(text: string, length: number) {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  }

  getFolderIcon(folder: TAGMailFolder) {
    switch (folder.specialUseAttribs) {
      case "\\Sent":
        return "send";
      case "\\Drafts":
        return "drafts";
      case "\\Trash":
        return "delete";
      case "\\Junk":
        return "report";
      default:
        if (folder.name == "INBOX") {
          return "inbox";
        }
        return "folder";
    }
  }

  getFolderFromPath(path: string) {
    let folder = this.foldersFlat.find((f) => f.path == path);
    return folder;
  }

  canEditFolder(folder: TAGMailFolder) {
    if (!folder) return false;

    if (folder.name == "INBOX") {
      return false;
    }

    return folder.specialUseAttribs.length == 0;
  }

  makeFlatFolders(folders: TAGMailFolder[]) {
    for (let folder of folders) {
      this.foldersFlat.push(folder);
      if (folder.children) {
        this.makeFlatFolders(folder.children);
      }
    }
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.sort.sort({ id: "date", start: "desc" } as any);

      this.refreshFolders();
    });
  }

  ngOnDestroy() {
    if (this.updateTimer) {
      clearInterval(this.updateTimer);
      this.updateTimer = null;
    }
  }

  sendNewMail() {
    this.router.navigate(["./new"], { relativeTo: this.route, state: null });
  }

  resendOldMail(mail: TAGMail, action: string) {
    this.router.navigate(["./new"], { relativeTo: this.route, state: { mail: mail, action: action } });
  }

  refreshFolders() {
    this.api.getFolders().then((result) => {

      this.folders = [];

      this.folders.push(...result.filter(f => f.name == "INBOX"));
      this.folders.push(...result.filter(f => f.specialUseAttribs == "\\Sent"));
      this.folders.push(...result.filter(f => f.specialUseAttribs == "\\Drafts"));
      this.folders.push(...result.filter(f => f.specialUseAttribs == "\\Trash"));
      this.folders.push(...result.filter(f => f.specialUseAttribs == "\\Junk"));
      this.folders.push(...result.filter(f => f.name != "INBOX" && f.specialUseAttribs == ""));

      this.folderDataSource.data = this.folders;

      this.foldersFlat = [];
      this.makeFlatFolders(this.folders);

      this.refreshMails();
      this.dataSource = new MatTableDataSource(this.mails);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      if (this.updateTimer) {
        clearInterval(this.updateTimer);
        this.updateTimer = null;
      }

      this.updateTimer = setInterval(() => {
        this.refreshMails();
      }, 10000);
    }).catch((reason) => {
      console.error(reason);
    });
  }

  refreshMails() {
    this.api.getAllMails(this.selectedFolder).then((result) => {
      this.mails = result;
      this.dataSource.data = this.mails;
      this.loadingMails = false;
      this.loading = false;
    }).catch((reason) => {
      console.error(reason);
    });
  }

  selectFolder(folder: TAGMailFolder) {
    this.selectedFolder = folder.path;
    this.refreshMails();
  }

  newFolder() {
    const dialogRef = this.dialog.open(MailFolderEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { foldersFlat: this.foldersFlat },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;
      this.loading = true;
      this.refreshFolders();
    });
  }

  editFolder() {
    const dialogRef = this.dialog.open(MailFolderEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { foldersFlat: this.foldersFlat, folder: this.getFolderFromPath(this.selectedFolder) },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;
      this.loading = true;
      this.refreshFolders();
    });
  }

  deleteFolder() {
    let deleteRef = openDeleteDialog(this.dialog, "Mail Folder", [this.selectedFolder])
    deleteRef.afterClosed().subscribe((result) => {
      this.loading = true;
      if (result) {
        this.api.deleteFolder(this.selectedFolder).then((result) => {
          this.selectedFolder = "INBOX";
          this.refreshFolders();
        }).catch((reason) => {
          console.error(reason);
        });
      }
    });
  }

  showMailLogin() {
    const dialogRef = this.dialog.open(MailLoginInformationDialog, {
      minWidth: "400px",
      //width: "50%",
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectMail(mail: TAGMail) {
    this.selectedMail = this.mails.findIndex((m) => m.uid == mail.uid);

    if (!this.isFlagged(mail, "\\Seen")) {
      this.markReadTimer(mail);
    }
  }

  markReadTimer(mail: TAGMail) {
    var selMail = this.selectedMail;
    setTimeout(() => {
      if (this.selectedMail == selMail) {
        this.markMailRead(mail);
      }
    }, 1000);
  }

  markMailRead(mail: TAGMail) {
    if (this.isFlagged(mail, "\\Seen")) {
      mail.flags.splice(mail.flags.indexOf("\\Seen"), 1);
    } else {
      mail.flags.push("\\Seen");
    }

    this.api.markMailRead(
      mail.uid,
      this.isFlagged(mail, "\\Seen"),
      this.selectedFolder).then((result) => {
        this.refreshMails();
      }).catch((reason) => {
        console.error(reason);
      });
  }

  markMailFlagged(mail: TAGMail) {
    if (this.isFlagged(mail, "\\Flagged")) {
      mail.flags.splice(mail.flags.indexOf("\\Flagged"), 1);
    } else {
      mail.flags.push("\\Flagged");
    }

    this.api.markMailFlagged(
      mail.uid,
      this.isFlagged(mail, "\\Flagged"),
      this.selectedFolder).then((result) => {
        this.refreshMails();
      }).catch((reason) => {
        console.error(reason);
      });
  }

  moveMail(mail: TAGMail, folder: TAGMailFolder) {
    this.loading = true;

    this.api.moveMail(
      mail.uid,
      this.selectedFolder,
      folder.path).then((result) => {
        this.selectedMail = -1;
        this.refreshMails();
      }).catch((reason) => {
        console.error(reason);
      });
  }

  deleteMail(mail: TAGMail) {
    let deleteRef = openDeleteDialog(this.dialog, "E-Mail", [mail.subject])
    deleteRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;

        this.api.moveMail(
          mail.uid,
          this.selectedFolder,
          "Trash").then((result) => {
            this.selectedMail = -1;
            this.refreshMails();
          }).catch((reason) => {
            console.error(reason);
          });
      }
    });
  }

  undeleteMail(mail: TAGMail) {
    this.loading = true;

    this.api.moveMail(
      mail.uid,
      this.selectedFolder,
      "INBOX").then((result) => {
        this.selectedMail = -1;
        this.refreshMails();
      }).catch((reason) => {
        console.error(reason);
      });
  }

  deleteMailForever(mail: TAGMail) {
    let deleteRef = openDeleteDialog(this.dialog, "E-Mail", [mail.subject])
    deleteRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;

        this.mails.splice(
          this.mails.findIndex((m) => m.uid == mail.uid),
          1
        );

        this.api.deleteMailForever(
          mail.uid,
          this.selectedFolder).then((result) => {
            this.selectedMail = -1;
            this.refreshMails();
          }).catch((reason) => {
            console.error(reason);
          });
      }
    });
  }

  downloadAttachment(mail: TAGMail, filename: string, download: boolean = false) {
    this.api.getMailAttachments(
      mail.uid,
      filename,
      this.selectedFolder).then((result) => {
        var pdfUrl = window.URL.createObjectURL(result);

        var link = document.createElement("a");
        link.download = filename;
        link.href = pdfUrl;

        if (download) {
          link.click();
        } else {
          window.open(pdfUrl, "_blank");
        }
      }).catch((reason) => {
        console.error(reason);
      });
  }
}
