<div id="playerState">
  <img [src]="currentCoverUrl" imgFallback="assets/images/bg_headphones.png" />

  <div id="current-info">
    <div id="song-control">
      <button *ngIf="showControls" mat-icon-button (click)="playPause()">
        <mat-icon>{{ playerState.playing ? "pause" : "play_arrow" }}</mat-icon>
      </button>

      <button *ngIf="showControls" mat-icon-button (click)="skipSong()">
        <mat-icon>skip_next</mat-icon>
      </button>

      <div id="songInfo">
        <div id="current-title">
          {{ (playerState.song.title != "" ? playerState.song.title : "radio.noSongPlaying") | translate }}
        </div>

        <div id="current-artist">
          {{ playerState.song.artist }}
        </div>
      </div>

      <a *ngIf="showYoutubeLink && playerState.nextSong.url != ''" style="height: 38px" mat-stroked-button [href]="playerState.nextSong.url" target="_blank">Youtube</a>
    </div>

    <div id="progress-bar">
      <div id="progress-bar-outer">
        <div id="progress-bar-fill" [style.width]="(playerState.currentTime * 100) / playerState.duration + '%'"></div>
      </div>

      <div id="progress-bar-times">
        <div id="pbt-current">{{ playerState.currentTime | formatTime: true }}</div>
        <div id="pbt-total">{{ playerState.duration | formatTime: true }}</div>
      </div>
    </div>
  </div>
</div>
