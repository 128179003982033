import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { PlayerState } from "src/app/internal.schulradio/internal.schulradio.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "player-widget",
  templateUrl: "./player-widget.component.html",
  styleUrls: ["./player-widget.component.scss"],
})
export class PlayerWidgetComponent implements OnInit {
  @Input() playerState: PlayerState = null;
  @Input() showYoutubeLink: boolean = false;
  @Input() showControls: boolean = false;

  @Output() onPlayPause: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSkipSong: EventEmitter<void> = new EventEmitter<void>();

  currentCoverUrl: SafeUrl = "";
  imageRefreshTimestamp: string = "doesntmatter";

  timeInterpolationTimer: any = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.startTimeInterpolator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.playerState.firstChange) {
      this.imageRefreshTimestamp = new Date().getTime().toString();
      this.currentCoverUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl + "schulradio/currentCover?ts=" + this.imageRefreshTimestamp);

      console.log(changes.playerState.currentValue.song);
    }
  }

  ngOnDestroy() {
    this.stopTimeInterpolator();
  }

  playPause() {
    this.onPlayPause.emit();
  }

  skipSong() {
    this.onSkipSong.emit();
  }

  startTimeInterpolator() {
    if (this.timeInterpolationTimer) {
      this.stopTimeInterpolator();
    }

    this.timeInterpolationTimer = setInterval(() => {
      if (this.playerState && this.playerState.playing) {
        this.playerState.currentTime = Math.min(this.playerState.currentTime + 1, this.playerState.duration);
      }
    }, 1000);
  }

  stopTimeInterpolator() {
    clearInterval(this.timeInterpolationTimer);
  }
}
