import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { MatDialog } from "@angular/material/dialog";
import { ImagedialogComponent } from "../imagedialog/imagedialog.component";
import { TAGImageCategory } from "../models/tagimagecategory";
import { TAGImage } from "../models/tagimage";

@Component({
  selector: "app-galerie",
  templateUrl: "./galerie.component.html",
  styleUrls: ["./galerie.component.scss"],
})
export class GalerieComponent implements OnInit {
  selectedCategory: TAGImageCategory;
  images: TAGImage[] = [];
  categories: TAGImageCategory[] = [];

  changing: boolean = false;

  getImageLink(imageid: string) {
    return this.api.getImageLink(imageid);
  }

  getThumbLink(imageid: string) {
    return this.api.getThumbLink(imageid);
  }

  getFormattedHTML(text: string) {
    return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }

  constructor(private api: ApiService, public dialog: MatDialog) {}

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.api.getCategories().then((result) => {
        this.categories = result.filter((cat) => cat.isPublic);
        this.categories.forEach((category) => {
          console.log(this.api.config.defaultGallery);
          if (category.alias == this.api.config.defaultGallery) {
            this.changeCategory(category);
          }
        });
      }).catch((reason) => {

      });
    });
  }

  changeCategory(cat: TAGImageCategory) {
    if (this.changing) return;
    this.changing = true;
    this.selectedCategory = cat;
    this.images = [];

    this.api.getImages(cat._id).then((result) => {
      this.images = result.filter((img) => img.isPublic);
      this.changing = false;
    }).catch((reason) => {
      this.changing = false;
    });
  }

  //TODO: Get Image correctly
  openDialog(img: TAGImage): void {
    this.dialog.open(ImagedialogComponent, {
      width: "90%",
      data: { image: img, link: this.getImageLink(img._id) },
      panelClass: "image-dialog-container",
    });
  }
}
