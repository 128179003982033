import { Serializable } from "./ISerializeable";

export class TAGConfig implements Serializable {
  enableMusicWishes: boolean = false;
  defaultGallery: string = "";
  enableSchulradio: boolean = false;
  allowGroupsForBookIn: boolean = false;

  labelTemplate: string = "";
  groupLabelTemplate: string = "";

  constructor(info: any = {}) {
    this.deserialize(info);
  }

  serialize(): any {
    var obj: any = {};

    obj.enableMusicWishes = this.enableMusicWishes;
    obj.defaultGallery = this.defaultGallery;
    obj.enableSchulradio = this.enableSchulradio;
    obj.allowGroupsForBookIn = this.allowGroupsForBookIn;

    obj.labelTemplate = this.labelTemplate;
    obj.groupLabelTemplate = this.groupLabelTemplate;

    return obj;
  }

  deserialize(data: any) {
    if (data.enableMusicWishes != null) this.enableMusicWishes = data.enableMusicWishes;
    if (data.defaultGallery != null) this.defaultGallery = data.defaultGallery;
    if (data.enableSchulradio != null) this.enableSchulradio = data.enableSchulradio;
    if (data.allowGroupsForBookIn != null) this.allowGroupsForBookIn = data.allowGroupsForBookIn;

    if (data.labelTemplate != null) this.labelTemplate = data.labelTemplate;
    if (data.groupLabelTemplate != null) this.groupLabelTemplate = data.groupLabelTemplate;
  }
}
