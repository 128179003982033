import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Permission } from '../models/tagpermission';
import { TAGLocation } from '../models/taglocation';

@Component({
  selector: 'app-internallocations',
  templateUrl: './internal.locations.edit.component.html',
  styleUrls: ['./internal.locations.edit.component.scss']
})
export class InternalLocationsEditComponent implements OnInit {
  location: TAGLocation;

  imageFile: File;
  imageData: string;
  imageReupload: boolean = false;

  constructor(private api: ApiService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute) {

  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  getImageLink() {
    if (this.location._id != undefined && !this.imageReupload) {
      return this.api.getLocationImageLink(this.location._id);
    } else if (this.imageFile != null) {
      return this.imageData;
    } else {
      return "";
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.api.waitForAPI().then(() => {
        this.loadLocation(params.locationid);
      });
    });
  }


  loadLocation(locationid: string) {
    if (locationid == "new") {
      this.location = new TAGLocation();
    } else
    this.api.getLocation(locationid).then((result) => {
      this.location = result;
    }).catch((reason) => {
      //TODO: Show Error
    });
  }

  uploadFile($event) {
    console.log($event);
    this.imageFile = $event[0];

    const reader = new FileReader();
    reader.onload = () => {
      this.imageData = reader.result as string;
    }
    reader.readAsDataURL(this.imageFile)

    if (this.location._id != undefined) {
      this.imageReupload = true;
    }
  }


  saveLocation() {
    console.log(this.location);

    if (this.location._id != undefined) {
      this.api.updateLocation(this.location, (this.imageReupload? this.imageData : null)).then((result) => {
        this.goBack();
      }).catch((reason) => {
      //TODO: Show Error
      });
    } else {
      //TODO: Form and verification
      if (this.location.name == "") {
        return;
      }

      this.api.newLocation(this.location, this.imageData).then((result) => {
        this.goBack();
      }).catch((reason) => {
        //TODO: Show Error
      });
    }
  }

  cancelLocation() {
    this.goBack();
  }

  goBack() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

  deleteLocation() {
    if (this.location._id == undefined) return;
    const dialogRef = this.dialog.open(IntLocationsDeleteDialog, {
      width: '400px',
      data: {locationname: this.location.name}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.api.deleteLocation(this.location._id).then((result) => {
        this.goBack();
      }).catch((reason) => {
      //TODO: Show Error
      });
    });
  }
}

export interface DeleteDialogData {
  locationname: string;
}

@Component({
  selector: 'int-location-deletedialog',
  templateUrl: 'int-location-deletedialog.html',
})
export class IntLocationsDeleteDialog {

  constructor(
    public dialogRef: MatDialogRef<IntLocationsDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogData) {

  }

  onCanel(): void {
    this.dialogRef.close();
  }
}
