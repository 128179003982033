import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { TAGRequest } from "../models/tagrequest";
import { ApiService } from "../api.service";
import { Permission } from "../models/tagpermission";
import { TAGLocation } from "../models/taglocation";
import { RequestEditDialog } from "./requestEditDialog";

@Component({
  selector: "app-internalRequests",
  templateUrl: "./internal.requests.component.html",
  styleUrls: ["./internal.requests.component.scss"],
})
export class InternalRequestsComponent implements OnInit {
  pageSize: number = 25;
  currentPage: number = 0;
  displayedColumns: string[] = ["name", "location", "date", "contact"];
  dataSource: MatTableDataSource<TAGRequest>;

  locations: TAGLocation[] = [];
  requests: TAGRequest[] = [];

  constructor(private api: ApiService, public dialog: MatDialog) {}

  eventLocation(locationid: string) { return this.locations.find(l => l._id == locationid); }
  locationName(locationid: string) {
    let location = this.eventLocation(locationid);
    if (location != null) return location.name;
    return locationid;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  switchPage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;

    var startIndex = this.currentPage * this.pageSize;
    this.dataSource = new MatTableDataSource(this.requests.slice(startIndex, startIndex + this.pageSize));
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.reloadRequests();
    });
  }

  newRequest() {
    if (!this.hasPerm("create_requests")) return;

    const dialogRef = this.dialog.open(RequestEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { request: new TAGRequest(), locations: this.locations },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadRequests();
    });
  }

  editRequest(request: TAGRequest) {
    if (!this.hasPerm("edit_requests")) return;

    const dialogRef = this.dialog.open(RequestEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { request: request, locations: this.locations },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadRequests();
    });
  }

  reloadRequests() {
    this.api.getLocations().then((result) => {
      this.locations = result;

      this.api.getRequests().then((result) => {
        this.requests = result;
        this.switchPage({ pageIndex: 0, pageSize: this.pageSize });
      }).catch((reason) => {
        //TODO: Show Error
      });
    }).catch((reason) => {
      console.error(reason);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
