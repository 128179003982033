import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { AuthGuardService } from './auth-guard.service';
import { EventsComponent } from './events/events.component';
import { GalerieComponent } from './galerie/galerie.component';
import { HomeComponent } from './home/home.component';
import { InternalDashboardComponent } from './internal.dashboard/internal.dashboard.component';
import { InternalEventsComponent } from './internal.events/internal.events.component';
import { InternalGalleryComponent } from './internal.gallery/internal.gallery.component';
import { InternalMusicwishesComponent } from './internal.musicwishes/internal.musicwishes.component';
import { InternalPresenceComponent } from './internal.presence/internal.presence.component';
import { InternalPresenceEditComponent } from './internal.presence/internal.presence.edit.component';
import { InternalRequestsComponent } from './internal.requests/internal.requests.component';
import { InternalSchulradioComponent } from './internal.schulradio/internal.schulradio.component';
import { InternalSettingsComponent } from './internal.settings/internal.settings.component';
import { InternalTodosComponent } from './internal.todos/internal.todos.component';
import { InternalUsersComponent } from './internal.users/internal.users.component';
import { InternalUsersEditComponent } from './internal.users/internal.users.edit.component';
import { InternalComponent } from './internal/internal.component';
import { MusicWishFormComponent } from './musicwishform/musicwishform.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SchulradioComponent } from './schulradio/schulradio.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { InternalLocationsComponent } from './internal.locations/internal.locations.component';
import { InternalLocationsEditComponent } from './internal.locations/internal.locations.edit.component';
import { InternalEmailComponent } from './internal.email/internal.email.component';
import { InternalEmailEditComponent } from './internal.email/internal.email.edit.component';
import { OAuthLoginComponent } from './oauth/login/login.component';
import { InternalInventoryComponent } from './internal.inventory/internal.inventory.component';
import { InternalFinancesComponent } from './internal.finances/internal.finances.component';
import { RequestComponent } from './request/request.component';
import { InvoiceEditorComponent } from './invoice-editor/invoice-editor.component';
import { DocumentViewerComponent } from './documentviewer/documentviewer.component';
import { InternalWorkstationComponent } from './internal.workstation/internal.workstation.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'gallery', component: GalerieComponent },
    { path: 'aboutus', component: AboutusComponent },
    { path: 'music', component: MusicWishFormComponent, data: {hideMenu: true} },
    { path: 'request', component: RequestComponent },
    { path: 'events', component: EventsComponent },
    { path: 'document/:docid', component: DocumentViewerComponent },
    { path: 'copyright', redirectTo: '/home', data: {openCopyrightDialog: true}, pathMatch: 'full' },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'internal', component: InternalComponent, canActivate: [AuthGuardService], canActivateChild: [AuthGuardService], children: [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
        { path: 'dashboard', component: InternalDashboardComponent},
        { path: 'mail', component: InternalEmailComponent},
        { path: 'mail/new', component: InternalEmailEditComponent},
        { path: 'presence', component: InternalPresenceComponent},
        { path: 'presence/:presenceid', component: InternalPresenceEditComponent},
        { path: 'gallery', component: InternalGalleryComponent},
        { path: 'requests', component: InternalRequestsComponent},
        { path: 'events', component: InternalEventsComponent},
        { path: 'locations', component: InternalLocationsComponent},
        { path: 'locations/:locationid', component: InternalLocationsEditComponent},
        { path: 'todos', component: InternalTodosComponent },
        { path: 'musicwishes', component: InternalMusicwishesComponent},
        { path: 'schulradio', component: InternalSchulradioComponent },
        { path: 'inventory', component: InternalInventoryComponent},
        { path: 'finances', component: InternalFinancesComponent},
        { path: 'users', component: InternalUsersComponent},
        { path: 'users/:userid', component: InternalUsersEditComponent},
        { path: 'invoice', component: InvoiceEditorComponent},
        { path: 'settings', component: InternalSettingsComponent},
    ]},
    { path: 'workstation', component: InternalWorkstationComponent, data: {hideMenu: true}, canActivate: [AuthGuardService], canActivateChild: [AuthGuardService], children: [
        { path: '', redirectTo: 'todos', pathMatch: 'full'},
        { path: 'dashboard', component: InternalDashboardComponent},
        { path: 'presence', component: InternalPresenceComponent},
        { path: 'presence/:presenceid', component: InternalPresenceEditComponent},
        { path: 'todos', component: InternalTodosComponent },
        { path: 'inventory', component: InternalInventoryComponent},
    ]},
    { path: "schulradio", component: SchulradioComponent, data: {hideMenu: true}, },

    { path: "oauth", data: {hideMenu: true}, children: [
        { path: "login", component: OAuthLoginComponent }
    ]},

    { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }