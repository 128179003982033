<div id="int-container">
  <div id="int-sidebar" [style.left]="menuOpen? '0' : ''">

		<div id="closeWrapper">
			<button mat-icon-button color="white" aria-label="Menu" (click)="closeMenu()">
				<mat-icon>close</mat-icon>
			</button>
		</div>

    <div id="sidebar-scroll">
      <div class="int-sidebar_option" *ngIf="hasPermission('dashboard')" routerLink="dashboard" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.dashboard" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasEmail()" routerLink="mail" routerLinkActive="active" (click)="closeMenu()" [matBadge]="amountUnreadMails" matBadgeColor="warn" [matBadgeHidden]="amountUnreadMails == 0">{{ "internal.menu.mail" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('presence')" routerLink="presence" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.presence" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('gallery')" routerLink="gallery" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.gallery" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('requests')" routerLink="requests" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.requests" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('events')" routerLink="events" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.events" | translate }}</div>
      <!-- <div class="int-sidebar_option" *ngIf="hasPermission('locations')" routerLink="locations" routerLinkActive="active" (click)="closeMenu()">{{ 'dashboard.menu.locations' | translate }}</div> -->
      <div class="int-sidebar_option" *ngIf="hasPermission('todos')" routerLink="todos" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.todos" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('musicwishes')" routerLink="musicwishes" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.musicwishes" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('schulradio')" routerLink="schulradio" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.radio" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('inventory')" routerLink="inventory" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.inventory" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('finances')" routerLink="finances" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.finances" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('users')" routerLink="users" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.users" | translate }}</div>
      <div class="int-sidebar_option" *ngIf="hasPermission('settings')" routerLink="settings" routerLinkActive="active" (click)="closeMenu()">{{ "internal.menu.settings" | translate }}</div>
    </div>
  </div>

  <div id="contentClickHandler" *ngIf="shadowVisible" [style.opacity]="menuOpen? '1' : '0'" (click)="closeMenu()"></div>

  <div class="int-content">
    <router-outlet></router-outlet>
  </div>
</div>
