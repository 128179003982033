import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map, Observable, startWith } from "rxjs";
import { ApiService } from "../api.service";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { Permission } from "../models/tagpermission";
import { TAGInvItemInstance, TAGInvItemType, TAGInvTransaction } from "../models/taginventory";
import { PrinterService } from "../printer.service";
import { TransactionEditDialog } from "./transactionEditDialog";
import { BarcodeEditDialog } from "./barcodeEditDialog";

export interface ItemEditDialogData {
  item: TAGInvItemType;
  selectedInstances?: string[];
}

@Component({
  selector: "int-inventory-itemeditdialog",
  templateUrl: "itemEditDialog.html",
  styleUrls: ["./itemEditDialog.scss"],
})
export class ItemEditDialog {
  //TODO: Form with validation
  categories: string[] = [];

  item: TAGInvItemType = new TAGInvItemType();
  transactions: TAGInvTransaction[] = [];

  showDeletedInstances: boolean = false;
  selectedInstances: string[] = [];

  catControl = new FormControl("");
  filteredCategories: Observable<string[]>;

  editingDisabled: boolean = false;
  deletionDisabled: boolean = false;

  constructor(
    private api: ApiService,
    private printer: PrinterService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ItemEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ItemEditDialogData
  ) {

    // Init new Item
    this.item = new TAGInvItemType();
    this.editingDisabled = !this.hasPerm("create_inventory_item");
    this.deletionDisabled = !this.hasPerm("delete_inventory_item");

    if (data.item) {
      // Item has been given
      this.item = new TAGInvItemType(Object.assign({}, data.item));
      if (this.item._id) {
        this.editingDisabled = !this.hasPerm("edit_inventory_item");
      }
    }

    this.filteredCategories = this.catControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._catFilter(value || ""))
    );

    // Preselect Instances
    if (data.selectedInstances) {
      this.selectedInstances = data.selectedInstances;

      var hasDeletedSelected = false;
      this.selectedInstances.forEach((instanceID) => {
        if (this.item.instances.find((instance) => instance.itemid == instanceID).deleted) hasDeletedSelected = true;
      });

      if (hasDeletedSelected) this.showDeletedInstances = true;
    }

    if (this.item.deleted) {
      this.editingDisabled = true;
      this.deletionDisabled = true;
    }

    if (this.editingDisabled) {
      this.catControl.disable();
    }

    this.dialogRef.disableClose = true;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  allItemsSelected() {
    return this.selectedInstances.length == this.item.instances.length;
  }

  selectAll() {
    if (this.selectedInstances.length == this.item.instances.length) {
      this.selectedInstances = [];
    } else {
      this.selectedInstances = this.item.instances.map((instance) => instance.itemid);
    }
  }

  filteredInstances(showDeleted: boolean = false) {
    return this.item.instances.filter((instance) => !instance.deleted || showDeleted);
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.extractCategories();

      this.api.getInvTransactionsByItem(this.item._id).then((result) => {
          this.transactions = result;
          this.transactions.sort((a, b) => {
            return a.startdate > b.startdate ? -1 : 1;
          });
        }).catch((reason) => {
          console.error(reason);
        });
    });
  }

  extractCategories() {
    this.api.getInvItems().then((result) => {
      this.categories = [];
      result
        .filter((item) => !item.deleted)
        .forEach((item) => {
          if (!this.categories.includes(item.category)) {
            this.categories.push(item.category);
          }
        });

      this.categories.sort();
    }).catch((reason) => {
      //TODO: Show error
    });
  }

  getLatestTransaction(instanceID: string) {
    var filtered = this.transactions.filter((transaction) => transaction.findItem(this.item._id, instanceID) != null);
    filtered.sort((a, b) => {
      return a.startdate > b.startdate ? -1 : 1;
    });
    return filtered[0];
  }

  instanceStatus(instanceID: string) {
    var transaction = this.getLatestTransaction(instanceID);
    if (!transaction) return true;

    return transaction.findItem(this.item._id, instanceID).back != null;
  }

  instanceTransaction(instanceID: string) {
    return this.getLatestTransaction(instanceID);
  }

  showTransaction(instance: TAGInvItemInstance) {
    var transaction = this.instanceTransaction(instance.itemid);

    console.log(transaction);

    const dialogRef = this.dialog.open(TransactionEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { transaction: transaction },
    });

    this.dialogRef.close(null);
  }

  cancelEdit(): void {
    this.dialogRef.close(null);
  }

  saveEdit(close: boolean = true): void {
    if (this.item._id) {
      this.api.updateInvItem(this.item).then((result) => {
        this.item = result;
        if (close) this.dialogRef.close(this.item);
      }).catch((reason) => {
        console.error(reason);
      });
    } else {
      this.api.newInvItem(this.item).then((result) => {
        this.item = result;
        this.extractCategories();
        if (close) this.dialogRef.close(this.item);
      }).catch((reason) => {
        console.error(reason);
      });
    }
  }

  deleteItem() {
    openDeleteDialog(this.dialog, "Item", [this.item.instances.length + "x " + this.item.name])
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.api.deleteInvItem(this.item._id).then((result) => {
            this.dialogRef.close({ item: this.item, delete: true });
          }).catch((reason) => {
            console.error(reason);
          });
        }
      });
  }

  _catFilter(filter: String): string[] {
    const filterValue = filter.toLowerCase();

    return this.categories.filter((option) => option.toLowerCase().includes(filterValue));
  }

  addInstance() {
    var nextId = 1;
    this.item.instances.forEach((instance) => {
      if (parseInt(instance.itemid) >= nextId) nextId = parseInt(instance.itemid) + 1;
    });

    this.item.instances.push(
      new TAGInvItemInstance({
        itemid: nextId.toString(),
      })
    );

    this.selectedInstances.push(nextId.toString());
  }

  deleteInstance(instanceID: string) {
    var amount = this.item.instances.filter((instance) => instance.itemid == instanceID).length;

    if (this.item.instances.find((instance) => instance.itemid == instanceID).deleted) {
      this.item.instances.find((instance) => instance.itemid == instanceID).deleted = false;
      return;
    }

    openDeleteDialog(this.dialog, "Items", [amount + "x " + this.item.name])
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (!this.item._id) {
            this.item.instances = this.item.instances.filter((instance) => instance.itemid != instanceID);
          } else {
            this.item.instances.find((instance) => instance.itemid == instanceID).deleted = true;
          }
        }
      });
  }

  editInstanceBarcodes(instance: TAGInvItemInstance) {
    const dialogRef = this.dialog.open(BarcodeEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { barcodes: instance.barcodes },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        this.item.instances.find((inst) => inst.itemid == instance.itemid).barcodes = result;
      }
    });
  }

  instanceSelected(instanceID: string, checked: boolean) {
    if (checked) {
      this.selectedInstances.push(instanceID);
    } else {
      this.selectedInstances = this.selectedInstances.filter((id) => id != instanceID);
    }

    console.log(this.selectedInstances);
  }

  printLabelsAll() {
    if (!this.printer.isConnected())
      this.printer
        .connectToPrinter()
        .then((result) => {
          this.printer.printItemLabels(
            this.item,
            this.item.instances.filter((instance) => this.selectedInstances.includes(instance.itemid))
          );
        })
        .catch((reason) => {
          console.error(reason);
        });
    else
      this.printer.printItemLabels(
        this.item,
        this.item.instances.filter((instance) => this.selectedInstances.includes(instance.itemid))
      );
  }
}
