<div id="int-content-topbar">
    <button *ngIf="hasPerm('create_locations')" mat-stroked-button routerLink="new" routerLinkActive="active">{{ 'internal.locations.newLocation' | translate }}</button>
</div>
<div id="int-locations-content">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

        <ng-container class="column-wide" matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'internal.locations.name' | translate }} </th>
            <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_locations')? row._id : './'" routerLinkActive="active"> {{row.name}} </td>
        </ng-container>

        <ng-container class="column-wide" matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'internal.locations.address' | translate }} </th>
            <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_locations')? row._id : './'" routerLinkActive="active"> {{row.address}} </td>
        </ng-container>

        <ng-container class="column-wide" matColumnDef="coordinates">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'internal.locations.coordinates' | translate }} </th>
            <td mat-cell *matCellDef="let row" [routerLink]="hasPerm('edit_locations')? row._id : './'" routerLinkActive="active"> {{row.coordinates.lat + "," + row.coordinates.lon }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
        </table>

        <mat-paginator [length]="locations.length" [pageSize]="pageSize" (page)="switchPage($event)" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>