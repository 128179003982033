<div id="itembook-header">
  <div id="itembook-header-title">{{ 'internal.inventory.itemBooking.title' | translate }}</div>
</div>

<div id="itembook-form">
  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.inventory.itemBooking.location' | translate }}</mat-label>
    <input matInput placeholder="Gymnastikhalle" [(ngModel)]="transaction.location" />
    <mat-icon matSuffix>edit</mat-icon>
  </mat-form-field>

  <div id="instance-list">
    <div id="instance-list-header">
      <div id="instance-list-header-title">{{ 'internal.inventory.items' | translate }}</div>
    </div>

    <div id="hspacer"></div>

    <div id="instance-list-content">
      <div *ngFor="let types of itemTypes()" class="instance">
        <div class="instance-amount">{{itemFilter(types._id).length}}x</div>
        <div class="instance-name">{{types.name}}</div>
        <div class="instance-addInfo">{{types.additionalInfo}}</div>
      </div>
    </div>
  </div>
</div>

<div id="itembook-actions">
  <button mat-stroked-button color="warn" (click)="cancelEdit()">{{ 'general.cancel' | translate }}</button>
  <button mat-stroked-button [disabled]="transaction.location.trim() == ''" (click)="saveEdit()">{{ 'general.save' | translate }}</button>
</div>
