import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-imagedialog",
  templateUrl: "./imagedialog.component.html",
  styleUrls: ["./imagedialog.component.css"],
})
export class ImagedialogComponent implements OnInit {
  getFormattedHTML(text: string) {
    return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }

  getFormattedDate(text: string) {
    var yearindex = text.indexOf("-");
    var monthindex = text.indexOf("-", yearindex + 1);
    var dayindex = text.indexOf(" ", monthindex + 1);

    var year = text.substring(0, yearindex);
    var month = text.substring(yearindex + 1, monthindex);
    var day = text.substring(monthindex + 1, dayindex);
    return day + "." + month + "." + year;
  }

  defaultImage = "https://media.giphy.com/media/RHEqKwRZDwFKE/giphy.gif";

  imageLoaded: boolean = false;
  img;

  constructor(public dialogRef: MatDialogRef<ImagedialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.img = document.getElementById("image") as HTMLImageElement;
  }

  imageLoad() {
    this.img.style.width = null;
    this.img.style.height = null;

    console.log(this.img.width + "|" + this.img.height);
    this.imageLoaded = true;
    if (this.img.width > this.img.height) {
      // this.img.style.width = "100%";
      this.img.style.height = "auto";
    } else {
      this.img.style.height = "80vh";
      //this.img.style.width = "auto";
    }
  }
}
