import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { ImagedialogComponent } from '../imagedialog/imagedialog.component';
import { TAGImageCategory } from '../models/tagimagecategory';
import { TAGEvent } from '../models/tagevent';
import { TAGImage } from '../models/tagimage';
import { TAGLocation } from '../models/taglocation';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  locations: TAGLocation[] = [];
  events: TAGEvent[] = [];
  selectedEvent: TAGEvent;
  eventCategory: TAGImageCategory;
  eventImages: TAGImage[] = [];

  mapsURL: SafeUrl = "";

  paramsSub: Subscription = null;

  constructor(private api: ApiService, public dialog: MatDialog, private sanitizer: DomSanitizer, private route: ActivatedRoute) { }

  getImageLink(imageid: string) { return this.api.getImageLink(imageid); }
  getThumbLink(imageid: string) { return this.api.getThumbLink(imageid); }

  getLocationImageLink(locationid: string) { return this.api.getLocationImageLink(locationid); }
  eventLocation(locationid: string) { return this.locations.find(l => l._id == locationid); }
  locationName(locationid: string) {
    let location = this.eventLocation(locationid);
    if (location != null) return location.name;
    return locationid;
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {

      this.api.getLocations().then((result) => {
        this.locations = result;

        this.api.getEvents(true).then((result) => {
          this.events = result.filter(e => e.enddate > new Date()).sort((a: TAGEvent, b: TAGEvent) => <any>a.startdate - <any>b.startdate);
          this.paramsSub = this.route
          .queryParamMap
          .subscribe(params => {
            console.log(params);
            if (params.get("eventid")) {
              this.selectEvent(this.events.find(e => e._id == params.get("eventid")));
            } else {
              this.selectEvent(this.events[0]);
            }
          });
        }).catch((reason) => {
          console.error(reason);
        });
      }).catch((reason) => {
        console.error(reason);
      });
    });
  }

  ngOnDestroy() {
    if (this.paramsSub) this.paramsSub.unsubscribe();
  }

  selectEvent(event: TAGEvent) {
    console.log("select");

    this.selectedEvent = event;
    this.eventCategory = null;
    this.eventImages = [];

    if (this.eventLocation(event.location)) {
      this.mapsURL = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed/v1/view?key=AIzaSyCMTy1rHboTeVRUzlxBfX9o_48mYQXuf3Y&center=" + this.eventLocation(event.location).coordinates.lat + "," + this.eventLocation(event.location).coordinates.lon + "&zoom=15");
    }

    this.api.getCategoryForEvent(event._id).then((result) => {
      this.eventCategory = result;

      this.api.getImages(this.eventCategory._id).then((result) => {
        this.eventImages = result;
      }).catch((reason) => {
        //TODO: Show Error
      });
    }).catch((reason) => {
      //TODO: Show Error
    });
  }

  openLocationImage(location: TAGLocation) {
    var image: TAGImage = new TAGImage({title: location.name, description: location.description});
    this.dialog.open(ImagedialogComponent, {
      width: '90%',
      //height: '90%',
      //maxWidth: '90%',
      //maxHeight: '90%',
      data: {image: image, link: this.getLocationImageLink(location._id)},
      panelClass: 'image-dialog-container',
    });
  }

  openDialog(img: TAGImage): void {
    this.dialog.open(ImagedialogComponent, {
      width: '90%',
      //height: '90%',
      //maxWidth: '90%',
      //maxHeight: '90%',
      data: {image: img, link: this.getImageLink(img._id)},
      panelClass: 'image-dialog-container',
    });
  }
}
