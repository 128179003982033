import { animate, sequence, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'click-to-copy',
  templateUrl: './click-to-copy.component.html',
  styleUrls: ['./click-to-copy.component.scss'],
})
export class ClickToCopyComponent implements OnInit {

  @Input() text: string = "";
  @Input() copyText: string = undefined;

  @Input() showIcon: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  copyToClipboard() {
    if (this.copyText) {
      navigator.clipboard.writeText(this.copyText);
    } else {
      navigator.clipboard.writeText(this.text);
    }
  }
}
