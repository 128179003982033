import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from "@angular/router";
import { ApiService } from "./api.service";
import { Permission } from "./models/tagpermission";

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private router: Router, private api: ApiService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return new Promise<boolean>((resolve, reject) => {
      this.api.waitForAPI().then(() => {
        if (route.url[0].path == "internal") {
          if (!this.api.isLoggedIn()) {
            this.router.navigate([""]);
            return resolve(false);
          }
        }

        return resolve(true);
      });
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (state.url.startsWith("/workstation/todos")) return true;
    return this.canActivateUrl(childRoute.url[0].path);
  }

  canActivateUrl(url: string): Promise<boolean> | boolean {
    let userPermissions = Array.from(this.api.user?.permissions ?? []);

    switch (url) {
      case "mail":
        return this.api.user.technikEmail != "";
      case "presence":
        return userPermissions.findIndex((p) => p.category == "Presence") != -1;
      case "gallery":
        return userPermissions.findIndex((p) => p.category == "Images") != -1;
      case "todos":
        return userPermissions.findIndex((p) => p.category == "Todos") != -1;
      case "requests":
        return userPermissions.findIndex((p) => p.category == "Requests") != -1;
      case "events":
        return userPermissions.findIndex((p) => p.category == "Events") != -1;
      case "musicwishes":
        return userPermissions.findIndex((p) => p.category == "Music Wishes") != -1;
      case "schulradio":
        return userPermissions.findIndex((p) => p.category == "Schulradio") != -1;
      case "inventory":
        return userPermissions.findIndex((p) => p.category == "Inventory") != -1;
      case "finances":
        return userPermissions.findIndex((p) => p.category == "Finances") != -1;
      case "users":
        return userPermissions.findIndex((p) => p.category == "Users") != -1;
      case "settings":
        return userPermissions.findIndex((p) => p.name == Permission.edit_config.name) != -1;
      default:
        return true;
    }
  }
}
