<div id="int-content-topbar">
  <button mat-stroked-button routerLink=".." routerLinkActive="active">{{ "general.cancel" | translate }}</button>
  <button mat-stroked-button (click)="this.saveUser()">{{ "general.save" | translate }}</button>
  <button mat-stroked-button class="deletebtn" (click)="this.deleteUser()" *ngIf="this.user._id != undefined && hasPerm('delete_users')">{{ "general.delete" | translate }}</button>
</div>
<div id="int-users-content">
  <div class="int-users-editheader">
    <div class="int-users-edittitle">{{ "internal.users.editTitle" | translate: user }}</div>
    <mat-checkbox [(ngModel)]="this.user == null ? '' : this.user.active">{{ "internal.users.active" | translate }}</mat-checkbox>
  </div>

  <div class="int-users-textgrid">
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.username" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.user == null ? '' : this.user.username" />
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.class" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.user == null ? '' : this.user.schoolclass" />
      <mat-icon matSuffix>school</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.firstname" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.user == null ? '' : this.user.firstname" />
      <mat-icon matSuffix>badge</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.lastname" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.user == null ? '' : this.user.lastname" />
      <mat-icon matSuffix>badge</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.password" | translate }}</mat-label>
      <input type="password" matInput [(ngModel)]="this.user == null ? '' : this.userpassword" />
      <mat-icon matSuffix>password</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.pin" | translate }}</mat-label>
      <input matInput [(ngModel)]="(this.user == null || !this.user.permissions.has(pinPermission)) ? '' : this.userpin" size="4" inputmode="numeric" minlength="4" maxlength="4" (keyup)="checkPinCharacter($event)" [disabled]="true" />
      <button mat-icon-button matPrefix (click)="generateRandomPin()" [disabled]="this.user == null || !this.user.permissions.has(pinPermission)">
        <mat-icon>refresh</mat-icon>
      </button>
      <mat-icon matSuffix>pin</mat-icon>
    </mat-form-field>
  </div>

  <div class="int-users-editheader">
    <div class="int-users-edittitle">{{ "internal.users.titleEmail" | translate }}</div>
  </div>

  <div class="int-users-textgrid">
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.email" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.user == null ? '' : this.user.email" />
      <mat-icon matSuffix>mail_outline</mat-icon>
    </mat-form-field>
    <div class="desktop"></div>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.technikEmail" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.user == null ? '' : this.user.technikEmail" />
      <mat-icon matSuffix>mail_outline</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.users.technikEmailPassword" | translate }}</mat-label>
      <input type="password" matInput [(ngModel)]="this.user == null ? '' : this.user.technikEmailPassword" />
      <mat-icon matSuffix>mail_lock</mat-icon>
    </mat-form-field>
  </div>

  <div class="int-users-editheader">
    <div class="int-users-edittitle">{{ "internal.users.titlePermissions" | translate }}</div>
  </div>

  <div class="int-users-permgrid">
    <div *ngFor="let group of getAllPermissionGroups()" class="permGroup">
      <div class="permGroupHeader">
        <div class="permGroupTitle">
          {{ group }}
        </div>
        <mat-checkbox [disabled]="!hasAnyGroupPerm(group)" [checked]="this.user == null ? false : userHasAllGroupPerm(group)" (change)="editPermGroup(group, $event.checked)"></mat-checkbox>
      </div>
      <mat-checkbox *ngFor="let perm of this.getPermissionsByGroup(group)" style="width: fit-content;" [disabled]="!hasPerm(perm.name) && perm.name != 'kiosk_mode'" matTooltipPosition="left" matTooltip="{{ 'permissions.' + group.toLowerCase() + '.' + perm.name + '.description' | translate }}" [ngModel]="this.user == null ? false : this.user.permissions.has(perm)" (change)="editPermission(perm, $event.checked)">{{ "permissions." + group.toLowerCase() + "." + perm.name + ".name" | translate }}</mat-checkbox>
    </div>
  </div>
</div>
