import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { TAGInvItemGroup, TAGInvItemInstance, TAGInvItemType } from "./models/taginventory";

@Injectable({
  providedIn: "root",
})
export class PrinterService {
  private apiReady: boolean = false;
  private serialSupported: boolean = false;
  private serialConnected: boolean = false;
  private serialPort: any = null;

  private isPrinting: boolean = false;
  private printTimer: any = null;

  private labelQueue: string[] = [];

  constructor(private api: ApiService) {
    if ("serial" in navigator) {
      console.log("serial is supported");
      this.serialSupported = true;

      api.waitForAPI().then(() => {
        this.apiReady = true;
      });
    } else {
      console.log("serial is not supported");
    }
  }

  isSupported() {
    return this.serialSupported && this.apiReady;
  }

  isConnected() {
    return this.serialConnected && this.apiReady;
  }

  isPrintingLabels() {
    return this.isPrinting;
  }

  connectToPrinter() {
    return new Promise<void>((resolve, reject) => {
      if (!this.apiReady) reject(new Error("API not ready"));
      if (!this.serialSupported) reject(new Error("Serial not supported"));

      if (!this.serialPort) {
        let nav: any = navigator;

        nav.serial
          .requestPort()
          .then((port) => {
            console.log(port.getInfo());
            this.serialPort = port;
            this.connectToPort()
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        this.serialPort.close();
        this.serialConnected = false;
        this.connectToPort();
      }
    });
  }

  private connectToPort() {
    return new Promise<void>((resolve, reject) => {
      if (!this.serialPort) return;

      this.serialPort
        .open({ baudRate: 57600 })
        .then(() => {
          this.serialConnected = true;
          console.log("connected");
          this.initPrinter()
            .then((result) => {
              resolve();
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  private initPrinter() {
    return new Promise<void>(async (resolve, reject) => {
      console.log("init printer");
      console.log(this.api.config.labelTemplate);
      console.log(this.api.config.groupLabelTemplate);
      await this.writeSerial(this.api.config.labelTemplate);
      await this.writeSerial(this.api.config.groupLabelTemplate);
      resolve();
    });
  }

  private replaceTable = {
    194: {
      167: 245, // §
    },
    195: {
      132: 142, // Ä
      150: 153, // Ö
      156: 154, // Ü
      164: 132, // ä
      182: 148, // ö
      188: 129, // ü
      159: 225, // ß
    },
  };

  private convertToZPL1252(intArr: Uint8Array) {
    var arr = Array.from(intArr);

    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == 194 || arr[i] == 195) {
        arr[i] = this.replaceTable[arr[i]][arr[i + 1]];
        arr.splice(i + 1, 1);
      }
    }

    return arr;
  }

  private writeSerial(data: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.serialPort) reject(new Error("No serial port selected"));
      if (!this.serialConnected) reject(new Error("Serial port not connected"));

      const textEncoder = new TextEncoder();
      const writer: WritableStreamDefaultWriter = this.serialPort.writable.getWriter();

      console.log(this.convertToZPL1252(textEncoder.encode(data)).join(" "));
      var arr = this.convertToZPL1252(textEncoder.encode(data));

      writer
        .write(new Uint8Array(arr))
        .then((result) => {
          writer.releaseLock();
          resolve();
        })
        .catch((reason) => {
          writer.releaseLock();
          reject(reason);
        });
    });
  }

  printItemLabels(item: TAGInvItemType, instances: TAGInvItemInstance[]) {
    if (!this.serialConnected) throw new Error("Printer not connected");

    for (let inst of instances) {
      var combID = item._id + "-" + inst.itemid;

      this.labelQueue.push(
        `^XA ^XFR:TECHNIK.ZPL ^CI6 ^FS ^FN1 ^FD${combID} ^FS ^FN2 ^FH ^FD${item.name} ^FS ^FN4 ^FD${item.additionalInfo} ^FS ^FN3 ^FD${item.description} ^FS  ^XZ`
      )
    }

    if (!this.isPrinting) this.processQueue();
  }

  printGroupLabels(groups: TAGInvItemGroup[]) {
    if (!this.serialConnected) throw new Error("Printer not connected");

    for (let g of groups) {
      this.labelQueue.push(
        `^XA ^XFR:TECHNIKCONT.ZPL ^CI6 ^FS ^FN1 ^FD${g._id} ^FS ^FN2 ^FH ^FD${g.name} ^FS ^XZ`
      )
    }

    if (!this.isPrinting) this.processQueue();
  }

  private resetTimer() {
    this.isPrinting = true;
    if (this.printTimer) clearTimeout(this.printTimer);
    this.printTimer = setTimeout(() => {
      this.isPrinting = false;
    }, 2000);
  }

  private processQueue() {
    if (this.labelQueue.length == 0) return;

    this.resetTimer();
    let job = this.labelQueue.shift();

    this.writeSerial(job).then((result) => {
      if (this.labelQueue.length > 0) {
        this.processQueue();
      }
    }).catch((reason) => {
      console.error(reason);
      if (this.labelQueue.length > 0) {
        this.processQueue();
      }
    });
  }
}
