<div id="mail-edit-content">
  <div id="mail-edit-topbar">
    <button mat-icon-button matTooltip="{{ 'general.delete' | translate }}" (click)="back()">
      <mat-icon>delete</mat-icon>
    </button>

    <!-- <button mat-icon-button matTooltip="Draft EMail">
            <mat-icon>drafts</mat-icon>
        </button> -->

    <button mat-icon-button matTooltip="{{ 'internal.mails.send' | translate }}" (click)="sendMail()">
      <mat-icon>send</mat-icon>
    </button>
  </div>

  <div *ngIf="this.mail" id="mail-edit-header">
    <div id="mail-edit-header-from">
      <mat-icon>person</mat-icon>
      <span>{{ "internal.mails.from" | translate }}:</span>
      <div>
        <span class="name">{{ mail.from.addresses[0].name }}</span>
        &lt;<span class="address">{{ mail.from.addresses[0].address }}</span
        >&gt;
      </div>
    </div>

    <div class="mail-edit-header-to">
      <mat-icon>people</mat-icon>
      <span>{{ "internal.mails.to" | translate }}:</span>
      <div class="mailFormField" style="display: inline-block">
        <mat-chip-list #receiverList>
          <mat-chip
            *ngFor="let add of mail.to.addresses"
            (removed)="removeReceiver(add)"
            [matTooltip]="add.name == '' ? add.address : add.name + ' <' + add.address + '>'">
            {{ add.name == "" ? add.address : add.name }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>

          <input
            #contactInput
            placeholder="{{ 'internal.mails.newReceiver' | translate }}"
            [formControl]="contactCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="receiverList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addReceiver($event)" />
        </mat-chip-list>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedReceiver($event)">
          <mat-option *ngFor="let contact of filteredContacts | async" [value]="contact">
            {{ contact.firstname }} {{ contact.lastname }} &lt;{{ contact.email }}&gt;
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>

    <div class="mail-edit-header-to">
      <mat-icon>people</mat-icon>
      <span>Cc:</span>
      <div class="mailFormField" style="display: inline-block">
        <mat-chip-list #ccList>
          <mat-chip
            *ngFor="let add of mail.cc.addresses"
            (removed)="removeCc(add)"
            [matTooltip]="add.name == '' ? add.address : add.name + ' <' + add.address + '>'">
            {{ add.name == "" ? add.address : add.name }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input
            #ccInput
            placeholder="{{ 'internal.mails.newCCReceiver' | translate }}"
            [formControl]="ccCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="ccList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addCc($event)" />
        </mat-chip-list>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedCc($event)">
          <mat-option *ngFor="let contact of filteredContacts | async" [value]="contact">
            {{ contact.firstname }} {{ contact.lastname }} &lt;{{ contact.email }}&gt;
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>

    <div id="mail-edit-header-subject">
      <div class="mailFormField">
        <input class="mailInput" placeholder="{{ 'internal.mails.subject' | translate }}" [(ngModel)]="mail.subject" />
      </div>
    </div>

    <div style="display: contents">
      <div class="h-spacer"></div>
      <div id="mail-edit-attachments">
        <div id="mail-edit-attachments-title">{{ "internal.mails.attachments" | translate }}</div>
        <mat-chip-list #attachmentList>
          <mat-chip *ngFor="let att of mail.attachments" [matMenuTriggerFor]="attachMenu" [matMenuTriggerData]="{ attachment: att }" (removed)="removeAttachment(att)">
            <mat-icon>attach_file</mat-icon>
            {{ att.fileName }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>

          <div
            id="uploadfilecontainer"
            [ngClass]="{ hidden: mail.attachments.length > 0 }"
            (click)="attachmentInput.click()"
            appDragDrop
            (onFileDropped)="uploadFile($event)">
            <div id="uploadText">
              {{ "internal.mails.attachmentInstructions" | translate }}
            </div>
            <input
              (change)="uploadFile($event.target.files)"
              #attachmentInput
              type="file"
              hidden
              placeholder="New Attachment"
              [matChipInputFor]="attachmentList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addAttachment($event)" />
          </div>
        </mat-chip-list>

        <mat-menu #attachMenu="matMenu">
          <ng-template matMenuContent let-att="attachment">
            <button mat-menu-item (click)="downloadAttachment(mail, att.fileName, false)">{{ "internal.mails.view" | translate }}</button>
            <button mat-menu-item (click)="downloadAttachment(mail, att.fileName, true)">{{ "internal.mails.download" | translate }}</button>
          </ng-template>
        </mat-menu>
      </div>

      <div class="h-spacer"></div>
    </div>
  </div>

  <!--TODO: No hardcoded API Key-->

  <div *ngIf="this.mail" id="mail-edit-editor">
    <editor
      [(ngModel)]="mail.html"
      apiKey="4yfwwp3p2mabyoemebguryqwwb6jr492au3ori8wt8q0th32"
      skin="oxide-dark"
      [init]="{ plugins: 'lists link image table code help wordcount' }">
    </editor>
  </div>
</div>
