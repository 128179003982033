import { DBModel } from "./DBModel";

export class TAGLocation extends DBModel {
    address: string = "";
    name: string = "";
    description: string = "";
    coordinates: {lat: number, lon: number} = {lat: 0, lon: 0};

    constructor(info: any = {}) {
        super();
        this.deserialize(info);
    }

    serialize(): any {
        var obj: any = super.serialize();

        obj.address = this.address;
        obj.name = this.name;
        obj.description = this.description;
        obj.coordinates = this.coordinates;

        return obj;
    }

    deserialize(data: any) {
        if (data._id != undefined) this._id = data._id;
        if (data._rev != undefined) this._rev = data._rev;

        if (data.address != null) this.address = data.address;
        if (data.name != null) this.name = data.name;
        if (data.description != null) this.description = data.description;
        if (data.coordinates != null) this.coordinates = data.coordinates;

        return this;
    }
  }