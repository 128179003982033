<h1>Die Technik-AG</h1>

<h2>Entstehung</h2>
<p>
  Die Technik-AG gibt es schon sehr lange: schon 1999 wurde die Technik-AG am Fanny-Leicht Gymnasium gegründet. Damals war die Technik-AG jedoch "nur" eine Unter-AG der
  Theater-AG. Doch durch das wachsende Interesse an der Veranstaltungstechnik in unserer Schule wurde die Technik-AG schon wenige Jahre später als eigene, selbständige AG
  am Fanny-Leicht Gymnasium begrüßt. Damit ist die Technik-AG nicht nur einer der ältesten AG's sondern wahrscheinlich auch die erfolgreichste AG am Fanny-Leicht
  Gymnasium.
</p>

<h2>Beschäftigung</h2>
<p>
  In der Technik-AG beschäftigen wir uns hauptsächlich mit der Veranstaltungstechnik. Im Schulaltag bedeutet das, dass wir uns um jede Form von Veranstaltungen kümmern:
  das fängt bei dem französisch- und russischen Abend an, geht über die Einschulungen, Suchtpräventionswochen und Unterstufendiskos und endet am Ende des Jahres mit dem
  märchenhaften Abiball der Abiturienten in der Filderhalle.
</p>

<h2>Unsere AG</h2>
<p>Wir, als Technik-AG bestehen nicht nur Schülern aus verschiedenen Stufen des FLG's, auch ehemalige Mitglieder besuchen uns häufig...</p>
