<div class="event-item">
    <div class="event-item-date">
        <div class="event-item-date-day">{{event.startdate | date:"dd"}}</div>
        <div class="event-item-date-month">{{event.startdate | date:"MMM"}}</div>
    </div>
    <div class="event-item-information">
        <div class="event-item-title">{{event.name}}</div>
        <!-- TODO: Switch Description to Location -->
        <div class="event-item-location">{{location}}</div>
    </div>
    <button mat-icon-button (click)="buttonClicked()">
        <mat-icon>arrow_forward_ios</mat-icon>
    </button>
</div>