<div id="int-content-topbar">
  <button mat-stroked-button (click)="this.newCategory()" *ngIf="!this.editCategory && this.selectedImage == undefined && hasPerm('create_category')">
    {{ "internal.gallery.newCategory" | translate }}
  </button>
  <button mat-stroked-button (click)="this.newImage()" *ngIf="!this.editCategory && this.selectedImage == undefined && hasPerm('create_image')">
    {{ "internal.gallery.newImage" | translate }}
  </button>

  <button mat-stroked-button (click)="this.cancelEdit()" *ngIf="this.editCategory || this.selectedImage != undefined">
    {{ "general.cancel" | translate }}
  </button>
  <button mat-stroked-button (click)="this.saveEdit()" *ngIf="this.editCategory || this.selectedImage != undefined">
    {{ "general.save" | translate }}
  </button>
  <button
    mat-stroked-button
    class="redbtn"
    (click)="this.deleteObject()"
    *ngIf="
      ((this.editCategory && this.selectedCategory != undefined) || this.selectedImage != undefined) &&
      (hasPerm('delete_category') || hasPerm('delete_image'))
    ">
    {{ "general.delete" | translate }}
  </button>
</div>
<div id="int-gallery-content" *ngIf="!this.editCategory && this.selectedImage == undefined">
  <div id="int-gallery-title">{{ "internal.gallery.title" | translate }}</div>

  <div id="int-gallery-categorycontent">
    <div id="int-gallery-categories-title">{{ "internal.gallery.categories" | translate }}</div>

    <div>
      <mat-form-field>
        <input matInput (keyup)="applyFilterCategories($event.target.value)" placeholder="{{ 'general.search' | translate }}" />
      </mat-form-field>

      <div class="mat-elevation-z8">
        <table id="categoryTable" mat-table [dataSource]="dataSourceCategory" matSort>
          <ng-container matColumnDef="categoryname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.gallery.category" | translate }}</th>
            <td mat-cell *matCellDef="let row" style="line-height: 48px">
              {{ row.title }}
            </td>
          </ng-container>

          <ng-container *ngIf="hasPerm('edit_category')" matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.gallery.edit" | translate }}</th>
            <td mat-cell *matCellDef="let row" style="line-height: 48px">
              <button mat-icon-button class="int-gallery-categories-editCategoryBtn" (click)="doEditCategory(row._id)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="open" justify="end">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.gallery.open" | translate }}</th>
            <td mat-cell *matCellDef="let row" style="line-height: 48px">
              <button mat-icon-button class="int-gallery-categories-editCategoryBtn" (click)="this.selectCategory(row._id)">
                <mat-icon>chevron_right</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsCategory"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsCategory"></tr>
        </table>

        <mat-paginator
          [length]="categories.length"
          [pageSize]="catPageSize"
          (page)="catSwitchPage($event)"
          [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>

  <div id="int-gallery-imagecontent">
    <div id="int-gallery-images-title">{{ "internal.gallery.images" | translate }}</div>

    <div>
      <mat-form-field>
        <input matInput (keyup)="applyFilterImages($event.target.value)" placeholder="{{ 'general.search' | translate }}" />
      </mat-form-field>

      <div class="mat-elevation-z8">
        <table id="imageTable" mat-table [dataSource]="dataSourceImage" matSort>
          <ng-container class="column-wide" matColumnDef="imagePreview">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.gallery.preview" | translate }}</th>
            <td mat-cell *matCellDef="let row" (click)="this.selectImage(row._id)">
              <img style="height: 100px" [src]="this.getImageThumbLink(row._id) | useHttpImgSrc" />
            </td>
          </ng-container>

          <ng-container class="column-wide" matColumnDef="imagetitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.gallery.entityTitle" | translate }}</th>
            <td mat-cell *matCellDef="let row" (click)="this.selectImage(row._id)">
              {{ row.title }}
            </td>
          </ng-container>

          <ng-container class="column-wide" matColumnDef="imagedescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.gallery.entityDescription" | translate }}</th>
            <td mat-cell *matCellDef="let row" (click)="this.selectImage(row._id)">
              {{ row.description }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsImage"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsImage"></tr>
        </table>

        <mat-paginator
          [length]="images.length"
          [pageSize]="imgPageSize"
          (page)="imgSwitchPage($event)"
          [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>

<div id="int-gallery-editcategory-content" *ngIf="this.editCategory">
  <div id="int-gallery-editcategory-title">{{ "internal.gallery.editCategory" | translate: selectedCategoryInfo }}</div>
  <div id="int-gallery-editcategory-textgrid">
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.gallery.entityTitle" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.selectedCategoryInfo.title" />
      <mat-icon matSuffix>info</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.gallery.entityDescription" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.selectedCategoryInfo.description" />
      <mat-icon matSuffix>info</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "internal.gallery.categoryEvent" | translate }}</mat-label>
      <mat-select [(ngModel)]="this.selectedCategoryInfo.eventid">
        <mat-option *ngFor="let evt of events" [value]="evt._id">
          {{ evt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="this.selectedCategoryInfo.isPublic">{{ "internal.gallery.entityPublic" | translate }}</mat-slide-toggle>
  </div>
</div>

<div id="int-gallery-editimage-content" *ngIf="this.selectedImage != undefined">
  <div style="display: grid; grid-template-columns: 1fr">
    <img
      *ngIf="(this.selectedImage != undefined && this.selectedImage != 'new') || this.imageFile != null"
      alt="{{ this.selectedImageInfo.title }}"
      id="int-gallery-editimage-image"
      [src]="this.getImageLink(this.selectedImageInfo._id) | useHttpImgSrc" />

    <div
      *ngIf="this.selectedImage == 'new' && this.imageFile == null"
      class="uploadfilecontainer"
      (click)="fileInput.click()"
      appDragDrop
      (onFileDropped)="uploadFile($event)">
      <input hidden type="file" accept=".jpg,.png,.jpeg" #fileInput (change)="uploadFile($event.target.files)" />
    </div>
    <div
      *ngIf="(this.selectedImage != undefined && this.selectedImage != 'new') || this.imageFile != null"
      class="uploadfilecontainer-hidden"
      (click)="fileInput.click()"
      appDragDrop
      (onFileDropped)="uploadFile($event)">
      <input hidden type="file" accept=".jpg,.png,.jpeg" #fileInput (change)="uploadFile($event.target.files)" />
    </div>
  </div>

  <div id="int-gallery-editimage-title">{{ "internal.gallery.editImage" | translate: selectedImageInfo }}</div>
  <div id="int-gallery-editimage-textgrid">
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.gallery.entityTitle" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.selectedImageInfo.title" />
      <mat-icon matSuffix>info</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ "internal.gallery.entityDescription" | translate }}</mat-label>
      <input matInput [(ngModel)]="this.selectedImageInfo.description" />
      <mat-icon matSuffix>info</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "internal.gallery.category" | translate }}</mat-label>
      <mat-select [(ngModel)]="this.selectedImageInfo.categoryid">
        <mat-option *ngFor="let cat of categories" [value]="cat._id">
          {{ cat.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="this.selectedImageInfo.isPublic">{{ "internal.gallery.entityPublic" | translate }}</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="this.selectedImageInfo.watermark">{{ "internal.gallery.imageWatermark" | translate }}</mat-slide-toggle>
  </div>
</div>
