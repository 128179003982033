<div id="int-content-topbar">
    <button mat-stroked-button (click)="this.cancelLocation()">{{ 'general.cancel' | translate }}</button>
    <button mat-stroked-button (click)="this.saveLocation()">{{ 'general.save' | translate }}</button>
    <button mat-stroked-button class="deletebtn" (click)="this.deleteLocation()" *ngIf="this.location && this.location._id != undefined && hasPerm('delete_locations')">{{ 'general.delete' | translate }}</button>
</div>
<div *ngIf="this.location" id="int-locations-content">

    <div class="int-locations-edittitle">{{ 'internal.locations.editTitle' | translate: location }}</div>
    <div id="int-locations-textgrid">

        <mat-form-field appearance="standard">
            <mat-label>{{ 'internal.locations.name' | translate }}</mat-label>
            <input matInput [(ngModel)]="this.location.name">
            <mat-icon matSuffix>info</mat-icon>
        </mat-form-field>

        <div style="display: grid; grid-template-columns: 1fr;">
            <img *ngIf="this.location._id != undefined || this.imageFile != null" id="int-locations-editimage-image" src="{{this.getImageLink()}}">
            <div *ngIf="this.location._id == undefined && this.imageFile == null" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
                <input hidden type="file" accept=".jpg,.png,.jpeg" #fileInput (change)="uploadFile($event.target.files)">
            </div>
            <div *ngIf="this.location._id != undefined || this.imageFile != null" class="uploadfilecontainer-hidden" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
                <input hidden type="file" accept=".jpg,.png,.jpeg" #fileInput (change)="uploadFile($event.target.files)">
            </div>
        </div>

        <mat-form-field appearance="standard">
            <mat-label>{{ 'internal.locations.address' | translate }}</mat-label>
            <input matInput [(ngModel)]="this.location.address">
            <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>{{ 'internal.locations.description' | translate }}</mat-label>
            <input matInput [(ngModel)]="this.location.description">
            <mat-icon matSuffix>notes</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>{{ 'internal.locations.coordinatesLat' | translate }}</mat-label>
            <input matInput [(ngModel)]="this.location.coordinates.lat">
            <mat-icon matSuffix>memory</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>{{ 'internal.locations.coordinatesLon' | translate }}</mat-label>
            <input matInput [(ngModel)]="this.location.coordinates.lon">
            <mat-icon matSuffix>notes</mat-icon>
        </mat-form-field>

    </div>
</div>