<div id="int-content-topbar">
  <internal-menu-button id="menuBtn"></internal-menu-button>
  <button mat-stroked-button class="redbtn" (click)="revertSettings()">{{ "general.cancel" | translate }}</button>
  <button mat-stroked-button class="greenbtn" (click)="saveSettings()">{{ "general.save" | translate }}</button>
</div>
<div id="int-settings-content">
  <div id="int-settings-title">{{ "internal.settings.title" | translate }}</div>

  <mat-slide-toggle [(ngModel)]="api.config.enableMusicWishes" class="int-settings-setting">{{ "internal.settings.settings.enableMusicwishes" | translate }}</mat-slide-toggle>

  <mat-form-field class="int-settings-setting">
    <mat-label>{{ "internal.settings.settings.defaultGallery" | translate }}</mat-label>
    <mat-select [(ngModel)]="api.config.defaultGallery">
      <mat-option *ngFor="let cat of categories" [value]="cat.alias">
        {{ cat.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-slide-toggle [(ngModel)]="api.config.enableSchulradio" class="int-settings-setting">{{ "internal.settings.settings.enableSchulradio" | translate }}</mat-slide-toggle>

  <mat-slide-toggle [(ngModel)]="api.config.allowGroupsForBookIn" class="int-settings-setting">{{ "internal.settings.settings.allowGroupsForBookIn" | translate }}</mat-slide-toggle>

  <mat-form-field class="int-settings-setting" appearance="fill">
    <mat-label>{{ "internal.settings.settings.labelTemplate" | translate }}</mat-label>
    <textarea matInput [(ngModel)]="api.config.labelTemplate"></textarea>
  </mat-form-field>

  <mat-form-field class="int-settings-setting" appearance="fill">
    <mat-label>{{ "internal.settings.settings.groupLabelTemplate" | translate }}</mat-label>
    <textarea matInput [(ngModel)]="api.config.groupLabelTemplate"></textarea>
  </mat-form-field>
</div>
