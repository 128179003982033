import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { TAGImageCategory } from "../models/tagimagecategory";

@Component({
  selector: "app-internal.settings",
  templateUrl: "./internal.settings.component.html",
  styleUrls: ["./internal.settings.component.css"],
})
export class InternalSettingsComponent implements OnInit {
  categories: TAGImageCategory[] = [];

  constructor(public api: ApiService) {}

  ngOnInit(): void {
    this.api.getCategories().then((result) => {
      this.categories = result;
    }).catch((reason) => {
      console.log(reason);
    });
  }

  revertSettings() {}

  saveSettings() {
    console.log(this.api.config);
    this.api.saveConfig();
  }
}
