import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map, Observable, startWith } from "rxjs";
import { ApiService } from "../api.service";
import { openProgressDialog, ProgressDialogStep, ProgressDialogStepState } from "../dialogs/progressDialog/progressDialog";
import { TAGLocation } from "../models/taglocation";
import { Permission } from "../models/tagpermission";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { TAGRequest } from "../models/tagrequest";

export interface RequestEditDialogData {
  request: TAGRequest;
  locations: TAGLocation[];
}

@Component({
  selector: "int-request-editdialog",
  templateUrl: "requestEditDialog.html",
  styleUrls: ["./requestEditDialog.scss"],
})
export class RequestEditDialog {

  locations: TAGLocation[] = [];
  request: TAGRequest = new TAGRequest();

  locationControl = new FormControl("");
  filteredLocations: Observable<TAGLocation[]>;

/*   newFiles: {fileName: string, mimeType: string, fileData: string}[] = [];
  deletedFiles: string[] = []; */

  today = new Date();
  max = new Date();

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RequestEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: RequestEditDialogData
  ) {
    this.request = new TAGRequest(Object.assign({}, data.request));
    this.locations = data.locations;

    this.max.setDate(this.today.getDate() + 365);

    this.filteredLocations = this.locationControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._locationFilter(value || ""))
    );

    let location = this.locations.find((loc) => loc._id == this.request.location);
    if (location) {
      this.request.location = location.name;
    }

    this.dialogRef.disableClose = true;
  }

  /* getDocumentUrl(eventID: string) { return environment.apiUrl + "events/" + eventID + "/file/<filename>"; }

  allFiles() {
    let allFiles = this.event.files.concat(this.newFiles.map((nf) => { return {fileName: nf.fileName, mimeType: nf.mimeType, fileData: nf.fileData}; }));
    return allFiles.filter((f) => !this.deletedFiles.includes(f.fileName));
  }

  apiUrl() { return environment.apiUrl; } */

  _locationFilter(filter: String): TAGLocation[] {
    const filterValue = filter.toLowerCase();

    return this.locations.filter((loc) =>
      loc.name.toLowerCase().includes(filterValue) ||
      loc.address.toLowerCase().includes(filterValue)
    );
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {

    });
  }

  /* uploadFile(event) {
    console.log(event);
    this.newFiles.push(event);
  }

  deleteFile(fileName: string) {
    if (this.newFiles.find((nf) => nf.fileName == fileName)) {
      this.newFiles = this.newFiles.filter((nf) => nf.fileName != fileName);
    } else {
      this.deletedFiles.push(fileName);
    }
  } */

  promoteRequest() {
    if (this.request._id == undefined) return;

    this.api.updateRequest(this.request).then((result) => {
      this.api.promoteRequest(this.request._id).then((result) => {
        this.dialogRef.close({ deleted: false });
      }).catch((reason) => {
        //TODO: Show Error
      });
    }).catch((reason) => {
      //TODO: Show Error
    });
  }

  cancelRequest(): void {
    this.dialogRef.close(null);
  }

  deleteRequest() {
    if (this.request._id == undefined) return;

    openDeleteDialog(this.dialog, "Request", [this.request.name])
      .afterClosed()
      .subscribe((result) => {
        if (result)
          this.api.deleteRequest(this.request._id).then((result) => {
            this.dialogRef.close({ deleted: true });
          }).catch((reason) => {
            //TODO: Show Error
          });
      });
  }

  saveRequest() {
    console.log(this.request);

    let location = this.locations.find((loc) => loc.name == this.request.location);
    if (location) {
      this.request.location = location._id;
    }

    // Initialize Progress Dialog
    let processingStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Processing",
    }

    /* let uploadStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Upload Files",
      showProgress: true,
      progress: 0,
    }

    if (this.newFiles.length == 0) {
      uploadStep.state = ProgressDialogStepState.SKIPPED;
    } */

    const progressDialog = openProgressDialog(this.dialog, [processingStep/* , uploadStep */]);

    progressDialog.afterOpened().subscribe(() => {
      processingStep.state = ProgressDialogStepState.PROCESSING;

      if (this.request._id != undefined) {
        this.api.updateRequest(this.request).then((result) => {
          processingStep.state = ProgressDialogStepState.SUCCESS;

          /* // Update Files of Event
          if (this.newFiles.length > 0 || this.deletedFiles.length > 0) {
            this.saveFiles(result._id, uploadStep).then(() => {
              progressDialog.componentInstance.finishDialog();
              this.dialogRef.close({ deleted: false });
            }).catch((error) => {
              progressDialog.componentInstance.finishDialog(-1);
              console.log(error);
            });
          } else { */
            progressDialog.componentInstance.finishDialog();
            this.dialogRef.close({ deleted: false });
          /* } */
        }).catch((reason) => {
          processingStep.state = ProgressDialogStepState.ERROR;
          processingStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
          console.log(reason);
        });
      } else {
        //TODO: Form and verification
        if (this.request.name == "") {
          return;
        }

        this.api.newRequest(this.request).then((result) => {
          processingStep.state = ProgressDialogStepState.SUCCESS;

          /* // Update Files of Event
          if (this.newFiles.length > 0 || this.deletedFiles.length > 0) {

            this.saveFiles(result._id, uploadStep).then(() => {
              progressDialog.componentInstance.finishDialog();
              this.dialogRef.close({ deleted: false });
            }).catch((error) => {
              progressDialog.componentInstance.finishDialog(-1);
              console.log(error);
            });
          } else { */
            progressDialog.componentInstance.finishDialog();
            this.dialogRef.close({ deleted: false });
          /* } */
        }).catch((reason) => {
          processingStep.state = ProgressDialogStepState.ERROR;
          processingStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
          console.log(reason);
        });
      }
    });
  }

  /* saveFiles(eventID: string, uploadStep: ProgressDialogStep) {
    return new Promise<void>((resolve, reject) => {
      uploadStep.state = ProgressDialogStepState.PROCESSING;

      let promises = [];
      let doneFiles = 0;

      for (let f of this.newFiles) {
        promises.push(new Promise<void>((resolve, reject) => {
          this.api.uploadEventAttachment(eventID, f.fileName, f.fileData, progress => {
            uploadStep.progress = (doneFiles / this.newFiles.length) + (progress / this.newFiles.length);
          }).then((result) => {
            resolve();
            doneFiles++;
          }).catch((reason) => {
            reject(reason);
            console.log(reason);
            uploadStep.state = ProgressDialogStepState.ERROR;
            uploadStep.subtitle = reason.message;
          });
        }));
      }

      for (let f of this.deletedFiles) {
        promises.push(new Promise<void>((resolve, reject) => {
          this.api.deleteEventAttachment(eventID, f).then((result) => {
            resolve();
          }).catch((reason) => {
            reject(reason);
            console.log(reason);
            uploadStep.state = ProgressDialogStepState.ERROR;
            uploadStep.subtitle = reason.message;
          });
        }));
      }

      Promise.all(promises).then(() => {
        uploadStep.state = ProgressDialogStepState.SUCCESS;
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  } */
}
