import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { TAGEvent } from "../models/tagevent";
import { Permission } from "../models/tagpermission";
import { TAGLocation } from "../models/taglocation";
import { TAGImageCategory } from "../models/tagimagecategory";
import { TAGImage } from "../models/tagimage";
import { TAGTransaction } from "../models/tagfinances";
import { ImagedialogComponent } from "../imagedialog/imagedialog.component";
import { TAGUser } from "../models/taguser";
import { FinanceTransactionEditDialog } from "../internal.finances/financeTransactionEditDialog";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { TAGInvTransaction } from "../models/taginventory";
import { ItemInstanceCombo, ItemScanDialog } from "../internal.inventory/itemScanDialog";
import { ItemBookDialog } from "../internal.inventory/itemBookDialog";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { EventEditDialog } from "./eventEditDialog";
import { InvTransactionImportDialog } from "./InvTransactionImportDialog";
import { ProgressDialogStep, ProgressDialogStepState, openProgressDialog } from "../dialogs/progressDialog/progressDialog";

@Component({
  selector: "app-internalEvents",
  templateUrl: "./internal.events.component.html",
  styleUrls: ["./internal.events.component.scss"],
})
export class InternalEventsComponent implements OnInit {
  users: TAGUser[] = [];
  locations: TAGLocation[] = [];
  events: TAGEvent[] = [];
  filteredEvents: TAGEvent[] = [];
  oldEvents: TAGEvent[] = [];
  filteredOldEvents: TAGEvent[] = [];

  selectedEvent: TAGEvent;
  eventFinances: TAGTransaction[] = [];
  eventInventory: TAGInvTransaction[] = [];
  eventCategory: TAGImageCategory;
  eventImages: TAGImage[] = [];

  today = new Date();
  max = new Date();

  constructor(private api: ApiService, public dialog: MatDialog, private router: Router) {
    this.max.setDate(this.today.getDate() + 365);
  }

  getImageLink(imageid: string) { return this.api.getImageLink(imageid); }
  getThumbLink(imageid: string) { return this.api.getThumbLink(imageid); }

  getDocumentUrl(eventID: string) { return environment.apiUrl + "events/" + eventID + "/file/<filename>"; }

  eventLocation(locationid: string) { return this.locations.find(l => l._id == locationid); }
  locationName(locationid: string) {
    let location = this.eventLocation(locationid);
    if (location != null) return location.name;
    return locationid;
  }

  getUserName(username: string) {
    var usr = this.users.find((u) => u.username == username);
    if (usr != undefined) return usr.firstname + " " + usr.lastname;

    return username;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  anyFinancePending() {
    return this.eventFinances.find((f) => f.pending) != undefined;
  }

  financeTotal() {
    var total = 0;
    this.eventFinances.forEach((t) => {
      let nAmount = typeof t.amount == "string" ? parseFloat(t.amount) : t.amount;
      total += (t.isIncome? nAmount : -nAmount);
    });
    return total;
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.reloadEvents();
    });
  }

  onFilterKeyUp(filter: string) {
    if (filter.trim() == "") {
      this.filteredEvents = this.events;
      this.filteredOldEvents = this.oldEvents;
      return;
    }

    this.filteredEvents = this.events.filter((e) => {
      let includes = false;

      if (e.name) includes = includes || e.name.toLowerCase().includes(filter.toLowerCase());
      if (e.contact) includes = includes || e.contact.toLowerCase().includes(filter.toLowerCase());
      if (e.contactMail) includes = includes || e.contactMail.toLowerCase().includes(filter.toLowerCase());
      if (e.location) includes = includes || this.locationName(e.location).toLowerCase().includes(filter.toLowerCase());
      if (e.equipment) includes = includes || e.equipment.toLowerCase().includes(filter.toLowerCase());
      if (e.description) includes = includes || e.description.toLowerCase().includes(filter.toLowerCase());

      return includes;
    });

    this.filteredOldEvents = this.oldEvents.filter((e) => {
      let includes = false;

      if (e.name) includes = includes || e.name.toLowerCase().includes(filter.toLowerCase());
      if (e.contact) includes = includes || e.contact.toLowerCase().includes(filter.toLowerCase());
      if (e.contactMail) includes = includes || e.contactMail.toLowerCase().includes(filter.toLowerCase());
      if (e.location) includes = includes || this.locationName(e.location).toLowerCase().includes(filter.toLowerCase());
      if (e.equipment) includes = includes || e.equipment.toLowerCase().includes(filter.toLowerCase());
      if (e.description) includes = includes || e.description.toLowerCase().includes(filter.toLowerCase());

      return includes;
    });
  }

  newEvent() {
    const dialogRef = this.dialog.open(EventEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { event: new TAGEvent(), locations: this.locations },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadEvents();
    });
  }

  editEvent(event: TAGEvent) {
    const dialogRef = this.dialog.open(EventEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { event: event, locations: this.locations },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadEvents();
    });
  }

  selectEvent(event: TAGEvent) {
    console.log("select");

    this.selectedEvent = event;
    this.eventFinances = [];
    this.eventInventory = [];
    this.eventCategory = null;
    this.eventImages = [];

    this.api.getCategoryForEvent(event._id).then((result) => {
      this.eventCategory = result;

      this.api.getImages(this.eventCategory._id).then((result) => {
        this.eventImages = result.filter((i) => i.isPublic);
      }).catch((reason) => {
        console.log(reason);
      });
    }).catch((reason) => {

    });

    this.api.getFinanceTransactionsByEvent(event._id).then((result) => {
      this.eventFinances = result.sort((a, b) => {
        return b.date.getTime() - a.date.getTime();
      });
    }).catch((reason) => {
      console.log(reason);
    });

    this.api.getInvTransactionsByEvent(event._id).then((result) => {
      this.eventInventory = result.sort((a, b) => {
        return b.startdate.getTime() - a.startdate.getTime();
      });
    }).catch((reason) => {
      console.log(reason);
    });
  }

  reloadEvents() {
    this.api.getLocations().then((result) => {
      this.locations = result;

      this.api.getEvents(false).then((result) => {
        this.events = result.filter((e) => e.enddate.getTime() >= this.today.getTime());
        this.oldEvents = result.filter((e) => e.enddate.getTime() < this.today.getTime());

        this.events = this.events.sort((a, b) => {
          return a.startdate.getTime() - b.startdate.getTime();
        });

        this.oldEvents = this.oldEvents.sort((a, b) => {
          return b.startdate.getTime() - a.startdate.getTime();
        });

        this.filteredEvents = this.events;
        this.filteredOldEvents = this.oldEvents;

        if (this.selectedEvent != null) {
          this.selectEvent(result.find((e) => e._id == this.selectedEvent._id));
        }
      }).catch((reason) => {
        //TODO: Show Error
      });
    }).catch((reason) => {
      //TODO: Show Error
    });

    this.api.getUsers().then((result) => {
      this.users = result;
    }).catch((reason) => {
      console.error(reason);
    });
  }

  openImageDialog(img: TAGImage): void {
    this.dialog.open(ImagedialogComponent, {
      width: '90%',
      data: {image: img, link: this.getImageLink(img._id)},
      panelClass: 'image-dialog-container',
    });
  }

  openDocument(transactionId: string, fileName: string) {
    this.api.getFinanceTransactionAttachment(transactionId, fileName).then((result) => {
      var pdfUrl = window.URL.createObjectURL(result);

      var link = document.createElement("a");
      link.download = fileName;
      link.href = pdfUrl;

      window.open(pdfUrl, "_blank");
    }).catch((reason) => {
      console.log(reason);
    });
  }

  newFinanceTransaction() {
    let newTrans = new TAGTransaction();
    newTrans.event = this.selectedEvent._id;

    const dialogRef = this.dialog.open(FinanceTransactionEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { transaction: newTrans, users: this.users, events: this.events.concat(this.oldEvents) },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadEvents();
    });
  }

  editFinanceTransaction(transaction: TAGTransaction) {
    const dialogRef = this.dialog.open(FinanceTransactionEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { transaction: transaction, users: this.users, events: this.events.concat(this.oldEvents) },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadEvents();
    });
  }

  deleteFinanceTransaction(transaction: TAGTransaction) {
    openDeleteDialog(this.dialog, "Transaction", [transaction.reason]).afterClosed().subscribe((result) => {
      if (result) {
        this.api.deleteFinanceTransaction(transaction._id).then((result) => {
          this.reloadEvents();
        }).catch((reason) => {
          console.log(reason);
        });
      }
    });
  }

  startInvoice() {
    this.router.navigate(["/internal/invoice"], { state:{
      reason: this.selectedEvent.name,
      //date: this.selectedEvent.enddate,
      user: this.api.user.username,
      eventid: this.selectedEvent._id,
      availableItems: this.eventFinances.filter((t) => !t.isIncome)
    }});
  }

  openBookingScanDialog() {
    const dialogRef = this.dialog.open(ItemScanDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: {  },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);

      if (result.length > 0) {
        this.openBookingDialog(result);
      }
    });
  }

  openBookingDialog(instances: ItemInstanceCombo[]) {
    const dialogRef = this.dialog.open(ItemBookDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: { instances: instances, eventid: this.selectedEvent._id, transactionName: this.selectedEvent.name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);

      if (result) {
        this.api.startTransaction(result).then((result) => {
          this.reloadEvents();
        }).catch((reason) => {
          console.log(reason);
        });
      }
    });
  }

  openBookingImportDialog() {
    const dialogRef = this.dialog.open(InvTransactionImportDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: {  },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);

      if (result != undefined) {
        let transaction: TAGInvTransaction = result;
        transaction.event = this.selectedEvent._id;

        let processingStep: ProgressDialogStep = {
          state: ProgressDialogStepState.PROCESSING,
          title: "Processing",
        }

        const progressDialog = openProgressDialog(this.dialog, [processingStep]);

        this.api.updateTransaction(transaction).then((result) => {
          processingStep.state = ProgressDialogStepState.SUCCESS;
          progressDialog.componentInstance.finishDialog();
          this.reloadEvents();
        }).catch((reason) => {
          processingStep.state = ProgressDialogStepState.ERROR;
          processingStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
        });
      }
    });
  }

  openNewMail(mail: string) {
    this.router.navigate(["/internal/mail/new"], { state: { to: [mail], subject: this.selectedEvent.name } });
  }
}
