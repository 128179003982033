<div id="oauth-content">
  <div *ngIf="loading && !error" id="loading">{{ "general.loading" | translate }}</div>
  <div *ngIf="error" id="error">
    <div id="error-icon-wrapper">
      <mat-icon id="error-icon">error_outlined</mat-icon>
    </div>
    <div id="error-text">{{ error }}</div>
    <div id="error-btn-wrapper">
      <button mat-raised-button color="primary" (click)="goBack()">{{ "general.back" | translate }}</button>
    </div>
  </div>
  <div *ngIf="!loading && !error && loggedIn()" id="oauth-consent-wrapper">
    <div id="oauth-consent-title">
      {{ "oauth.consent.title" | translate }}
    </div>

    <div id="oauth-consent-text">
      {{ "oauth.consent.text" | translate: { application: clientName } }}
    </div>

    <div id="oauth-consent-user">
      <div id="oauth-consent-user-icon-wrapper">
        <mat-icon id="oauth-consent-user-icon">person</mat-icon>
      </div>
      <div id="oauth-consent-user-text">
        {{ username() }}
      </div>
      <button mat-icon-button id="oauth-consent-user-btn" (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>

    <div id="oauth-consent-buttons">
      <button id="btn-accept" mat-stroked-button (click)="accept()">{{ "oauth.consent.acceptBtn" | translate }}</button>
      <button id="btn-decline" mat-stroked-button (click)="decline()">{{ "oauth.consent.declineBtn" | translate }}</button>
    </div>
  </div>
  <div *ngIf="!loading && !error && !loggedIn()" id="oauth-login-wrapper">
    <form [formGroup]="loginform" (ngSubmit)="login()">
      <div id="oauth-login-title">
        {{ "login.title" | translate }}
      </div>

      <mat-form-field class="loginformfield">
        <input id="input_username" matInput placeholder="{{ 'login.username' | translate }}" formControlName="username" required />
      </mat-form-field>

      <mat-form-field class="loginformfield">
        <input id="input_password" matInput placeholder="{{ 'login.password' | translate }}" type="password" formControlName="password" required />
      </mat-form-field>

      <div id="oauth-login-error" class="alert alert-danger" *ngIf="formError">
        {{ formError | translate }}
      </div>

      <button id="login-button" mat-stroked-button type="submit" [disabled]="!loginform.valid">{{ "login.login" | translate }}</button>
    </form>
  </div>
</div>
