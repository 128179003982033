import { DBModel } from "./DBModel";

export class TAGTransaction extends DBModel {
    reason: string = "";
    amount: number = 0;
    date: Date = new Date();
    user: string = "";
    event: string = undefined;
    isIncome: boolean = false;
    pending: boolean = false;

    notes: string = "";

    invoiceNumber: number = undefined;
    invoiceFileName: string = undefined;

    files: {fileName: string, mimeType: string}[] = [];

    constructor(info: any = {}) {
        super();
        this.deserialize(info);
    }

    serialize() {
        var obj: any = super.serialize();

        obj.reason = this.reason;
        obj.amount = this.amount;
        obj.date = this.date;
        obj.user = this.user;
        obj.event = this.event;
        obj.isIncome = this.isIncome;
        obj.pending = this.pending;

        obj.notes = this.notes;

        obj.invoiceNumber = this.invoiceNumber;
        obj.invoiceFileName = this.invoiceFileName;

        obj.files = this.files;

        return obj;
    }

    deserialize(data: any) {
        if (data._id != undefined) this._id = data._id;
        if (data._rev != undefined) this._rev = data._rev;

        if (data.reason != null) this.reason = data.reason;
        if (data.amount != null) this.amount = typeof data.amount == "string" ? parseFloat(data.amount) : data.amount;
        if (data.date != null) this.date = new Date(data.date);
        if (data.user != null) this.user = data.user;
        if (data.event != null) this.event = data.event;
        if (data.isIncome != null) this.isIncome = data.isIncome;
        if (data.pending != null) this.pending = data.pending;

        if (data.notes != null) this.notes = data.notes;

        if (data.invoiceNumber != null) this.invoiceNumber = data.invoiceNumber;
        if (data.invoiceFileName != null) this.invoiceFileName = data.invoiceFileName;

        if (data.files != null) this.files = data.files;

        return this;
    }
}
