import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

  @Input() icon: string = 'info';
  @Input() title: string = 'No data available';
  @Input() subtitle: string = '';
  @Input() buttonText: string = 'Add data';
  @Input() showButton: boolean = false;

  @Output() buttonClick = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  buttonAction() {
    this.buttonClick.emit();
  }
}
