<div id="schulradio-grid">
  <internal-menu-button id="menuBtn"></internal-menu-button>

  <div id="playerState">
    <player-widget
      [playerState]="playerState"
      [showYoutubeLink]="true"
      [showControls]="hasControlPermissions()"
      (onPlayPause)="playPauseSong()"
      (onSkipSong)="skipSong()"></player-widget>
  </div>

  <div id="breakState">
    <div class="title">{{ "internal.radio.breakInformation" | translate }}</div>

    <div id="breakStateFlex">
      <div *ngIf="!breakState.enabled && !breakState.break" style="display: contents">
        <div id="breakInfo">
          <div class="breakText">{{ "internal.radio.breaksDisabled" | translate }}</div>
        </div>
        <button *ngIf="hasBreakPermissions()" mat-stroked-button color="warn" (click)="forceStartBreak()">{{ "internal.radio.startBreak" | translate }}</button>
      </div>
      <div *ngIf="breakState.enabled || breakState.break" style="display: contents">
        <div *ngIf="breakState.break" style="display: contents">
          <div id="breakInfo">
            <div class="breakText">{{ "internal.radio.breakCurrently" | translate }}</div>
            <div class="breakText">
              {{ "internal.radio.breakTimeRemaining" | translate }} <span>{{ breakState.remaining | formatTime }}</span>
            </div>
            <div class="breakText">
              {{ "internal.radio.nextBreak" | translate }} <span>{{ nextBreakRemaining() | formatTime }} ({{ breakState.nextBreak | date: "dd.MM.yyyy HH:mm" }})</span>
            </div>
          </div>
          <button *ngIf="hasBreakPermissions()" mat-stroked-button color="warn" (click)="forceEndBreak()">{{ "internal.radio.stopBreak" | translate }}</button>
        </div>
        <div *ngIf="!breakState.break" style="display: contents">
          <div id="breakInfo">
            <div class="breakText">{{ "internal.radio.noBreakCurrently" | translate }}</div>
            <div class="breakText">
              {{ "internal.radio.nextBreak" | translate }} <span>{{ nextBreakRemaining() | formatTime }} ({{ breakState.nextBreak | date: "dd.MM.yyyy HH:mm" }})</span>
            </div>
          </div>
          <button *ngIf="hasBreakPermissions()" mat-stroked-button color="warn" (click)="forceStartBreak()">{{ "internal.radio.startBreak" | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="breakState.break" id="playlistState">
    <div id="nextSong">
      <div class="title">{{ "internal.radio.nextSong" | translate }}</div>

      <div class="song-item">
        <div id="song-title">
          {{ (playerState.nextSong.title != "" ? playerState.nextSong.title : "internal.radio.noNextSong") | translate }}
        </div>
        <div id="song-artist">
          {{ playerState.nextSong.artist }}
        </div>
      </div>
    </div>
    <div id="lists">
      <div id="playlist" class="list">
        <div class="listTitle">{{ "internal.radio.playlist" | translate }}</div>

        <div class="song-item" style="margin-bottom: 8px" *ngFor="let song of playlist | slice: 1">
          <div id="song-title" [ngClass]="{ dark: song.isVote }">
            {{ (song.isVote && song.title == "" ? "internal.radio.openForVoting" : song.title) | translate }}
          </div>
          <div id="song-artist">
            {{ song.isVote && song.artist == "" ? "-" : song.artist }}
          </div>
        </div>
      </div>
      <div class="listSeparator"></div>
      <div id="votes" class="list">
        <div class="listTitle">{{ "internal.radio.votes" | translate }}</div>

        <div class="songwish-item" style="margin-bottom: 8px" *ngFor="let song of votelist">
          <div class="song-item">
            <div id="song-title">
              {{ song.title != "" ? song.title : ("internal.radio.noNextSong" | translate) }}
            </div>
            <div id="song-artist">
              {{ song.artist }}
            </div>
          </div>
          <div id="song-votes">
            {{ song.upvotes - song.downvotes }} <span>{{ "internal.radio.votes" | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button *ngIf="hasPlayerPermissions()" mat-stroked-button color="warn" style="width: fit-content; margin-left: 10px; margin-bottom: 10px" (click)="updatePlayer()">
    {{ "internal.radio.updatePlayer" | translate }}
  </button>
</div>
