<div id="documents-content">
    <input hidden type="file" accept=".jpg,.png,.jpeg,.pdf" #fileInput (change)="uploadFile($event.target.files)" />
    <div id="documents-list">
        <div *ngFor="let f of documents" class="document">
            <div class="document-embed">
            <embed *ngIf="f.mimeType == 'application/pdf'" width="100" height="100" name="plugin" [src]="fileUrl(f.fileName) | useHttpImgSrc" type="application/pdf"/>
            <img *ngIf="f.mimeType != 'application/pdf'" width="100" height="100" [src]="fileUrl(f.fileName) | useHttpImgSrc" />
            </div>

            <div class="document-actions">
            <button *ngIf="showDelete" mat-icon-button color="warn" (click)="deleteFile(f.fileName)"><mat-icon>delete</mat-icon></button>
            <button *ngIf="showView" mat-icon-button (click)="downloadDoc(f.fileName, false)"><mat-icon>visibility</mat-icon></button>
            <button *ngIf="showDownload" mat-icon-button (click)="downloadDoc(f.fileName, true)"><mat-icon>file_download</mat-icon></button>
            </div>
        </div>

        <div *ngIf="showUpload" id="documents-add" class="document" (click)="fileInput.click()">
            <mat-icon>upload</mat-icon>
        </div>
    </div>
</div>