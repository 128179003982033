<div id="hometitle">Technik AG FLG</div>

<div id="home-wrapper">
  <mat-card class="homecard">
    <img mat-card-image src="{{ this.getRandomImageLink() }}" alt="" />
    <mat-card-header>
      <!-- TODO: Placeholder Bars and Loading for Image-->
      <mat-card-title>
        <placeholder *ngIf="!this.randomimage" style="height: 24px; width: 150px"></placeholder>
        {{ this.randomimage ? this.randomimage.title : "" }}<span>{{ this.randomimage ? this.randomimage.category : "" }}</span>
      </mat-card-title>

      <mat-card-subtitle>
        <placeholder *ngIf="!this.randomimage" style="height: 17px; width: 150px"></placeholder>
        {{ this.randomimage ? this.randomimage.description : "" }}
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <placeholder *ngIf="!this.randomimage" style="height: 17px; width: 150px"></placeholder>
      {{(this.randomimage)? this.randomimage.description : ''}}
    </mat-card-content>

    <mat-card-actions>
      <button mat-button routerLink="/gallery" routerLinkActive="active">{{ "home.gallery.gotogallery" | translate }}</button>
    </mat-card-actions>
  </mat-card>

  <mat-card class="homecard">
    <mat-card-title>{{ "home.events.title" | translate }}</mat-card-title>
    <mat-card-subtitle>{{ "home.events.description" | translate }}</mat-card-subtitle>

    <mat-card-content>
      <div class="event-list">
        <div *ngIf="events.length == 0" id="event-list-empty">
          <div>{{ "home.events.noevents" | translate }}</div>
        </div>

        <div *ngFor="let event of events" style="display: contents;">
          <event-item [event]="event" [location]="locationName(event.location)" (eventClicked)="openEventDetails(event._id)"></event-item>
          <div class="spacer"></div>
        </div>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <button mat-button routerLink="/events" routerLinkActive="active">
        {{ "home.events.gotoevents" | translate }}
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-card class="homecard">
    <img mat-card-image src="./assets/images/aboutUsImage.jpg" alt="about-us" />

    <div class="about-us-image-caption-first">{{ "home.aboutus.imgtext1" | translate }}</div>
    <div class="about-us-image-caption-second">{{ "home.aboutus.imgtext2" | translate }}</div>

    <mat-card-header>
      <mat-card-title>{{ "home.aboutus.title" | translate }}</mat-card-title>
      <mat-card-subtitle>{{ "home.aboutus.description" | translate }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-actions>
      <button mat-button routerLink="/aboutus" routerLinkActive="active">
        {{ "home.aboutus.gotoaboutus" | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
