<h1 mat-dialog-title>{{ "general.dialogs.delete.title" | translate:data }}</h1>

<div mat-dialog-content>
  <h3>{{ "general.dialogs.delete.message" | translate:data }}</h3>

  <ul>
    <li *ngFor="let object of data.objects">{{object}}</li>
  </ul>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>{{ "general.no" | translate }}</button>
  <button mat-button [mat-dialog-close]="true" color="warn">{{ "general.yes" | translate }}</button>
</div>
