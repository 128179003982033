import { Serializable } from "./ISerializeable";

export class TAGMail implements Serializable {
  uid: number = -1;
  messageID: string = "";

  threadID: string = "";
  labels: string[] = [];

  date: Date = new Date();
  subject: string = "";
  headers: { key: string; value: string }[] = [];

  flags: string[] = [];

  from: TAGMailAddress = new TAGMailAddress({});
  to: TAGMailAddress = new TAGMailAddress({});
  cc: TAGMailAddress = new TAGMailAddress({});
  replyTo: TAGMailAddress = new TAGMailAddress({});

  html: string = "";
  text: string = "";
  textAsHtml: string = "";

  attachments: TAGMailAttachment[] = [];

  constructor(info: any = {}) {
    this.deserialize(info);
  }

  deserialize(data: any) {
    this.uid = data.uid ?? -1;
    this.messageID = data.messageID ?? "";

    this.threadID = data.threadID ?? "";
    this.labels = data.labels ?? [];

    this.date = data.date? new Date(data.date) : new Date();
    this.subject = data.subject ?? "";
    this.headers = data.headers ?? [];

    this.flags = data.flags ?? [];

    this.from = data.from? new TAGMailAddress(data.from) : new TAGMailAddress({});
    this.to = data.to? new TAGMailAddress(data.to) : new TAGMailAddress({});
    this.cc = data.cc? new TAGMailAddress(data.cc) : new TAGMailAddress({});
    this.replyTo = data.replyTo? new TAGMailAddress(data.replyTo) : new TAGMailAddress({});

    this.html = data.html ?? "";
    this.text = data.text ?? "";
    this.textAsHtml = data.textAsHtml ?? "";

    this.attachments = data.attachments?.map((a: any) => new TAGMailAttachment(a)) ?? [];
  }

  serialize() {
    return {
      uid: this.uid,
      messageID: this.messageID,

      threadID: this.threadID,
      labels: this.labels,

      date: this.date,
      subject: this.subject,
      headers: this.headers,

      flags: this.flags,

      from: this.from.serialize(),
      to: this.to.serialize(),
      cc: this.cc.serialize(),
      replyTo: this.replyTo.serialize(),

      html: this.html,
      text: this.text,
      textAsHtml: this.textAsHtml,

      attachments: this.attachments.map((a) => a.serialize()),
    };
  }
}

export class TAGMailAddress implements Serializable {
  addresses: { address: string; name: string }[] = [];
  text: string = "";
  html: string = "";

  constructor(info: any = {}) {
    this.deserialize(info);
  }

  serialize() {
    return { text: this.text, html: this.html, addresses: this.addresses };
  }

  deserialize(data: any) {
    this.text = data.text ?? "";
    this.html = data.html ?? "";
    this.addresses = data.addresses ?? [];
  }
}

export class TAGMailAttachment implements Serializable {
  fileName: string = "";
  contentType: string = "";
  size: number = 0;
  content: ArrayBuffer = null;

  constructor(info: any = {}) {
    this.deserialize(info);
  }

  serialize() {
    return { fileName: this.fileName, contentType: this.contentType, size: this.size, content: this.content };
  }

  deserialize(data: any) {
    this.fileName = data.fileName ?? "";
    this.contentType = data.contentType ?? "";
    this.size = data.size ?? 0;
    this.content = data.content ?? null;
  }
}

export class TAGMailContact implements Serializable {
  firstname: string = "";
  lastname: string = "";
  email: string = "";

  constructor(info: any = {}) {
    this.deserialize(info);
  }

  serialize() {
    return { firstname: this.firstname, lastname: this.lastname, email: this.email };
  }

  deserialize(data: any) {
    this.firstname = data.firstname ?? "";
    this.lastname = data.lastname ?? "";
    this.email = data.email ?? "";
  }
}

export class TAGMailFolder implements Serializable {

  name: string = "";
  path: string = "";

  delimiter: string = ".";
  flags: string[] = [];
  specialUseAttribs: string = "";

  children: TAGMailFolder[] = [];

  constructor(info: any = {}) {
    this.deserialize(info);
  }

  deserialize(object: any) {
    this.name = object.name ?? "";
    this.path = object.path ?? "";

    this.delimiter = object.delimiter ?? ".";
    this.flags = object.flags ?? [];
    this.specialUseAttribs = object.specialUseAttribs ?? "";

    this.children = object.children?.map(c => new TAGMailFolder(c)) ?? [];

    return this;
  }

  serialize() {
    return {
      name: this.name,
      path: this.path,

      delimiter: this.delimiter,
      flags: this.flags,
      specialUseAttribs: this.specialUseAttribs,

      children: this.children.map(c => c.serialize())
    }
  }

}
