import { DBModel } from "./DBModel";

export class TAGInvItemType extends DBModel {
  deleted: boolean = false;

  category: string = "";

  name: string = "";
  description: string = "";

  additionalInfo: string = "";

  instances: TAGInvItemInstance[] = [];

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  findInstance(idOrCode: string) {
    return this.instances.find((instance) => instance.itemid == idOrCode || instance.barcodes.includes(idOrCode));
  }

  serialize() {
    var obj: any = super.serialize();

    obj.deleted = this.deleted;

    obj.category = this.category;

    obj.name = this.name;
    obj.description = this.description;

    obj.additionalInfo = this.additionalInfo;

    obj.instances = this.instances.map((instance) => instance.serialize());

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;
    if (data.deleted != undefined) this.deleted = data.deleted;

    if (data.category != null) this.category = data.category;

    if (data.name != null) this.name = data.name;
    if (data.description != null) this.description = data.description;

    if (data.additionalInfo != null) this.additionalInfo = data.additionalInfo;

    if (data.instances != null) this.instances = data.instances.map((instance) => new TAGInvItemInstance(instance));

    return this;
  }
}

export class TAGInvItemInstance extends DBModel {
  deleted: boolean = false;

  barcodes: string[] = [];

  itemid: string = "";
  createdate: Date = new Date();

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  serialize() {
    var obj: any = super.serialize();

    obj.deleted = this.deleted;

    obj.barcodes = this.barcodes;

    obj.itemid = this.itemid;
    obj.createdate = this.createdate;

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;
    if (data.deleted != undefined) this.deleted = data.deleted;

    if (data.barcodes != null) this.barcodes = data.barcodes;

    if (data.itemid != null) this.itemid = data.itemid;
    if (data.createdate != null) this.createdate = new Date(data.createdate);

    return this;
  }
}

export class TAGInvTransaction extends DBModel {
  location: string = "";
  user: string = "";
  event: string = "";

  startdate: Date = new Date();
  enddate: Date = null;

  items: { itemid: string; instanceid: string; back: Date }[] = [];

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  serialize() {
    var obj: any = super.serialize();

    obj.location = this.location;
    obj.user = this.user;
    obj.event = this.event;

    obj.startdate = this.startdate;
    obj.enddate = this.enddate;

    obj.items = this.items;

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.location != null) this.location = data.location;
    if (data.user != null) this.user = data.user;
    if (data.event != null) this.event = data.event;

    if (data.startdate != null) this.startdate = new Date(data.startdate);
    if (data.enddate != null) this.enddate = new Date(data.enddate);

    if (data.items != null)
      this.items = data.items.map((item) => {
        return { itemid: item.itemid, instanceid: item.instanceid, back: item.back ? new Date(item.back) : null };
      });

    return this;
  }

  containsItemInstance(itemid: string, instanceid: string) {
    return this.items.findIndex((item) => item.itemid == itemid && item.instanceid == instanceid) != -1;
  }

  containsItem(itemid: string) {
    return this.items.findIndex((item) => item.itemid == itemid) != -1;
  }

  findItem(itemid: string, instanceid: string) {
    return this.items.find((item) => item.itemid == itemid && item.instanceid == instanceid);
  }

  allItemsBack() {
    return this.items.every((item) => item.back != null);
  }
}

export class TAGInvItemGroup extends DBModel {
  deleted: boolean = false;

  name: string = "";
  items: {itemid: string, instanceid: string}[] = [];

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  serialize() {
    var obj: any = super.serialize();

    obj.deleted = this.deleted;

    obj.name = this.name;
    obj.items = this.items;

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.deleted != undefined) this.deleted = data.deleted;

    if (data.name != null) this.name = data.name;

    if (data.items != null)
      this.items = data.items.map((item) => {
        return { itemid: item.itemid, instanceid: item.instanceid, back: item.back ? new Date(item.back) : null };
      });

    return this;
  }

  containsItemInstance(itemid: string, instanceid: string) {
    return this.items.findIndex((item) => item.itemid == itemid && item.instanceid == instanceid) != -1;
  }

  containsItem(itemid: string) {
    return this.items.findIndex((item) => item.itemid == itemid) != -1;
  }
}