import { Component, Inject } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map, Observable, startWith } from "rxjs";
import { ApiService } from "../api.service";
import { openProgressDialog, ProgressDialogStep, ProgressDialogStepState } from "../dialogs/progressDialog/progressDialog";
import { TAGUser } from "../models/taguser";
import { Permission } from "../models/tagpermission";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { Priority, TAGTodo, TodoStatus } from "../models/tagtodo";

export interface TodoEditDialogData {
  todo: TAGTodo;
  users: TAGUser[];
}

@Component({
  selector: "int-todo-editdialog",
  templateUrl: "todoEditDialog.html",
  styleUrls: ["./todoEditDialog.scss"],
})
export class TodoEditDialog {

  userControl = new UntypedFormControl("");
  users: TAGUser[] = [];
  filteredUsers: Observable<TAGUser[]>;

  todo: TAGTodo = new TAGTodo();

  today = new Date();
  max = new Date();

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TodoEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TodoEditDialogData
  ) {
    this.todo = new TAGTodo(Object.assign({}, data.todo.serialize()));
    this.users = data.users;

    this.max.setDate(this.today.getDate() + 365);

    let user = this.users.find((user) => user._id == this.todo.assignee);
    if (user) {
      this.todo.assignee = user.fullname;
    }

    this.dialogRef.disableClose = true;
  }

  allStatus() {
    return TodoStatus.ALL;
  }

  allPriorities() {
    return Priority.ALL;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value || ""))
      );
    });
  }

  private _filter(value: string): TAGUser[] {
    const filterValue = value.toLowerCase();
    console.log(filterValue);
    console.log(this.users);
    return this.users.filter((u) => (u.username + u.firstname + u.lastname + u.email + u.schoolclass).toLowerCase().includes(filterValue));
  }

  cancelTodo(): void {
    this.dialogRef.close(null);
  }

  deleteTodo() {
    if (this.todo._id == undefined) return;

    openDeleteDialog(this.dialog, "Todo", [this.todo.description])
      .afterClosed()
      .subscribe((result) => {
        if (result)
          this.api.deleteTodo(this.todo._id).then((result) => {
            this.dialogRef.close({ deleted: true });
          }).catch((reason) => {
            //TODO: Show Error
          });
      });
  }

  saveTodo() {
    console.log(this.todo);

    let user = this.users.find((user) => user.fullname == this.todo.assignee);
    if (user) {
      this.todo.assignee = user._id;
    }

    // Initialize Progress Dialog
    let processingStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Processing",
    }

    const progressDialog = openProgressDialog(this.dialog, [processingStep]);

    progressDialog.afterOpened().subscribe(() => {
      processingStep.state = ProgressDialogStepState.PROCESSING;

      if (this.todo._id != undefined) {
        this.api.updateTodo(this.todo).then((result) => {
          processingStep.state = ProgressDialogStepState.SUCCESS;
          progressDialog.componentInstance.finishDialog();
          this.dialogRef.close({ deleted: false });
        }).catch((reason) => {
          processingStep.state = ProgressDialogStepState.ERROR;
          processingStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
          console.log(reason);
        });
      } else {
        //TODO: Form and verification
        if (this.todo.description == "") {
          return;
        }

        this.api.newTodo(this.todo).then((result) => {
          processingStep.state = ProgressDialogStepState.SUCCESS;
          progressDialog.componentInstance.finishDialog();
          this.dialogRef.close({ deleted: false });
        }).catch((reason) => {
          processingStep.state = ProgressDialogStepState.ERROR;
          processingStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
          console.log(reason);
        });
      }
    });
  }
}
