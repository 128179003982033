import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { TAGImageCategory } from "../models/tagimagecategory";
import { TAGImage } from "../models/tagimage";
import { TAGEvent } from "../models/tagevent";
import { Permission } from "../models/tagpermission";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { openProgressDialog, ProgressDialogStep, ProgressDialogStepState } from "../dialogs/progressDialog/progressDialog";

@Component({
  selector: "app-internal.gallery",
  templateUrl: "./internal.gallery.component.html",
  styleUrls: ["./internal.gallery.component.scss"],
})
export class InternalGalleryComponent implements OnInit {
  /**
   * TODO:
   * - Complete rework
   * - Error for Files larger than 30mb
   */

  catPageSize: number = 25;
  catCurrentPage: number = 0;
  displayedColumnsCategory: string[] = ["categoryname", "open"];
  dataSourceCategory: MatTableDataSource<TAGImageCategory>;

  imgPageSize: number = 25;
  imgCurrentPage: number = 0;
  displayedColumnsImage: string[] = ["imagePreview", "imagetitle", "imagedescription"];
  dataSourceImage: MatTableDataSource<TAGImage>;

  categories: TAGImageCategory[] = [];
  images: TAGImage[] = [];

  events: TAGEvent[] = [];

  selectedCategory: string = undefined;
  editCategory: boolean = false;
  selectedCategoryInfo: TAGImageCategory = null;

  selectedImage: string = undefined;
  selectedImageInfo: TAGImage = null;
  imageReupload: boolean = false;

  imageFile: File;
  imageData: string;

  previews: Map<string, string> = new Map<string, string>();

  catSwitchPage(event) {
    this.catCurrentPage = event.pageIndex;
    this.catPageSize = event.pageSize;

    var startIndex = this.catCurrentPage * this.catPageSize;
    this.dataSourceCategory = new MatTableDataSource(this.categories.slice(startIndex, startIndex + this.catPageSize));
  }

  imgSwitchPage(event) {
    this.imgCurrentPage = event.pageIndex;
    this.imgPageSize = event.pageSize;

    var startIndex = this.imgCurrentPage * this.imgPageSize;
    this.dataSourceImage = new MatTableDataSource(this.images.slice(startIndex, startIndex + this.imgPageSize));
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  getImageLink(imageid: string) {
    if (this.selectedImage == "new" || this.imageReupload) {
      if (this.imageFile != null) {
        return this.imageData;
      } else {
        return "";
      }
    } else return this.api.getImageLink(imageid);
  }

  getImageThumbLink(imageid: string) {
    return this.api.getThumbLink(imageid);
  }

  getImageThumb(imageid: string) {
    if (this.previews.has(imageid)) return this.previews.get(imageid);

    return new Promise<string>((resolve, reject) => {
      this.api.getImageThumb(imageid).then((result) => {
        this.previews.set(imageid, result);
        resolve(result);
      }).catch((reason) => {
        reject(reason);
      });
    });
  }

  constructor(public api: ApiService, public dialog: MatDialog) {}

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      if (this.hasPerm("edit_category")) this.displayedColumnsCategory.splice(1, 0, "edit");

      this.reloadCategories();
      this.api.getEvents(false).then((result) => {
        this.events = result;
      }).catch((reason) => {
        console.error(reason);
      });
    });
  }

  uploadFile($event) {
    console.log($event);

    const reader = new FileReader();
    reader.onloadend = () => {
      this.imageFile = $event[0];
      this.imageData = reader.result as string;

      if (this.selectedImage != "new") {
        this.imageReupload = true;
      }
    };
    reader.readAsDataURL($event[0]);
  }

  deleteObject() {
    if (this.editCategory) {
      if (this.selectedCategory == "new" || this.selectedCategory == undefined) return;

      openDeleteDialog(this.dialog, "Category", [this.selectedCategoryInfo.title])
        .afterClosed()
        .subscribe((result) => {
          // TODO: Check for Images in Category
          if (result)
            this.api.deleteCategory(this.selectedCategory).then((result) => {
              this.cancelEdit();
            }).catch((reason) => {
            //TODO: Show Error
            });
        });
    } else if (this.selectedImage != undefined) {
      if (this.selectedImage == "new") return;

      openDeleteDialog(this.dialog, "Image", [this.selectedImageInfo.title])
        .afterClosed()
        .subscribe((result) => {
          if (result)
            this.api.deleteImage(this.selectedImage).then((result) => {
              this.cancelEdit();
            }).catch((reason) => {
              //TODO: Show Error
            });
        });
    }
  }

  newCategory() {
    this.selectedCategoryInfo = new TAGImageCategory();
    this.selectedCategory = "new";
    this.editCategory = true;
  }

  newImage() {
    this.selectedImageInfo = new TAGImage();
    this.selectedImage = "new";
  }

  cancelEdit() {
    if (this.editCategory) {
      this.editCategory = false;
      this.reloadCategories();
      this.selectCategory(this.selectedCategory);
    } else if (this.selectedImage != undefined) {
      this.selectedImage = undefined;
      this.imageFile = null;
      this.imageReupload = false;
      this.reloadImages(this.selectedImageInfo.categoryid);
    }
  }

  saveEdit() {
    if (this.editCategory) {
      if (this.selectedCategory != "new") {
        this.api.updateCategory(this.selectedCategoryInfo).then((result) => {
          this.cancelEdit();
        }).catch((reason) => {
        //TODO: Show Error
        });
      } else {
        console.log("Saving new Category");
        console.log(this.selectedCategoryInfo);
        if (this.selectedCategoryInfo.title == "") {
          return;
        }

        this.selectedCategoryInfo.alias = this.selectedCategoryInfo.title.toLowerCase().replace(" ", "-");

        this.api.newCategory(this.selectedCategoryInfo).then((result) => {
          this.cancelEdit();
        }).catch((reason) => {
        //TODO: Show Error
        });
      }
    } else if (this.selectedImage != undefined) {

      console.log(this.imageReupload ? "has Data" : "no Data")

      let uploadStep: ProgressDialogStep = {
        state: this.imageReupload ? ProgressDialogStepState.WAITING : ProgressDialogStepState.SKIPPED,
        title: "Upload Image",
        showProgress: true,
        progress: 0,
      }

      let processingStep: ProgressDialogStep = {
        state: ProgressDialogStepState.WAITING,
        title: "Processing",
      }

      const progressRef = openProgressDialog(this.dialog, [uploadStep, processingStep]);

      progressRef.afterOpened().subscribe(() => {
        if (this.selectedImage != "new") {
          if (this.imageReupload) {
            uploadStep.state = ProgressDialogStepState.PROCESSING;
          }

          this.api.updateImage(this.selectedImageInfo, this.imageReupload ? this.imageData : null, (progress) => {
            console.log("progress");
            console.log(progress);
            if (this.imageReupload) {
              uploadStep.progress = progress;
              if (progress >= 100) {
                uploadStep.state = ProgressDialogStepState.SUCCESS;
              }
            }

            if (progress >= 100) {
              processingStep.state = ProgressDialogStepState.PROCESSING;
            }
          }).then((result) => {
            console.log("data");
            console.log(result);
            processingStep.state = ProgressDialogStepState.SUCCESS;
            progressRef.componentInstance.finishDialog();
            this.cancelEdit();
          }).catch((reason) => {
            console.log("error");
            console.log(reason);
            processingStep.state = ProgressDialogStepState.ERROR;
            processingStep.subtitle = reason.message;
            progressRef.componentInstance.finishDialog(-1);
          });
        } else {
          uploadStep.state = ProgressDialogStepState.PROCESSING;
          this.api.newImage(this.selectedImageInfo, this.imageData, (progress) => {
            console.log("progress");
            console.log(progress);
            uploadStep.progress = progress;
            if (progress >= 100) {
              uploadStep.state = ProgressDialogStepState.SUCCESS;
              processingStep.state = ProgressDialogStepState.PROCESSING;
            }
          }).then((result) => {
            console.log("data");
            console.log(result);
            processingStep.state = ProgressDialogStepState.SUCCESS;
            progressRef.componentInstance.finishDialog();
            this.cancelEdit();
          }).catch((reason) => {
            console.log("error");
            console.log(reason);
            processingStep.state = ProgressDialogStepState.ERROR;
            processingStep.subtitle = reason.message;
            progressRef.componentInstance.finishDialog(-1);
          });
        }
      });
    }
  }

  doEditCategory(category: string) {
    this.selectCategory(category);
    this.editCategory = true;
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
    console.log("Selecting Category: " + category);
    this.categories.forEach((cat) => {
      if (cat._id == category) {
        this.selectedCategoryInfo = cat;
      }
    });

    this.reloadImages(this.selectedCategoryInfo._id);
  }

  selectImage(image: string) {
    if (!this.hasPerm("edit_image")) return;

    this.selectedImage = image;
    console.log("Selecting Image: " + image);
    this.images.forEach((img) => {
      if (img._id == image) {
        this.selectedImageInfo = img;
        this.api.getImageFull(img._id).then((result) => {
          this.imageData = result;
        }).catch((reason) => {
          console.log(reason);
        });
      }
    });
  }

  reloadCategories() {
    this.categories = [];
    this.catSwitchPage({ pageIndex: 0, pageSize: this.catPageSize });

    this.api.getCategories().then((result) => {
      this.categories = result;
      this.catSwitchPage({ pageIndex: 0, pageSize: this.catPageSize });
    }).catch((reason) => {
    //TODO: Show Error
    });
  }

  reloadImages(categoryid: string) {
    this.images = [];
    this.imgSwitchPage({ pageIndex: 0, pageSize: this.imgPageSize });

    this.api.getImages(categoryid).then((result) => {
      this.images = result;
      this.imgSwitchPage({ pageIndex: 0, pageSize: this.imgPageSize });
    }).catch((reason) => {
      //TODO: Show Error
    });
  }

  applyFilterCategories(filterValue: string) {
    this.dataSourceCategory.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceCategory.paginator) {
      this.dataSourceCategory.paginator.firstPage();
    }
  }

  applyFilterImages(filterValue: string) {
    this.dataSourceImage.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceImage.paginator) {
      this.dataSourceImage.paginator.firstPage();
    }
  }
}
