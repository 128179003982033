<div id="missingitems-header">
  <div id="transedit-header-title">{{ 'internal.inventory.missingItems.title' | translate }}</div>
</div>

<div id="missingitems-form">
  <div id="missingitems-list">
    <div id="missingitems-list-header">
      <div id="missingitems-list-header-title">{{ 'internal.inventory.missingItems.title' | translate }}</div>

      <div id="missingitems-list-header-actions">
        <button mat-stroked-button (click)="printLabelsAll()" [disabled]="selectedItems.length <= 0">
          {{ 'internal.inventory.printLabels' | translate }}
        </button>
      </div>
    </div>

    <div id="hspacer"></div>

    <div id="missingitems-list-content">
      <div *ngFor="let types of itemTypes" class="item">
        <div class="item-name">{{types.name}}</div>
        <div class="instance-list">
          <div *ngFor="let instance of itemFilter(types._id)" class="instance">
            <mat-checkbox
              [checked]="selectedItems.includes(instance)"
              (change)="instanceSelected(types._id, instance.instanceID, $event.checked)"></mat-checkbox>
            <div class="instance-name">{{types.name}}</div>
            <div class="instance-addInfo">{{types.additionalInfo}}</div>
            <div class="instance-id">{{instance.instanceID}}</div>
            <div class="instance-actions">
              <button mat-icon-button (click)="showTransaction(instance.transaction)"><mat-icon>visibility</mat-icon></button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="itemTypes.length == 0">
        <div class="item">
          <div class="item-name">{{ 'internal.inventory.missingItems.noMissingItems' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="missingitems-actions">
  <button mat-stroked-button (click)="cancel()">{{ 'general.close' | translate }}</button>
</div>
