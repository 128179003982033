<div *ngIf="!hasPerm('kiosk_mode')" id="int-content-topbar">
  <internal-menu-button id="menuBtn"></internal-menu-button>

  <button *ngIf="hasPerm('create_todos')" class="desktop" mat-stroked-button (click)="openTodo()">{{ "internal.todos.newTodo" | translate }}</button>
  <button *ngIf="hasPerm('create_todos')" class="mobile" mat-icon-button (click)="openTodo()"><mat-icon>add</mat-icon></button>

  <mat-form-field id="search-field" appearance="outline" [ngClass]="{'highlight': (highlightedTodos != undefined && !showTodoMenu && !isMoving)}">
    <input #searchInput matInput (keyup)="onFilterKeyUp($event.target.value)" (keydown.enter)="searchInput.blur()" placeholder="{{ 'general.search' | translate }}" />
    <button *ngIf="searchInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchInput.value=''; onFilterKeyUp('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-slide-toggle style="margin: auto 0 auto auto;" [ngModel]="true" (change)="toggleAutoReload($event)"></mat-slide-toggle>
  <button mat-icon-button (click)="reloadTodos()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>

<div id="int-todos-content" (window:keypress)="onKey($event)">
  <div id="swimlanes" cdkDropListGroup>
    <div class="swimlane" #swimlane *ngFor="let state of allStatus()" [cdkDropListAutoScrollDisabled]="false" [cdkDropListAutoScrollStep]="20" cdkDropList [cdkDropListData]="state" (cdkDropListDropped)="drop($event)">
      <div class="sl-header" [style.background-color]="state.color">
        {{ "status." + state.name | translate }}
      </div>

      <div class="todocard"
        *ngFor="let todo of filterByStatus(state); let i = index"
        (click)="openTodo(todo)"
        [ngClass]="{'selected': selectedTodo != undefined? selectedTodo.todo._id == todo._id : false, 'highlight': (selectedTodo?.todo._id == todo._id && showTodoMenu) || (highlightedTodos?.includes(todo._id) ?? false && !showTodoMenu && !isMoving)}"
        [attr.data-todo]="todo._id"
        cdkDrag
        [cdkDragData]="todo"
        (cdkDragStarted)="startDrag($event)"
        (cdkDragEnded)="endDrag($event)">

        <div class="drag-placeholder" *cdkDragPlaceholder></div>
        <div *ngIf="!dragHandleDisabled()" class="drag-handle" cdkDragHandle><mat-icon>open_with</mat-icon></div>

        <div class="todo-priority badge noselect" [style.background-color]="todo.priority.color">{{ "priority." + todo.priority.name | translate }}</div>
        <div *ngIf="todo.assignee" class="todo-assignee right"><mat-icon>account_circle</mat-icon><div>{{userToName(todo.assignee)}}</div></div>
        <div class="todo-description">{{todo.description}}</div>
        <div class="todo-createdate"><mat-icon>calendar_add_on</mat-icon><div>{{todo.createdate | date}}</div></div>
        <div *ngIf="todo.duedate" class="todo-duedate right" [style.color]="duedateWarning(todo.duedate)"><mat-icon>calendar_clock</mat-icon><div>{{todo.duedate | date}}</div></div>

        <div #menu class="todomenu" [ngClass]="{'show': showTodoMenu && selectedTodo?.todo._id == todo._id, 'left': todo.status.name == 'canceled'}">
          <div class="menuitem" [ngClass]="{'selected': selectedMenuItem == 'priority'}">
            <mat-icon>remove</mat-icon>
            {{ "internal.todos.priority" | translate }}
            <mat-icon>add</mat-icon>
          </div>
          <div class="menuitem" [ngClass]="{'selected': selectedMenuItem == 'move'}">
            {{ "internal.todos.move" | translate }}
          </div>

        </div>

        <div class="todotooltip" [ngClass]="{'show': (isMoving || showTodoMenu) && selectedTodo?.todo._id == todo._id, 'bottom': tooltipPos()}">
          <div style="display: contents;" *ngIf="isMoving">
            <div class="tooltipitem">
              <mat-icon>arrow_back</mat-icon>
              4
            </div>
            <div class="tooltipitem">
              <mat-icon>arrow_forward</mat-icon>
              6
            </div>
            <div class="tooltipitem">
              <mat-icon>save</mat-icon>
              5
            </div>
            <div class="tooltipitem">
              <mat-icon>close</mat-icon>
              0
            </div>
          </div>
          <div style="display: contents;" *ngIf="showTodoMenu">
            <div class="tooltipitem">
              <mat-icon>arrow_upward</mat-icon>
              8
            </div>
            <div class="tooltipitem">
              <mat-icon>arrow_downward</mat-icon>
              2
            </div>
            <div class="tooltipitem" *ngIf="selectedMenuItem == 'priority'">
              <mat-icon>remove</mat-icon>
              4
            </div>
            <div class="tooltipitem" *ngIf="selectedMenuItem == 'priority'">
              <mat-icon>add</mat-icon>
              6
            </div>
            <div class="tooltipitem" *ngIf="selectedMenuItem == 'priority'">
              <mat-icon>save</mat-icon>
              5
            </div>
            <div class="tooltipitem" *ngIf="selectedMenuItem == 'move'">
              <mat-icon>keyboard_return</mat-icon>
              5
            </div>
            <div class="tooltipitem">
              <mat-icon>close</mat-icon>
              0
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="menuOverlay" [ngClass]="{'show': showTodoMenu || highlightedTodos != undefined}">

</div>
