import { DBModel } from "./DBModel";

export class TAGPresence extends DBModel {
  startdate: Date = new Date();
  enddate: Date = new Date();

  presenceInfo: { [username: string]: number } = {};

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  getSchoolYear(): number {
    if (this.startdate.getMonth() < 8) {
      return this.startdate.getFullYear() - 1;
    } else {
      return this.startdate.getFullYear();
    }
  }

  getUserColor(user: string, alpha: string = "ff") {
    return TAGPresence.getColor(this.presenceInfo[user], alpha);
  }

  static getColor(value: number, alpha: string = "ff") {
    var red = "#f44336" + alpha;
    var green = "#4caf50" + alpha;
    var greenyellowish = "#CDDC39" + alpha;
    var yellow = "#ffc107" + alpha;
    var blue = "#00bcd4" + alpha;
    var halfredgreen = "linear-gradient(45deg, " + green + " 0%, " + green + " 50%, " + red + " 50%, " + red + " 100%)";

    switch (value ?? -1) {
      case 0: // Nicht anwesend
        return red;
      case 1: // anwesend / nicht getestet
        return halfredgreen;
      case 2: // anwesend / getestet
        return green;
      case 3: // entschuldigt
        return yellow;
      case 4: // legacy anwesend
        return green;
      case 5: // verspätet
        return greenyellowish;
      default: // Kein Wert angegeben
        return "#ffffff33";
    }
  }

  getUserIcon(user: string) {
    return TAGPresence.getIcon(this.presenceInfo[user]);
  }

  static getIcon(value: number) {
    switch (value ?? -1) {
      case 0: // Nicht anwesend
        return "close";
      case 1: // anwesend / nicht getestet
        return "rule"; //check
      case 2: // anwesend / getestet
        return "checklist_rtl";
      case 3: // entschuldigt
        return "coronavirus"; //sick
      case 4: // legacy anwesend
        return "check";
      case 5: // verspätet
        return "schedule";
      default: // Kein Wert angegeben
        return "question_mark";
    }
  }

  serialize(): any {
    var obj: any = super.serialize();

    obj.startdate = this.startdate;
    obj.enddate = this.enddate;

    obj.presenceInfo = this.presenceInfo;

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.startdate != null) this.startdate = new Date(data.startdate);
    if (data.enddate != null) this.enddate = new Date(data.enddate);

    if (data.presenceInfo != null) this.presenceInfo = data.presenceInfo;
  }
}
