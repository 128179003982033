import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../api.service";
import { TAGMail, TAGMailAddress, TAGMailAttachment, TAGMailContact } from "../models/tagmail";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { ProgressDialogStep, ProgressDialogStepState, openProgressDialog } from "../dialogs/progressDialog/progressDialog";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-internal.email.edit",
  templateUrl: "./internal.email.edit.component.html",
  styleUrls: ["./internal.email.edit.component.scss"],
})
export class InternalEmailEditComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  @ViewChild("contactInput") contactInput: ElementRef<HTMLInputElement>;
  @ViewChild("ccInput") ccInput: ElementRef<HTMLInputElement>;

  contactCtrl = new FormControl("");
  ccCtrl = new FormControl("");
  contacts: TAGMailContact[] = [];
  filteredContacts: Observable<TAGMailContact[]>;

  routeState: any = null;
  mail: TAGMail;

  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    console.log(this.router.getCurrentNavigation().extras.state);
    if (this.router.getCurrentNavigation().extras.state) {
      this.routeState = this.router.getCurrentNavigation().extras.state;
    }

    this.filteredContacts = this.contactCtrl.valueChanges.pipe(
      startWith(""),
      map((state) => (state ? this._filter(state) : this.contacts.slice()))
    );
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.api.getContacts().then((result) => {
        this.contacts = result;
      }).catch((reason) => {
        console.log(reason);
      });

      this.mail = new TAGMail();

      this.mail.from = new TAGMailAddress({
        addresses: [{ name: this.api.user.firstname + " " + this.api.user.lastname, address: this.api.user.technikEmail }],
      });

      if (this.routeState) {
        if (this.routeState.mail && this.routeState.action) {
          if (this.routeState.action == "reply") {
            this.mail.to = this.routeState.mail.from;
            this.mail.subject = "Re: " + this.routeState.mail.subject;

            let fromMail = this.routeState.mail.to.addresses.find((addr) => addr.address == "info@technikflg.com");
            if (fromMail) {
              this.mail.to.addresses.push(fromMail);
            }
          } else if (this.routeState.action == "replyAll") {
            this.mail.to = this.routeState.mail.from;

            for (var add of this.routeState.mail.cc.addresses) {
              if (add.address != this.api.user.technikEmail) {
                this.mail.cc.addresses.push(add);
              }
            }

            let fromMail = this.routeState.mail.to.addresses.find((addr) => addr.address == "info@technikflg.com");
            if (fromMail) {
              this.mail.to.addresses.push(fromMail);
            }

            this.mail.subject = "Re: " + this.routeState.mail.subject;
          } else if (this.routeState.action == "forward") {
            this.mail.subject = "Fwd: " + this.routeState.mail.subject;
          }

          if (!this.routeState.mail.html) {
            this.mail.html = this.routeState.mail.textAsHtml == "" ? this.routeState.mail.text : this.routeState.mail.textAsHtml;
          } else {
            this.mail.html = this.routeState.mail.html;
          }

          if (this.mail.html != "") {
            this.mail.html = "<br/><br/><hr><blockquote>" + this.mail.html + "</blockquote>";
          }

          this.mail.attachments = this.routeState.mail.attachments;
        } else {

          if (this.routeState.to) {
            this.mail.to = new TAGMailAddress({ addresses:
              this.routeState.to.filter((addr) => addr.trim() != "").map((addr) => {
                return { name: "", address: addr };
              })
            });
          }

          if (this.routeState.cc) {
            this.mail.cc = new TAGMailAddress({ addresses:
              this.routeState.cc.filter((addr) => addr.trim() != "").map((addr) => {
                return { name: "", address: addr };
              })
            });
          }

          if (this.routeState.subject) {
            this.mail.subject = this.routeState.subject;
          }
        }
      }

      this.mail.to.html = "";
      this.mail.to.text = "";

      console.log(this.mail);
    });
  }

  back(): void {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  sendMail() {
    console.log(this.mail);

    let processingStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Sending Mail",
    }

    const progressDialog = openProgressDialog(this.dialog, [processingStep]);

    progressDialog.afterOpened().subscribe(() => {
      processingStep.state = ProgressDialogStepState.PROCESSING;

      this.api.sendMail(this.mail).then((result) => {
        console.log(result);

        processingStep.state = ProgressDialogStepState.SUCCESS;
        progressDialog.componentInstance.finishDialog(1000);
        this.back();
      }).catch((reason) => {
        processingStep.state = ProgressDialogStepState.ERROR;
        processingStep.subtitle = reason.message;
        progressDialog.componentInstance.finishDialog(-1);
      });
    });
  }

  uploadFile($event) {
    console.log($event);
    var file = $event[0];

    const reader = new FileReader();
    reader.onload = () => {
      this.mail.attachments.push(
        new TAGMailAttachment({
          fileName: file.name,
          contentType: file.type,
          size: file.size,
          content: reader.result as ArrayBuffer,
        })
      );

      console.log(this.mail.attachments);
    };
    reader.readAsDataURL(file);
  }

  addReceiver(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) this.mail.to.addresses.push({ name: "", address: value });
    event.chipInput!.clear();
  }

  addCc(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) this.mail.cc.addresses.push({ name: "", address: value });
    event.chipInput!.clear();
  }

  removeReceiver(address: any): void {
    const index = this.mail.to.addresses.findIndex((a) => a.address == address.address);

    if (index >= 0) this.mail.to.addresses.splice(index, 1);
  }

  removeCc(address: any): void {
    const index = this.mail.cc.addresses.findIndex((a) => a.address == address.address);

    if (index >= 0) this.mail.cc.addresses.splice(index, 1);
  }

  selectedCc(event: MatAutocompleteSelectedEvent): void {
    var contact = event.option.value as TAGMailContact;

    this.mail.cc.addresses.push({ name: contact.firstname + " " + contact.lastname, address: contact.email });
    this.contactInput.nativeElement.value = "";
    this.contactCtrl.setValue(null);
  }

  selectedReceiver(event: MatAutocompleteSelectedEvent): void {
    var contact = event.option.value as TAGMailContact;

    this.mail.to.addresses.push({ name: contact.firstname + " " + contact.lastname, address: contact.email });
    this.contactInput.nativeElement.value = "";
    this.contactCtrl.setValue(null);
  }

  private _filter(value: any): TAGMailContact[] {
    if (typeof value != "string") return;
    const filterValue = value.toLowerCase();

    return this.contacts.filter((contact) => {
      var match: boolean = false;

      match = match || contact.firstname.toLowerCase().includes(filterValue);
      match = match || contact.lastname.toLowerCase().includes(filterValue);
      match = match || contact.email.toLowerCase().includes(filterValue);

      return match;
    });
  }
}
