import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake/build/vfs_fonts.js');
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { map, Observable, startWith } from 'rxjs';
import { ApiService } from '../api.service';
import { openProgressDialog, ProgressDialogStep, ProgressDialogStepState } from '../dialogs/progressDialog/progressDialog';
import { TAGTransaction } from '../models/tagfinances';
import { TAGUser } from '../models/taguser';

//const PDF417 = require('pdf417-generator');
const JsBarcode = require('jsbarcode');

interface InvoiceData {
  reason?: string;
  date?: Date;
  user?: string;
  eventid?: string;
  availableItems?: TAGTransaction[];
}

@Component({
  selector: 'app-invoice-editor',
  templateUrl: './invoice-editor.component.html',
  styleUrls: ['./invoice-editor.component.scss']
})
export class InvoiceEditorComponent implements OnInit {

  addressTemplates: { name: string, address: string[] }[] = [
    { name: "SAK", address: ["Fanny-Leicht Gymnasium", "SAK", "Fanny-Leicht-Straße 13", "70563 Stuttgart"] },
  ];

  routeState: InvoiceData = null;
  availableTransactions: TAGTransaction[] = [];
  get unusedAvailTransactions() { return this.availableTransactions.filter((t) => !this.invoiceItems.find((i) => i.value.transactionID == t._id)) }

  users: TAGUser[] = [];
  userControl = new FormControl("");
  filteredUsers: Observable<TAGUser[]>;

  invoiceForm: FormGroup = new FormGroup({
    reason: new FormControl(""),
    date: new FormControl<Date>(new Date()),
    user: this.userControl,
    addressLine1: new FormControl(""),
    addressLine2: new FormControl(""),
    addressLine3: new FormControl(""),
    addressLine4: new FormControl(""),
    showQR: new FormControl(true),
    invoiceItems: new FormArray([

    ])
  });
  get invoiceItems() { return (this.invoiceForm.get("invoiceItems") as FormArray).controls }
  get total() { return this.invoiceItems.reduce((prev, curr: FormGroup) => { return prev + curr.controls.quantity.value * curr.controls.unitPrice.value }, 0) }
  itemIsTransaction(index: number) { return this.invoiceItems[index].value.transactionID != null}

  pdfData: SafeResourceUrl = "";

  lastChangedTimer: any = null;



  constructor(private api: ApiService, private sanitzer: DomSanitizer, private router: Router, private dialog: MatDialog) {
    this.generatePDF.bind(this);

    this.filteredUsers = this.userControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._userFilter(value || ""))
    );

    console.log(this.router.getCurrentNavigation().extras.state);
    if (this.router.getCurrentNavigation().extras.state) {
      this.routeState = this.router.getCurrentNavigation().extras.state as InvoiceData;
    }
  }

  _userFilter(filter: String): TAGUser[] {
    const filterValue = filter.toLowerCase();

    return this.users.filter((user) =>
      user.username.toLowerCase().includes(filterValue) ||
      user.firstname.toLowerCase().includes(filterValue) ||
      user.lastname.toLowerCase().includes(filterValue) ||
      user.email.toLowerCase().includes(filterValue)
    );
  }

  getUserName(username: string) {
    var usr = this.users.find((u) => u.username == username);
    if (usr != undefined) return usr.firstname + " " + usr.lastname;

    return username;
  }

  ngOnInit(): void {
    this.pdfData = this.sanitzer.bypassSecurityTrustResourceUrl("") as string;

    if (this.routeState) {
      if (this.routeState.reason) {
        this.invoiceForm.get("reason").setValue(this.routeState.reason);
      }

      if (this.routeState.date) {
        this.invoiceForm.get("date").setValue(this.routeState.date);
      }

      if (this.routeState.user) {
        this.invoiceForm.get("user").setValue(this.routeState.user);
      }

      if (this.routeState.availableItems) {
        this.availableTransactions = this.routeState.availableItems.map((t) => {
          if (typeof t.amount == "string") {
            t.amount = parseFloat(t.amount);
          }
          return t;
        });
      }
    }


    // Get Users and replace username with full name
    this.api.getUsers().then((result) => {
      this.users = result;

      let user = this.users.find((usr) => usr.username == this.invoiceForm.get("user").value);
      if (user) {
        this.invoiceForm.get("user").setValue(this.getUserName(user.username));
      }
    }).catch((reason) => {
      console.log(reason);
    });

    // Form change listener
    this.invoiceForm.valueChanges.subscribe((value) => {
      if (this.lastChangedTimer) {
        clearTimeout(this.lastChangedTimer);
      }

      this.lastChangedTimer = setTimeout(() => {
        this.generatePDF();
      }, 500);
      console.log(value);
      //this.generatePDF();
    });
    this.generatePDF();
  }

  selectAddressTemplate(event) {
    this.invoiceForm.get("addressLine1").setValue(event.value[0]);
    this.invoiceForm.get("addressLine2").setValue(event.value[1]);
    this.invoiceForm.get("addressLine3").setValue(event.value[2]);
    this.invoiceForm.get("addressLine4").setValue(event.value[3]);
  }

  saveInvoice() {
    if (!this.invoiceForm.valid) {
      console.log("Form is invalid");
      return;
    }

    let transactionStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Creating Transaction",
    }

    let uploadStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Uploading Invoice",
      showProgress: true,
      progress: 0,
    }

    let updateStep: ProgressDialogStep = {
      state: ProgressDialogStepState.WAITING,
      title: "Updating Transaction",
    }

    const progressDialog = openProgressDialog(this.dialog, [transactionStep, uploadStep, updateStep]);
    progressDialog.afterOpened().subscribe(() => {

      let transaction = new TAGTransaction({});
      transaction.reason = this.invoiceForm.get("reason").value;
      transaction.amount = this.total;
      transaction.date = this.invoiceForm.get("date").value;
      transaction.event = this.routeState.eventid;
      transaction.isIncome = true;
      transaction.pending = true;

      transactionStep.state = ProgressDialogStepState.PROCESSING;

      this.api.getNextInvoiceNumber().then((nextInvoiceNumber) => {
        this.api.newFinanceTransaction(transaction).then((newTrans) => {
          transactionStep.state = ProgressDialogStepState.SUCCESS;
          uploadStep.state = ProgressDialogStepState.PROCESSING;

          this.generatePDF(false, nextInvoiceNumber, newTrans._id).then((pdfDataUrl) => {
            console.log(pdfDataUrl);
            this.api.uploadFinanceTransactionAttachment(newTrans._id, "overview.pdf", pdfDataUrl, (progress) => {
              console.log(progress);
              uploadStep.progress = progress;
            }).then((result) => {
              uploadStep.state = ProgressDialogStepState.SUCCESS;
              updateStep.state = ProgressDialogStepState.PROCESSING;

              newTrans.invoiceNumber = nextInvoiceNumber;
              newTrans.invoiceFileName = "overview.pdf";

              this.api.updateFinanceTransaction(newTrans).then((result) => {
                updateStep.state = ProgressDialogStepState.SUCCESS;
                progressDialog.componentInstance.finishDialog(1000);
                setTimeout(() => {
                  this.router.navigate(["/internal/finances"]);
                }, 1000);
              }).catch((reason) => {
                updateStep.state = ProgressDialogStepState.ERROR;
                updateStep.subtitle = reason.message;
                progressDialog.componentInstance.finishDialog(-1);
                console.error(reason);
              });
            }).catch((reason) => {
              uploadStep.state = ProgressDialogStepState.ERROR;
              uploadStep.subtitle = reason.message;
              progressDialog.componentInstance.finishDialog(-1);
              console.error(reason);
            });
          });
        }).catch((reason) => {
          transactionStep.state = ProgressDialogStepState.ERROR;
          transactionStep.subtitle = reason.message;
          progressDialog.componentInstance.finishDialog(-1);
          console.error(reason);
        });
      }).catch((reason) => {
        transactionStep.state = ProgressDialogStepState.ERROR;
        transactionStep.subtitle = reason.message;
        progressDialog.componentInstance.finishDialog(-1);
        console.error(reason);

      });
    });
  }

  addInvoiceItem() {
    (this.invoiceForm.get("invoiceItems") as FormArray).push(new FormGroup({
      transactionID: new FormControl(),
      description: new FormControl(""),
      quantity: new FormControl(1),
      unitPrice: new FormControl(),
      additionalInfo: new FormControl("")
    }));
  }

  addTransactionToInvoice(transaction: TAGTransaction, index: number) {
    console.log(transaction);
    let item = new FormGroup({
      transactionID: new FormControl(transaction._id),
      description: new FormControl({value: transaction.reason, disabled: true}),
      quantity: new FormControl({value: 1, disabled: true}),
      unitPrice: new FormControl({value: transaction.amount.toString(), disabled: true}),
      additionalInfo: new FormControl("")
    });

    (this.invoiceForm.get("invoiceItems") as FormArray).insert(index, item);
  }

  removeInvoiceItem(index: number) {
    (this.invoiceForm.get("invoiceItems") as FormArray).removeAt(index);
  }

  useItem(data) {
    console.log(data);
    if (data.previousContainer === data.container) {
      let item = this.invoiceItems[data.previousIndex];
      this.removeInvoiceItem(data.previousIndex);
      this.invoiceItems.splice(data.currentIndex, 0, item);
    } else {
      this.addTransactionToInvoice(data.item.data, data.currentIndex)
    }
  }

  unuseItem(data) {
    console.log(data);
    if (data.previousContainer === data.container) {
      let item = this.availableTransactions[data.previousIndex];
      this.availableTransactions.splice(data.previousIndex, 1);
      this.availableTransactions.splice(data.currentIndex, 0, item);
    } else {
      this.removeInvoiceItem(data.previousIndex);
    }
  }

  unusePredicate(item: CdkDrag<any>) {
    console.log(item);
    return item.data.transactionID != null;
  }

  generatePDF(preview: boolean = true, invoiceNumber: number = 0, transactionID: string = "00000000000000000000000000000000") {
    return new Promise<string>((resolve, reject) => {
      let pdf = pdfMake.createPdf(this.generatePDFContent(preview, invoiceNumber, transactionID), null, {
        "Arial": {
          normal: location.origin + "/assets/fonts/arial.ttf",
          bold: location.origin + "/assets/fonts/arialbd.ttf",
          bolditalics: location.origin + "/assets/fonts/arialbi.ttf",
          italics: location.origin + "/assets/fonts/ariali.ttf"
        },
        "Roboto": {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
        }
      } );
      pdf.getDataUrl((dataUrl) => {
        this.pdfData = this.sanitzer.bypassSecurityTrustResourceUrl(dataUrl) as string;
        resolve(dataUrl);
      });
    });
  }

  generatePDFContent(preview: boolean = true, invoiceNumber: number = 0, transactionID: string = "00000000000000000000000000000000") {
    console.log(this.invoiceItems);

    let logoURL = location.origin + "/assets/images/logo.png";

    let qrObject = {};
    if (this.invoiceForm.get("showQR").value) {
      qrObject = {
        stack: [
          { qr: "https://technikflg.com/document/" + transactionID, fit: 100 },
          {
            stack: [
              { text: "Online Version", alignment: "center" }
            ]
          },
        ]
      };
    }

    let addressText = this.invoiceForm.get("addressLine1").value + "\n";
    addressText += this.invoiceForm.get("addressLine2").value + "\n";
    addressText += this.invoiceForm.get("addressLine3").value + "\n";
    addressText += this.invoiceForm.get("addressLine4").value;

    let invoiceItemTableArr: any[] = this.invoiceItems.map((item: FormGroup, i) => {
      let description: any[] = [{ text: item.controls.description.value, bold: true }];
      if (item.controls.additionalInfo.value) {
        description.push({ text: item.controls.additionalInfo.value });
      }

      return [
        { text: i + 1, style: "tableItemsGrey" },
        description,
        { text: item.controls.quantity.value, style: ["tableItemsGrey", "tableItemsRight"] },
        { text: (parseFloat(item.controls.unitPrice.value) ?? 0).toFixed(2) + " €", style: ["tableItemsGrey", "tableItemsRight"] },
        { text: (item.controls.quantity.value * item.controls.unitPrice.value).toFixed(2) + " €", style: ["tableItemsRight"] },

      ]
    });

    invoiceItemTableArr.push([
      { text: "", style: "tableItemsGrey" },
      { text: "Gesamt", bold: true },
      { text: "", style: "tableItemsGrey" },
      { text: "", style: "tableItemsGrey" },
      { text: this.total.toFixed(2) + " €", bold: true, style: ["tableItemsRight"] },
    ]);

    /* var canvas = document.getElementById("barcode") as HTMLCanvasElement;
    var context = canvas.getContext("2d");
    //PDF417.draw("testcode", canvas, 3, 8)
    JsBarcode(canvas, '123456', {
      format: "CODE128",
      lineColor: "#000000",
      width: 1,
      height: 10,
      displayValue: true,
      fontSize: 4,
      margin: 0,
    });
    canvas.getContext("2d").rotate(270 * Math.PI / 180);
    const barcode = canvas.toDataURL('image/png') */


    console.log(invoiceItemTableArr);

    let docDefinition: TDocumentDefinitions = {
      info: {
        title: "Kosten Übersicht bezüglich " + this.invoiceForm.value.reason,
      },
      pageMargins: [70, 57, 43, 57 * 2 + 20],
      content: [
        {
          layout: "noBorders",
          table: {
            widths: ["*", 100],
            body: [[
              { image: "logo", width: 132 },
              qrObject
            ]]
          }
        },
        /* { image: "barcode", width: 132, absolutePosition: { x: 70, y: 57 + 20 }, style: {  } }, */

        //marginTop: this.invoiceForm.get("showQR").value? 30 : 82 + 30
        { text: "Technik AG, Fanny-Leicht-Gymnasium,\nFanny-Leicht-Str. 13, 70563 Stuttgart", style: "addressSender", absolutePosition: { x: 70, y: 128 } },
        { text: addressText, style: "addressText", absolutePosition: { x: 70, y: 128 + 28 } },

        { text: ["Kosten Übersicht bezüglich ", {text: this.invoiceForm.value.reason, decoration: "underline"}], style: "header", marginTop: 150 },

        {
          marginTop: 8,
          table: {
            widths: [141, '*'],
            body: [
              ["Erstellungsdatum", (this.invoiceForm.value.date as Date).toLocaleDateString(["de"], {day: "2-digit", month: "2-digit", year: "numeric"})],
              ["Dokumentennummer", invoiceNumber],
              ["Sachbearbeiter", this.invoiceForm.value.user],
            ]
          },
          layout: 'noBorders'
        },

        {
          marginTop: 38,
          style: "tableItems",
          table: {
            widths: ['auto', '*', 40, 50, 50],
            heights: 16,
            headerRows: 1,
            body: [
              [
                { text: "Pos.", style: ["tableItemsGrey"] },
                { text: "Beschreibung" },
                { text: "Menge", style: ["tableItemsGrey", "tableItemsRight"] },
                { text: "Preis", style: ["tableItemsGrey", "tableItemsRight"] },
                { text: "Summe", style: ["tableItemsRight"] },
              ],
              ...invoiceItemTableArr
            ]
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 0;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 0;
            },
            hLineColor: function (i, node) {
              return '#7f7f7f';
            },
            vLineColor: function (i, node) {
              return '#7f7f7f';
            },
            fillColor: function (i, node) {
              return i === 0 || i === (node.table.body.length - 1) ? "#8c8c8c" : "";
            },
            paddingTop: (i, node) => {
              if (node.text && (node.text as string).includes("\n")){
                return 0;
              } else {
                return 4;
              }
            }
          }
        },
      ],

      footer: {
        margin: [70, 0, 43, 57],
        stack: [
          {
            columns: [
              {
                style: { alignment: "left" },
                stack: [
                  {text: "Bankverbindung", bold: true},
                  {
                    table: {
                      widths: [50, '*'],
                      body: [
                        ["Name", "Andreas Nickel"],
                        ["BIC", "SOLADEST600"],
                        ["IBAN", "DE34 6005 0101 7006 8411 01"],
                      ]
                    },
                    layout: 'noBorders'
                  },
                ],
              },
              {
                style: { alignment: "right" },
                stack: [
                  {text: "Kontakt", bold: true},
                  {
                    table: {
                      widths: ['*'],
                      body: [
                        ["TechnikFLG"],
                        ["Fanny-Leicht-Straße 13"],
                        ["70563 Stuttgart"],
                      ]
                    },
                    layout: 'noBorders'
                  },
                ]
              },
            ]
          },
          {
            bold: true,
            style: { alignment: "center" },
            marginTop: 10,
            columns: [
              { text: "info@technikflg.com", alignment: "right", marginRight: 10 },
              { text: "www.technikflg.com", alignment: "left", marginLeft: 10 },
            ]
          }
        ]
      },

      images: {
        logo: logoURL,
        //barcode: barcode,
      },

      defaultStyle: {
        font: "Roboto",
        fontSize: 10
      },

      styles: {
        addressSender: {
          font: "Arial",
          fontSize: 9,
          //color: "#8c8c8c",
          color: "#595959",
          bold: true,
        },
        addressText: {
          font: "Arial",
          fontSize: 11,
          lineHeight: 1.1,
        },
        header: {
          fontSize: 12,
          bold: true,
        },
        tableItems: {

        },
        tableItemsGrey: {
          color: "#3d3d3d",
        },
        tableItemsRight: {
          alignment: "right"
        },
        preview: {
          font: "Roboto",
          fontSize: 100,
          bold: true,
          color: "#3d3d3d",
          opacity: 0.6,
          alignment: "center",
        }
      },


    };

    if (preview) {
      (docDefinition.content as any[]).push({ text: "Preview", style: "preview", absolutePosition: { x: 70, y: 400 } });
    }

    return docDefinition;
  }

}
