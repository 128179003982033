import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { TAGEvent } from 'src/app/models/tagevent';
import { TAGLocation } from 'src/app/models/taglocation';

@Component({
  selector: 'document-grid',
  templateUrl: './document-grid.component.html',
  styleUrls: ['./document-grid.component.scss']
})
export class DocumentGridComponent implements OnInit {

  @Input() documents: {fileName: string, mimeType: string, fileData?: Blob}[] = [];
  @Input() urlTemplate: string = "";
  @Input() showUpload: boolean = false;
  @Input() showDownload: boolean = false;
  @Input() showView: boolean = false;
  @Input() showDelete: boolean = false;

  @Output() uploadClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();

  constructor(private api: ApiService) { }

  ngOnInit(): void {

  }

  fileUrl(fileName: string) {
    let doc = this.documents.find((d) => d.fileName == fileName);
    if (doc && doc.fileData) {
      return doc.fileData;
    }

    return this.urlTemplate.replace("<filename>", fileName);
  }

  uploadFile(event) {
    console.log(event);

    const reader = new FileReader();
    reader.onloadend = () => {
      let file = event[0];
      let fileData = reader.result as string;

      let fileName = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
      let mimeType = file.type;

      console.log("New File Name " + fileName);

      this.uploadClicked.emit({fileName: fileName, mimeType: mimeType, fileData: fileData});
    };

    reader.readAsDataURL(event[0]);
  }

  deleteFile(fileName: string) {
    this.deleteClicked.emit(fileName);
  }

  downloadDoc(fileName: string, download: boolean = false) {
    let url = this.fileUrl(fileName);
    if (typeof url == "string") {
      this.api.getDocumentByUrl(url).then((data) => {
        console.log(data);
        this.promptDownload(fileName, data, download);

      }).catch((reason) => {
        console.log(reason);
      });
    } else {
      this.promptDownload(fileName, url, download);
    }
  }

  promptDownload(fileName: string, data: Blob, download: boolean = false) {
    var pdfUrl = window.URL.createObjectURL(data);

    var link = document.createElement("a");
    link.download = fileName;
    link.href = pdfUrl;

    console.log(link);

    if (download) {
      link.click();
    } else {
      window.open(pdfUrl, "_blank");
    }
  }

}
