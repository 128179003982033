<img id="image" alt="{{ data.image.title }}" (load)="imageLoad()" [defaultImage]="defaultImage" [lazyLoad]="data.link" />

<div id="titlewrapper">
  <div *ngIf="data.image.title != ''" id="title">
    {{ data.image.title }}
  </div>

  <div *ngIf="data.image.uploaddate != ''" id="date">
    <!-- {{ this.getFormattedHTML(getFormattedDate(this.data.image.uploaddate)) }} -->
    {{ this.data.image.uploaddate | date: 'dd.MM.yyyy' }}
  </div>
</div>

<div *ngIf="data.image.description != ''" id="subtitle">
  {{ this.getFormattedHTML(data.image.description) }}
</div>
