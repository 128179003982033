import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { sha256 } from "js-sha256";
import { MatDialog } from "@angular/material/dialog";
import { TAGUser } from "../models/taguser";
import { Permission } from "../models/tagpermission";
import { ActivatedRoute, Router } from "@angular/router";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { ProgressDialogStep, ProgressDialogStepState, openProgressDialog } from "../dialogs/progressDialog/progressDialog";

@Component({
  selector: "app-internalUsers",
  templateUrl: "./internal.users.edit.component.html",
  styleUrls: ["./internal.users.edit.component.scss"],
})
export class InternalUsersEditComponent implements OnInit {
  pinPermission = Permission.pin_login;

  user: TAGUser = new TAGUser();
  userpassword: string = "";
  userpin: string = "";

  hiddenPermissionGroups: string[] = ["Locations"];

  getAllPermissions(): Permission[] {
    return Permission.allPermissions;
  }

  getAllPermissionGroups(): string[] {
    var groups = new Set<string>();
    this.getAllPermissions().forEach((p) => {
      if (!this.hiddenPermissionGroups.includes(p.category)) {
        groups.add(p.category);
      }
    });
    return Array.from(groups);
  }

  getPermissionsByGroup(group: string): Permission[] {
    var perms: Permission[] = [];
    this.getAllPermissions().forEach((p) => {
      if (p.category == group) perms.push(p);
    });
    return perms;
  }

  constructor(private api: ApiService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute) {

  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  hasAnyGroupPerm(group: string) {
    var hasAny = false;
    this.getPermissionsByGroup(group).forEach((p) => {
      if (this.hasPerm(p.name)) hasAny = true;
    });
    return hasAny;
  }

  userHasAllGroupPerm(group: string) {
    var hasAll = true;
    this.getPermissionsByGroup(group).forEach((p) => {
      if (!this.user.permissions.has(p)) hasAll = false;
    });
    return hasAll;
  }

  ngOnInit() {
    console.log(this.route);
    this.route.params.subscribe((params) => {
      this.api.waitForAPI().then(() => {
        this.loadUser(params.userid);
      });
    });
  }

  generateRandomPin() {
    this.userpin = "";
    for (var i = 0; i < 4; i++) {
      this.userpin += Math.floor(Math.random() * 10);
    }

    let charCount: { [key: string]: number } = {};
    for (var i = 0; i < this.userpin.length; i++) {
      var char = this.userpin.charAt(i);
      if (charCount[char] == undefined) charCount[char] = 1;
      else charCount[char]++;
    }

    if (Object.values(charCount).filter((v) => v > 2).length > 0) {
      this.generateRandomPin();
    }
  }

  loadUser(userid: string) {
    if (userid == "new") {
      this.user = new TAGUser();
    } else
      this.api.getUser(userid).then((result) => {
        this.user = result;
      }).catch((reason) => {
        //TODO: Show Error
      });
  }

  checkPinCharacter(event) {
    // Filter userpin to only include numeric characters
    this.userpin = event.target.value.replace(/[^0-9]/g, "");
  }

  editPermission(permission: Permission, add: boolean) {
    if (add) {
      this.user.permissions.add(permission);
    } else {
      this.user.permissions.delete(permission);
    }
  }

  editPermGroup(group: string, add: boolean) {
    this.getPermissionsByGroup(group).forEach((p) => {
      if (this.hasPerm(p.name)) this.editPermission(p, add);
    });
  }

  saveUser() {
    console.log(this.user);

    let savingStep: ProgressDialogStep = {
      state: ProgressDialogStepState.PROCESSING,
      title: "Saving",
    }

    const progressDialog = openProgressDialog(this.dialog, [savingStep]);

    if (this.userpassword != "") this.user.password = sha256(this.userpassword);
    if (this.userpin != "") this.user.pin = this.userpin;

    if (this.user._id != undefined) {
      this.api.updateUser(this.user).then((result) => {
        savingStep.state = ProgressDialogStepState.SUCCESS;
        progressDialog.componentInstance.finishDialog();
        this.goBack();
      }).catch((reason) => {
        savingStep.state = ProgressDialogStepState.ERROR;
        savingStep.subtitle = reason.message;
        progressDialog.componentInstance.finishDialog(-1);
      });
    } else {
      if (this.user.username == "") {
        return;
      }

      this.api.newUser(this.user).then((result) => {
        savingStep.state = ProgressDialogStepState.SUCCESS;
        progressDialog.componentInstance.finishDialog();
        this.goBack();
      }).catch((reason) => {
        savingStep.state = ProgressDialogStepState.ERROR;
        savingStep.subtitle = reason.message;
        progressDialog.componentInstance.finishDialog(-1);
      });
    }
  }

  goBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  deleteUser() {
    if (this.user._id == undefined) return;

    openDeleteDialog(this.dialog, "User", [this.user.username])
      .afterClosed()
      .subscribe((result) => {
        if (result)
          this.api.deleteUser(this.user._id).then((result) => {
            this.goBack();
          }).catch((reason) => {
            //TODO: Show Error
          });
      });
  }
}
