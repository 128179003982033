import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InternalMenuService {

  public menuOpen = new BehaviorSubject<boolean>(false);

  constructor() { }

  public toggleMenu(newState?: boolean) {
    if (newState !== undefined) {
      this.menuOpen.next(newState);
    } else {
      this.menuOpen.next(!this.menuOpen.value);
    }
  }

  public get isOpen() {
    return this.menuOpen.asObservable();
  }
}
