import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timespan'
})
export class TimeSpanPipe implements PipeTransform {
  transform(value: number): string {

    const days = Math.floor(value / 86400);
    const hours = Math.floor((value % 86400) / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.floor(value % 60);

    var result = "";
/*     if (days > 0) result += ('00' + days).slice(-2) + "d ";
    if (hours > 0) result += ('00' + hours).slice(-2) + "h ";
    if (minutes > 0) result += ('00' + minutes).slice(-2) + "m ";
    if (seconds > 0) result += ('00' + seconds).slice(-2) + "s "; */

    if (days > 0) result += days + "d ";
    if (hours > 0) result += hours + "h ";
    if (minutes > 0) result += minutes + "m ";
    if (seconds > 0) result += seconds + "s ";
    return result;
  }
}