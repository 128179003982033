<div id="int-content-topbar">
  <internal-menu-button></internal-menu-button>
  <button *ngIf="hasPerm('create_presence')" mat-stroked-button routerLink="new" routerLinkActive="active">{{ "internal.presence.newPresence" | translate }}</button>
</div>

<div id="int-presence-content">
  <ng-container *ngIf="(schoolyears | async) === null"> Loading tabs... </ng-container>

  <mat-tab-group animationDuration="0ms" [selectedIndex]="(schoolyears | async) === null ? 0 : (schoolyears | async).length - 1">
    <mat-tab *ngFor="let tab of schoolyears | async">
      <ng-template mat-tab-label>{{ tab + " - " + (tab + 1) }}</ng-template>

      <div id="int-presence-legend-grid">
        <div *ngFor="let pres of [0, 1, 2, 3, 4, 5, 'default']; let i = index" class="int-presence-col">
          <div class="int-presence-cell" [style.background]="getPresenceColor(pres)">
            <mat-icon class="noselect">{{ getPresenceIcon(pres) }}</mat-icon>
          </div>

          <div class="int-presence-cell">
            <div class="noselect" style="font-size: 16px">{{ "internal.presence.tooltips." + pres | translate }}</div>
          </div>

          <div></div>
        </div>
      </div>

      <div id="int-presence-grid">
        <div class="int-presence-cell" [style.grid-area]="'1 / 1'">
          <div class="noselect">
            {{ "internal.presence.date" | translate }}
          </div>
        </div>

        <div *ngFor="let username of filteredUsers[tab + '']; let i = index" class="int-presence-cell" [style.grid-area]="i + 2 + ' / 1'">
          <div class="noselect" style="font-size: 16px">
            <mat-icon *ngIf="getUserName(username) == username" class="noselect">delete</mat-icon>
            {{ getUserName(username) }}
          </div>
        </div>

        <div *ngFor="let pres of filteredPresence[tab + '']; let o = index" class="int-presence-col">
          <div class="int-presence-cell" [style.grid-area]="'1 / ' + (o + 2)">
            <div class="noselect text-vertically">
              <div class="int-presence-date-start">
                {{ pres.startdate | date: "d. MMM y, HH:mm" }}
              </div>
              <div class="int-presence-date-end">
                {{ pres.enddate | date: "d. MMM y, HH:mm" }}
              </div>
            </div>
          </div>

          <div
            *ngFor="let username of filteredUsers[tab + '']; let i = index"
            class="int-presence-cell"
            [style.grid-area]="i + 2 + ' / ' + (o + 2)"
            [style.background]="getUserColor(pres, username)">
            <mat-icon class="noselect">{{ getUserIcon(pres, username) }}</mat-icon>
          </div>

          <div
            [ngClass]="{ touch: canEdit(pres) }"
            [routerLink]="canEdit(pres) ? pres._id : './'"
            routerLinkActive="active"
            [style.grid-area]="'1 / ' + (o + 2) + ' / span ' + (filteredUsers[tab + ''].length + 1)"></div>
        </div>

        <div class="int-presence-cell" [style.grid-area]="'1 / ' + (filteredPresence[tab + ''].length + 2)" style="background-color: rgba(255, 255, 255, 0.1)">
          <div class="noselect text-vertically">
            {{ "internal.presence.percentage" | translate }}
          </div>
        </div>

        <div
          *ngFor="let username of filteredUsers[tab + '']; let i = index"
          class="int-presence-cell"
          [style.grid-area]="i + 2 + ' / ' + (filteredPresence[tab + ''].length + 2)"
          style="background-color: rgba(255, 255, 255, 0.1)">
          <div class="noselect" style="font-size: 16px">
            {{ getUserPercentage(username, tab) + "%" }}
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
