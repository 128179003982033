<div id="invoice-topbar">
    <internal-menu-button id="menuBtn"></internal-menu-button>
    <button mat-stroked-button (click)="saveInvoice()" [disabled]="!invoiceForm.valid">{{ "general.save" | translate }}</button>
</div>

<div id="invoice-content">
    <div id="invoice-form">
        <form [formGroup]="invoiceForm">
            <div class="title">{{ "internal.invoice.formTitle" | translate }}</div>

            <mat-form-field appearance="standard">
                <mat-label>{{ 'internal.invoice.reason' | translate }}</mat-label>
                <input matInput placeholder="Fannyball 2023" formControlName="reason" required />
                <mat-icon matSuffix>edit</mat-icon>
              </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-placeholder>{{ "internal.invoice.date" | translate }}</mat-placeholder>
                <input matInput formControlName="date" [matDatetimepicker]="datepicker" required />
                <mat-datetimepicker-toggle [for]="datepicker" matSuffix></mat-datetimepicker-toggle>
                <mat-datetimepicker #datepicker type="date" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>{{ 'internal.invoice.user' | translate }}</mat-label>
                <input matInput placeholder="User" [formControl]="userControl" [matAutocomplete]="userAutocomplete" required />
                <mat-icon matSuffix>edit</mat-icon>

                <mat-autocomplete #userAutocomplete="matAutocomplete">
                <mat-option *ngFor="let user of filteredUsers | async" [value]="getUserName(user.username)"> {{getUserName(user.username)}} </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" style="height: 50px;">
                <mat-label>{{ "internal.invoice.addressTemplate" | translate }}</mat-label>
                <mat-select (selectionChange)="selectAddressTemplate($event)">
                  <mat-option *ngFor="let tmpl of addressTemplates" [value]="tmpl.address">
                    {{tmpl.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            <mat-form-field appearance="standard" style="height: 50px;">
                <mat-label>{{ "internal.invoice.addressLine" | translate:{line: 1} }}</mat-label>
                <input matInput placeholder="SAK" formControlName="addressLine1" required />
                <mat-icon matSuffix>edit</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="standard" style="height: 50px;">
                <mat-label>{{ "internal.invoice.addressLine" | translate:{line: 2} }}</mat-label>
                <input matInput placeholder="Fanny-Leicht Gymnasium" formControlName="addressLine2" required />
                <mat-icon matSuffix>edit</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="standard" style="height: 50px;">
                <mat-label>{{ "internal.invoice.addressLine" | translate:{line: 3} }}</mat-label>
                <input matInput placeholder="Fanny-Leicht-Straße 13" formControlName="addressLine3" required />
                <mat-icon matSuffix>edit</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>{{ "internal.invoice.addressLine" | translate:{line: 4} }}</mat-label>
                <input matInput placeholder="70563 Stuttgart" formControlName="addressLine4" />
                <mat-icon matSuffix>edit</mat-icon>
            </mat-form-field>

            <mat-slide-toggle formControlName="showQR">{{ "internal.invoice.showQR" | translate }}</mat-slide-toggle>

            <div class="subtitle">{{ "internal.invoice.usedItems" | translate }}</div>
            <div id="form-invoice-item-list" formArrayName="invoiceItems">

                <div id="form-invoice-item-list-header">
                    <button mat-icon-button (click)="addInvoiceItem()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

                <div id="form-invoice-item-list-list"
                cdkDropList
                #invoiceList="cdkDropList"
                [cdkDropListData]="invoiceItems"
                [cdkDropListConnectedTo]="[availableList]"
                (cdkDropListDropped)="useItem($event)">

                    <div cdkDrag [cdkDragData]="iItem.value" class="form-invoice-item-list-item" *ngFor="let iItem of invoiceItems; let i = index" [formGroupName]="i">
                        <mat-icon cdkDragHandle style="margin: auto; width: 40px;">drag_handle</mat-icon>

                        <div *cdkDragPreview>{{iItem.get("description").value + ": " + iItem.get("quantity").value + "x " + iItem.get("unitPrice").value + " €"}}</div>
                        <div *cdkDragPlaceholder class="drag-placeholder" style="min-height: 72px;"></div>

                        <mat-form-field appearance="standard">
                            <mat-label>{{ "internal.invoice.description" | translate }}</mat-label>
                            <input matInput formControlName="description" required/>
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>{{ "internal.invoice.additionalInfo" | translate }}</mat-label>
                            <input matInput formControlName="additionalInfo"/>
                        </mat-form-field>

                        <mat-form-field appearance="standard" style="width: 50px;">
                            <mat-label>{{ "internal.invoice.quantity" | translate }}</mat-label>
                            <input matInput type="number" formControlName="quantity" required/>
                        </mat-form-field>

                        <mat-form-field appearance="standard" style="width: 90px;">
                            <mat-label>{{ "internal.invoice.unitPrice" | translate }}</mat-label>
                            <input matInput mask="0*.00" [dropSpecialCharacters]="false" type="text" [placeholder]="0" formControlName="unitPrice" required/>
                            <span matPrefix>€&nbsp;</span>
                        </mat-form-field>

                        <button mat-icon-button (click)="removeInvoiceItem(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>

                <div id="form-invoice-item-list-footer">
                    <span>{{ "internal.invoice.total" | translate }}: {{total.toFixed(2)}} €</span>
                </div>
            </div>

            <div class="subtitle">{{ "internal.invoice.availableItems" | translate }}</div>
            <div id="form-invoice-available-list"
            cdkDropList
            #availableList="cdkDropList"
            [cdkDropListData]="unusedAvailTransactions"
            [cdkDropListConnectedTo]="[invoiceList]"
            (cdkDropListDropped)="unuseItem($event)"
            [cdkDropListEnterPredicate]="unusePredicate">
                <div cdkDrag [cdkDragData]="trans" class="form-invoice-available-list-item" *ngFor="let trans of unusedAvailTransactions; let i = index">
                    <div *cdkDragPreview>{{trans.reason + ": " + trans.amount.toFixed(2) + " €"}}</div>
                    <div *cdkDragPlaceholder class="drag-placeholder"></div>

                    <mat-icon cdkDragHandle style="width: 40px;">drag_handle</mat-icon>
                    <div style="margin-right: auto">{{trans.reason}}</div>
                    <div>{{trans.amount.toFixed(2)}} €</div>
                </div>
            </div>
        </form>
    </div>

    <div id="invoice-preview">
        <embed name="plugin" [src]="pdfData" type="application/pdf"/>
    </div>
</div>

<canvas id="barcode" style="display: none;"></canvas>