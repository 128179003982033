import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { TAGMailFolder } from "../models/tagmail";

export interface MailLoginInformationDialogData {

}

@Component({
  selector: "int-mails-logininformationdialog",
  templateUrl: "mailLoginInformationDialog.html",
  styleUrls: ["./mailLoginInformationDialog.scss"],
})
export class MailLoginInformationDialog {

  loading: boolean = true;
  userMailInfo: {email: string, password: string} = undefined;

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MailLoginInformationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MailLoginInformationDialogData
  ) {

  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.api.getUserMailInfo().then((info) => {
        this.userMailInfo = info;
        this.loading = false;

        console.log(this.userMailInfo);
      });
    });
  }
}
