import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import { Permission } from '../models/tagpermission';
import { TAGLocation } from '../models/taglocation';

@Component({
  selector: 'app-internalLocations',
  templateUrl: './internal.locations.component.html',
  styleUrls: ['./internal.locations.component.scss']
})
export class InternalLocationsComponent implements OnInit {

  pageSize: number = 10;
  currentPage: number = 0;
  displayedColumns: string[] = ['name', 'address', 'coordinates'];
  dataSource: MatTableDataSource<TAGLocation>;

  locations: TAGLocation[] = [];

  constructor(private api: ApiService) { }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  switchPage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;

    var startIndex = this.currentPage * this.pageSize;
    this.dataSource = new MatTableDataSource(this.locations.slice(startIndex, startIndex + this.pageSize));
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.reloadLocations();
    });
  }


  reloadLocations() {
    this.api.getLocations().then((result) => {
      this.locations = result;
      this.switchPage({pageIndex: 0, pageSize: this.pageSize});
    }).catch((reason) => {
    //TODO: Show Error
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
