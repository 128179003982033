import { HttpClient, HttpResponse } from "@angular/common/http";
import { ChangeDetectorRef, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, Subscription, switchMap, tap } from "rxjs";

@Pipe({
  name: "useHttpImgSrc",
  pure: false,
})
export class UseHttpImageSourcePipe implements PipeTransform {
  private subscription = new Subscription();
  private transformValue = new BehaviorSubject<string>("");

  private latestValue!: string | SafeUrl;

  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
    this.setUpSubscription();
  }

  transform(imagePath: string): string | SafeUrl {
    // we emit a new value
    this.transformValue.next(imagePath);

    // we always return the latest value
    return this.latestValue;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setUpSubscription(): void {
    const transformSubscription = this.transformValue
      .asObservable()
      .pipe(
        // we filter out empty strings and falsy values
        filter((v): v is string => !!v),
        // we don't emit if the input hasn't changed
        distinctUntilChanged(),
        // we use switchMap, so the previous subscription gets torn down
        switchMap((imagePath: string) => {
          var blob: Observable<Blob>;

          if (imagePath.startsWith("data:")) {
            console.log("Loading image from data url");
            blob = new Observable<Blob>((observer) => {
              var blobType = imagePath.substring(5, imagePath.indexOf(";"));
              console.log("Blob type: " + blobType);
              var base64 = imagePath.split(",")[1];
              var byteCharacters = atob(base64);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: blobType });
              observer.next(blob);
              observer.complete();
            });
          } else {
            console.log("Loading image from: " + imagePath);
            blob = this.httpClient
              // we get the imagePath, observing the response and getting it as a 'blob'
              .get(imagePath, { observe: "response", responseType: "blob" })
              .pipe(
                // we map the response to the blob
                map((response: HttpResponse<Blob>) => response.body)
              );
          }

          return blob.pipe(
            // we map our blob into an ObjectURL
            map((blob: Blob) => URL.createObjectURL(blob)),
            // we bypass Angular's security mechanisms
            map((unsafeBlobUrl: string) => this.domSanitizer.bypassSecurityTrustResourceUrl(unsafeBlobUrl)),
            // we trigger it only when there is a change in the result
            filter((blobUrl) => blobUrl !== this.latestValue)
          );
        }),
        tap((imagePath: string | SafeUrl) => {
          // we set the latestValue property of the pipe
          this.latestValue = imagePath;
          // and we mark the DOM changed, so the pipe's transform method is called again
          this.cdr.markForCheck();
        })
      )
      .subscribe();
    this.subscription.add(transformSubscription);
  }
}
