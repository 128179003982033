import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { MatTableDataSource } from "@angular/material/table";
import { TAGMusicWish } from "../models/tagmusicwish";

@Component({
  selector: "app-musicwishform",
  templateUrl: "./musicwishform.component.html",
  styleUrls: ["./musicwishform.component.scss"],
})
export class MusicWishFormComponent implements OnInit {
  musicwishes: TAGMusicWish[];
  filteredMusicWishes: TAGMusicWish[];

  filterValue: string = "";

  musicwish: TAGMusicWish = new TAGMusicWish();

  lastWish: Date = null;
  lastVote: Date = null;

  reloadTimer: any = undefined;

  timeout(): number {
    if (this.lastWish == null) return 9999;
    return new Date().getTime() / 1000 - this.lastWish.getTime() / 1000;
  }

  timeoutStr(): string {
    var time = 300 - this.timeout();
    var min = Math.floor(time / 60);
    var sec = Math.floor(time % 60);
    return min + ":" + (sec < 10 ? "0" : "") + sec;
  }

  timeoutVote(): number {
    if (this.lastVote == null) return 9999;
    return new Date().getTime() / 1000 - this.lastVote.getTime() / 1000;
  }

  ongoingWishes(): TAGMusicWish[] {
    if (!this.musicwishes) return [];

    return this.musicwishes.filter((wish) => {
      return wish.played == false;
    });
  }

  playedWishes(): TAGMusicWish[] {
    if (!this.musicwishes) return [];

    return this.musicwishes.filter((wish) => {
      return wish.played == true;
    });
  }

  constructor(public api: ApiService) {
    console.log(this.api);
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      if (localStorage.getItem("lastWish") != null) this.lastWish = new Date(localStorage.getItem("lastWish"));
      if (localStorage.getItem("lastVote") != null) this.lastVote = new Date(localStorage.getItem("lastVote"));

      this.reloadMusicWishes();

      this.reloadTimer = setInterval(() => {
        this.reloadMusicWishes();
      }, 5000);
    });
  }

  ngOnDestroy(): void {
    if (this.reloadTimer) clearInterval(this.reloadTimer);
  }

  sendWish() {
    if (!this.api.config.enableMusicWishes) return;
    if (this.timeout() < 300) return;

    let oldTime = this.lastWish;
    this.lastWish = new Date();

    if (this.api.isLoggedIn()) {
      this.musicwish.owner = this.api.user.username;
    }

    if (this.musicwish.title.trim() == "") {
      alert("Title should not be empty");
      this.lastWish = oldTime;
      return;
    }

    this.api.newMusicWishes(this.musicwish).then((result) => {
      this.musicwish = new TAGMusicWish();
      this.lastWish = new Date();
      localStorage.setItem("lastWish", new Date().toISOString());
      this.reloadMusicWishes();
    }).catch((reason) => {
      alert("It wasn't possible to process your Musicwish");
      this.lastWish = oldTime;
    });
  }

  applyFilter() {
    if (this.filterValue == "") {
      this.filteredMusicWishes = this.ongoingWishes();
    } else {
      this.filteredMusicWishes = this.ongoingWishes().filter((musicwish) => {
        return musicwish.title.toLowerCase().includes(this.filterValue.toLowerCase()) ||
          musicwish.artist.toLowerCase().includes(this.filterValue.toLowerCase());
      });
    }
  }

  reloadMusicWishes() {
    this.api.getMusicWishes().then((result) => {
      this.musicwishes = result;
      this.applyFilter();
    }).catch((reason) => {
      this.musicwishes = [];
    });
  }

  vote(wishid: string) {
    if (!this.api.config.enableMusicWishes) return;
    if (this.timeoutVote() < 300) return;

    let oldTime = this.lastVote;
    this.lastVote = new Date();

    console.log("Vote for " + wishid);
    this.api.voteForMusicWish(wishid).then((result) => {
      this.lastVote = new Date();
      localStorage.setItem("lastVote", new Date().toISOString());
      this.reloadMusicWishes();
    }).catch((reason) => {
      alert("Your Vote couldn't be processed");
      this.lastVote = oldTime;
    });
  }
}
