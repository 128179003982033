<div id="grouplist-header">
  <div id="grouplist-header-title">{{ 'internal.inventory.groupList.title' | translate }}</div>
</div>

<div id="grouplist-form">
  <form [formGroup]="scannerForm" (ngSubmit)="scanCode()">
    <mat-form-field>
      <input
        #scannerInput
        matInput
        placeholder="{{ 'internal.inventory.scanner' | translate }}"
        formControlName="input"
        (change)="codeScanned(scannerInput.value)" />
      <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
    </mat-form-field>
  </form>

  <div id="group-list">
    <div *ngFor="let group of filteredGroups()" class="group">
      <div class="group-name">{{group.name}}</div>

      <div class="group-actions">
        <button mat-icon-button matTooltip="Print Group Label" (click)="printGroupLabel(group)"><mat-icon>print</mat-icon></button>
        <button mat-icon-button matTooltip="Print Item Labels" (click)="printLabel(group)"><mat-icon>summarize</mat-icon></button>
        <button mat-icon-button (click)="showGroup(group)"><mat-icon>visibility</mat-icon></button>
      </div>
    </div>

    <empty-state
      *ngIf="filteredGroups().length == 0"
      [icon]="'search_off'"
      [title]="'internal.inventory.groupList.empty.title' | translate"
      [subtitle]="'internal.inventory.groupList.empty.subtitle' | translate"
      [showButton]="true"
      [buttonText]="'internal.inventory.groupList.empty.button' | translate"
      (buttonClick)="clearFilters()">
    </empty-state>
  </div>
</div>

<div id="grouplist-actions">
  <button mat-stroked-button (click)="newGroup()">{{ 'internal.inventory.groupList.newGroup' | translate }}</button>
  <button mat-stroked-button (click)="cancel()">{{ 'general.close' | translate }}</button>
</div>
