import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { Permission } from "../models/tagpermission";
import { TAGInvItemGroup, TAGInvItemType, TAGInvTransaction } from "../models/taginventory";
import { ItemEditDialog } from "./itemEditDialog";
import { getInventoryInstanceByCode } from "./inventoryHelper";

export interface GroupEditDialogData {
  group: TAGInvItemGroup;
}

@Component({
  selector: "int-inventory-groupEditDialog",
  templateUrl: "groupEditDialog.html",
  styleUrls: ["./groupEditDialog.scss"],
})
export class GroupEditDialog {
  //TODO: Form with validation

  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  scannerForm = this.fb.group({
    input: [""],
  });

  transactions: TAGInvTransaction[] = [];

  group: TAGInvItemGroup = new TAGInvItemGroup();
  itemTypes: TAGInvItemType[] = [];

  editingDisabled: boolean = false;
  deletionDisabled: boolean = false;

  instanceDeleted(itemid: string, instanceid: string) {
    var item = this.itemTypes.find((itm) => itm._id == itemid);
    if (!item) return false;

    var instance = item.instances.find((inst) => inst.itemid == instanceid);
    if (!instance) return false;

    return item.deleted || instance.deleted;
  }

  instanceBooked(itemid: string, instanceid: string) {
    let trans = this.transactions.filter((t) => t.containsItemInstance(itemid, instanceid));

    for (let t of trans) {
      let tItem = t.findItem(itemid, instanceid);
      if (!tItem.back) return true;
    }

    return false;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  constructor(
    public api: ApiService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GroupEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: GroupEditDialogData
  ) {
    api.waitForAPI().then(() => {
      api.getInvTransactions().then((result) => {
        this.transactions = result;

        this.group = new TAGInvItemGroup();

        this.editingDisabled = !this.hasPerm("create_inventory_item");
        this.deletionDisabled = !this.hasPerm("delete_inventory_item");

        if (data.group) {
          this.group = new TAGInvItemGroup(Object.assign({}, data.group));
          if (this.group._id) {
            this.editingDisabled = !this.hasPerm("edit_inventory_item");
          }
        }

        if (this.group.deleted) {
          this.editingDisabled = true;
          this.deletionDisabled = true;
        }

        console.log(this.group);

        //console.log(this.itemFilter(this.itemTypes[0]._id));
      }).catch((reason) => {
        console.error(reason);
      });

      api.getInvItems().then((items) => {
        this.itemTypes = items.filter((item) => data.group.items.findIndex((itm) => itm.itemid == item._id) != -1);
      }).catch((reason) => {
        console.error(reason);
      });
    });

    this.dialogRef.disableClose = true;
  }

  cancelEdit(): void {
    this.dialogRef.close(false);
  }

  saveEdit(): void {
    if (this.group._id) {
      this.api.updateInvItemGroup(this.group).then((result) => {
        this.dialogRef.close(result);
      }).catch((reason) => {
        console.error(reason);
      });
    } else {
      this.api.createInvItemGroup(this.group).then((result) => {
        this.dialogRef.close(result);
      }).catch((reason) => {
        console.error(reason);
      });
    }
  }

  deleteGroup() {
    openDeleteDialog(this.dialog, "Item Group", [this.group.name])
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.api.deleteInvItemGroup(this.group._id).then((result) => {
            this.dialogRef.close({ group: this.group, delete: true });
          }).catch((reason) => {
            console.error(reason);
          });
        }
      });
  }

  selectItem(item: TAGInvItemType, selectedInstances?: string[]) {
    let width = "50%";
    if (window.innerWidth < 600) {
      width = "100%";
    }

    const dialogRef = this.dialog.open(ItemEditDialog, {
      minWidth: "300px",
      width: width,
      data: { item: item, selectedInstances: selectedInstances },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.resetScannerField();
    });
  }

  removeItem(itemid: string, instanceid: string) {
    this.group.items = this.group.items.filter((item) => !(item.itemid == itemid && item.instanceid == instanceid));
    this.itemTypes = this.itemTypes.filter((item) => this.group.items.findIndex((itm) => itm.itemid == item._id) != -1);
  }

  resetScannerField() {
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: { multiple: true },
    });

    dialogRef.componentInstance.onCodeScan.subscribe((code: string) => {
      this.codeScanned(code);
      this.resetScannerField();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  codeScanned(code: string) {
    getInventoryInstanceByCode(code, this.api).then((result) => {
      let item = result.item;
      let instanceID = result.instanceID;

      console.log(result);

      if (item && instanceID) {
        if (this.group.items.findIndex((gItem) => gItem.itemid == item._id && gItem.instanceid == instanceID) == -1) {
          this.group.items.push({ itemid: item._id, instanceid: instanceID });
        }

        if (this.itemTypes.findIndex((itm) => itm._id == item._id) == -1) {
          this.itemTypes.push(item);
        }
      }
    }).catch((reason) => {
      console.error(reason);
    }).finally(() => {
      this.resetScannerField();
    });
  }

  itemFilter(itemid: string) {
    return this.group.items.filter((comb) => comb.itemid == itemid);
  }
}
