import { Component, OnInit } from "@angular/core";
import { InternalMenuService } from "../internal-menu.service";
import { ApiService } from "../api.service";
import { AuthGuardService } from "../auth-guard.service";

@Component({
  selector: "app-internal",
  templateUrl: "./internal.component.html",
  styleUrls: ["./internal.component.scss"],
})
export class InternalComponent implements OnInit {

  menuOpen: boolean = true;
  shadowVisible: boolean = false;

  amountUnreadMails: number = 0;

  constructor(private api: ApiService, private authGuard: AuthGuardService, private menuBtnSvc: InternalMenuService) {}

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.api.getAllMails("INBOX").then((result) => {
        this.amountUnreadMails = result.filter(mail => !mail.flags.includes("\\Seen")).length;
      }).catch((reason) => {
        //TODO: Show error
      });
    });

    this.menuBtnSvc.isOpen.subscribe((isOpen) => {
      if (isOpen) {
        this.shadowVisible = true;
        setTimeout(() => {
          this.menuOpen = true;
        }, 10);
      } else {
        this.menuOpen = false;
        setTimeout(() => {
          this.shadowVisible = false;
        }, 300);
      }
    });
  }

  hasPermission(group: string): boolean | Promise<boolean> {
    return this.authGuard.canActivateUrl(group);
  }

  hasEmail(): boolean {
    return this.api.user.technikEmail != null && this.api.user.technikEmail != "";
  }

  closeMenu() {
    this.menuBtnSvc.toggleMenu(false);
  }

  toggleMenu() {
      this.menuBtnSvc.toggleMenu();
  }
}
