<div id="mailsFolderEdit-header">
  {{ 'internal.mails.mailsFolderEditTitle' | translate }}
</div>

<div id="mailsFolderEdit-form">
  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.mails.folderName' | translate }}</mat-label>
    <input matInput placeholder="Folder" [(ngModel)]="folder.name" />
    <mat-icon matSuffix>edit</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{ 'internal.mails.parentFolder' | translate }}</mat-label>
    <mat-select [(ngModel)]="folder.path">
      <mat-option [value]="''">
        Root
      </mat-option>

      <mat-option *ngFor="let f of foldersFlat" [value]="f.path">
        {{f.path}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>

<div id="mailsFolderEdit-actions">
  <button mat-stroked-button color="warn" (click)="cancelEdit()">{{ 'general.cancel' | translate }}</button>
  <button mat-stroked-button (click)="saveEdit()">{{ 'general.save' | translate }}</button>
</div>
