import { DBModel } from "./DBModel";

export class TAGImageCategory extends DBModel {
  alias: string = "";
  title: string = "";
  description: string = "";

  eventid: string = undefined;

  isPublic: boolean = true;

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  serialize(): any {
    var obj: any = super.serialize();

    obj.alias = this.alias;
    obj.title = this.title;
    obj.description = this.description;

    obj.eventid = this.eventid;

    obj.isPublic = this.isPublic;

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.alias != null) this.alias = data.alias;
    if (data.title != null) this.title = data.title;
    if (data.description != null) this.description = data.description;

    if (data.eventid != null) this.eventid = data.eventid;

    if (data.isPublic != null) this.isPublic = data.isPublic;
  }
}
