import { Component, ViewContainerRef, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { CopyrightdialogComponent } from "./copyrightdialog/copyrightdialog.component";

import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "./api.service";

import { sha256 } from "js-sha256";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Permission } from "./models/tagpermission";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("viewhost", { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

  currentYear() {
    return new Date().getFullYear();
  }

  hideMenu: boolean = false;
  openloginform: boolean = false;

  token: string = "";

  forminvalid: boolean = false;
  errormessage: string = "";

  loginform = this.fb.group({
    username: ["", Validators.required],
    password: ["", Validators.required],
  });

  loggedin() {
    if (this.api == null) return false;
    return this.api.isLoggedIn();
  }

  username() {
    if (this.api == null) return "ERROR";
    return this.api.getUsername();
  }

  isKioskMode() {
    return this.loggedin() ? this.api.user.hasPermission(Permission.kiosk_mode) : false;
  }

  constructor(translate: TranslateService, public dialog: MatDialog, private api: ApiService, private fb: UntypedFormBuilder, private route: ActivatedRoute, private router: Router) {
    translate.setDefaultLang("en");
    translate.use(navigator.language.slice(0, navigator.language.indexOf("-")));

    console.log("Language: " + navigator.language);
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {});

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        var snapshot = this.router.routerState.root.firstChild.snapshot;

        var params = snapshot.data;
        console.log(params);
        if (params.hideMenu) this.hideMenu = true;
      }
    });

    console.log(this.route);

    this.route.data.subscribe((params) => {
      console.log(params);
      if (params.openCopyrightDialog) this.openCopyrightDialog();
    });
  }

  keyDownOnLogin(event) {
    if (event.keyCode == 13) {
      this.login();
    }
  }

  login() {
    this.forminvalid = false;
    this.errormessage = "";

    this.api.login(this.loginform.value.username, sha256(this.loginform.value.password)).then((result) => {

    }).catch((reason) => {
      if (reason.error == 2) {
        this.forminvalid = true;
        this.errormessage = "login.error.invalid";
      } else if (reason.error == 3 || reason.error == 7) {
        this.forminvalid = true;
        this.errormessage = "login.error.wronguser";
      } else {
        this.forminvalid = true;
        this.errormessage = "An unknown error occured.";
      }
    });
  }

  logout() {
    this.api.logout();
    location.reload();
  }

  openCopyrightDialog(): void {
    this.dialog.open(CopyrightdialogComponent, {
      width: "90%",
      data: {},
      panelClass: "copyright-dialog-container",
    });
  }
}
