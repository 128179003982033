<div id="int-content-topbar">
  <internal-menu-button id="menuBtn"></internal-menu-button>
  <button *ngIf="hasPerm('create_events')" mat-stroked-button (click)="newEvent()">
    {{ "internal.events.newEvent" | translate }}
  </button>
</div>

<div id="int-events-content">
  <div id="int-events-list" [ngClass]="{'hide': selectedEvent != undefined}">
    <mat-form-field id="search-field" appearance="outline">
      <input #searchInput matInput (keyup)="onFilterKeyUp($event.target.value)" (keydown.enter)="searchInput.blur()" placeholder="{{ 'general.search' | translate }}" />
      <button *ngIf="searchInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchInput.value=''; onFilterKeyUp('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div id="maintitle">{{ "events.nextEvents" | translate }}</div>

    <div class="event-list">
      <div *ngIf="filteredEvents.length == 0" id="event-list-empty">
        <div>{{ "events.currentNoEvents" | translate }}</div>
      </div>

      <div *ngFor="let event of filteredEvents" style="display: contents;">
        <event-item [event]="event" [location]="locationName(event.location)" (eventClicked)="selectEvent(event)"></event-item>
      </div>
    </div>

    <div *ngIf="filteredOldEvents.length > 0" id="maintitle" style="margin-top: 32px">{{ "events.oldEvents" | translate }}</div>

    <div *ngIf="filteredOldEvents.length > 0" class="event-list">
      <div *ngFor="let event of filteredOldEvents" style="display: contents;">
        <event-item [event]="event" [location]="locationName(event.location)" (eventClicked)="selectEvent(event)"></event-item>
      </div>
    </div>
  </div>

  <div class="spacer desktop"></div>

  <div id="event-details-maininfo" [ngClass]="{'hide': selectedEvent == undefined}">
    <div *ngIf="selectedEvent" style="display: contents;">
      <div id="title">
        <button id="event-back-btn" mat-icon-button (click)="selectEvent(undefined)"><mat-icon>arrow_back</mat-icon></button>
        <span>{{selectedEvent.name}}</span>
        <button *ngIf="hasPerm('edit_events')" (click)="editEvent(selectedEvent)" mat-icon-button><mat-icon>edit</mat-icon></button>
        <button *ngIf="hasPerm('edit_events') && hasPerm('create_finances')" (click)="startInvoice()" mat-icon-button><mat-icon>request_quote</mat-icon></button>
      </div>

      <div class="info">
        <mat-icon>schedule</mat-icon>
        {{selectedEvent.startdate | date:"dd MMM yyyy HH:mm"}} -
        {{selectedEvent.enddate | date:"dd MMM yyyy HH:mm"}}   •
        {{(selectedEvent.enddate - selectedEvent.startdate) / 1000 | timespan}}
      </div>

      <div class="info" *ngIf="selectedEvent.location">
        <mat-icon>location_on</mat-icon>
        {{locationName(selectedEvent.location)}}
      </div>

      <div class="info" *ngIf="selectedEvent.contact || selectedEvent.contactMail">
        <mat-icon>contact_mail</mat-icon>
        {{selectedEvent.contact}}
        <span *ngIf="selectedEvent.contact && selectedEvent.contactMail"> • </span>
        <span *ngIf="selectedEvent.contactMail" id="contactmail" (click)="openNewMail(selectedEvent.contactMail)">{{selectedEvent.contactMail}}</span>
      </div>
      <div class="info">
        <mat-icon>public</mat-icon>
        {{(selectedEvent.isPublic? "internal.events.public" : "internal.events.private") | translate}}
      </div>

      <div *ngIf="selectedEvent.description != ''" style="display: contents;">
        <div class="h-spacer"></div>
        <div class="subtitle">{{ "internal.events.description" | translate }}</div>
        <div>{{selectedEvent.description}}</div>
      </div>

      <div *ngIf="selectedEvent.equipment != ''" style="display: contents;">
        <div class="h-spacer"></div>
        <div class="subtitle">{{ "internal.events.equipment" | translate }}</div>
        <div>{{selectedEvent.equipment}}</div>
      </div>

      <div *ngIf="selectedEvent.files.length > 0" style="display: contents;">
        <div class="h-spacer"></div>
        <div class="subtitle">{{ "internal.events.documents" | translate }}</div>

        <document-grid [documents]="selectedEvent.files" [urlTemplate]="getDocumentUrl(selectedEvent._id)" [showDownload]="true" [showView]="true">

        </document-grid>
      </div>

      <!-- Finances of Event -->
      <div *ngIf="hasPerm('view_finances')" style="display: contents;">
        <div class="h-spacer"></div>
        <div class="subtitle">
          <span>{{ "internal.events.finances" | translate }}</span>
          <button *ngIf="hasPerm('create_finances')" (click)="newFinanceTransaction()" mat-icon-button><mat-icon>add</mat-icon></button>
        </div>

        <div *ngIf="eventFinances.length == 0" class="emptyList">
          {{ 'internal.events.noFinances' | translate }}
        </div>

        <div *ngIf="eventFinances.length > 0" class="finance-transaction-dummy-item">
          <div class="tli-header">
              {{ 'internal.events.financeTotal' | translate }}
          </div>
          <span *ngIf="anyFinancePending()" class="tli-pending large">{{ 'internal.finances.transaction.pending' | translate }}</span>
          <span *ngIf="anyFinancePending()" class="tli-pending small"><mat-icon>money_off</mat-icon></span>
          <span class="tli-amount" [ngClass]="{'negative': financeTotal() < 0}">{{financeTotal() >= 0? '' : '-'}} {{financeTotal().toFixed(2) | mask: 'separator':',' }}€</span>
        </div>

        <finance-transaction-list-item
            *ngFor="let trans of eventFinances"
            [users]="users"
            [transaction]="trans"
            [showEditButton]="hasPerm('edit_finances')"
            [showDeleteButton]="hasPerm('delete_finances')"
            [showEvent]="false"
            (edit)="editFinanceTransaction(trans)"
            (delete)="deleteFinanceTransaction(trans)"
            class="finance-transaction-item">
        </finance-transaction-list-item>
      </div>

      <!-- Inventory of Event -->
      <div *ngIf="hasPerm('view_inventory')" style="display: contents;">
        <div class="h-spacer"></div>
        <div class="subtitle">
          <span>{{ "internal.events.inventory" | translate }}</span>
          <button *ngIf="hasPerm('inventory_booking')" (click)="openBookingScanDialog()" mat-icon-button><mat-icon>add</mat-icon></button>
          <button *ngIf="hasPerm('inventory_booking')" (click)="openBookingImportDialog()" mat-icon-button><mat-icon>attach_file</mat-icon></button>
        </div>

        <div *ngIf="eventInventory.length == 0" class="emptyList">
          {{ 'internal.events.noInventory' | translate }}
        </div>

        <div id="inv-transaction-list">
          <inv-transaction-list-item *ngFor="let trans of eventInventory" [transaction]="trans" (changed)="selectEvent(selectedEvent)"></inv-transaction-list-item>
        </div>
      </div>

      <!-- Images of Event -->
      <div *ngIf="eventCategory && eventImages.length > 0" style="display: contents;">
        <div class="h-spacer"></div>
        <div class="subtitle">{{ "events.images" | translate }}</div>
        <ngx-masonry
          [options]="{ transitionDuration: '0.2s', gutter: 10, resize: true, initLayout: true, fitWidth: false }"
          [useImagesLoaded]="true">

          <div ngxMasonryItem *ngFor="let item of eventImages;" class="masonry-item" (click)="openImageDialog(item)">
            <img [src]="this.getThumbLink(item._id)" alt="Thumbnail: {{item.title}}"/> <!--  | useHttpImgSrc -->
          </div>
        </ngx-masonry>
      </div>
    </div>
  </div>
</div>
