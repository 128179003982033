import { DBModel } from "./DBModel";

export class TAGTodo extends DBModel {
  status: TodoStatus = TodoStatus.OPEN;
  priority: Priority = Priority.NORMAL;
  description: string = "";

  creator: string = "";
  assignee: string = "";

  createdate: Date = new Date();
  duedate: Date = null;
  notification: Date = null;

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.status != null) this.status = TodoStatus.fromName(data.status);
    if (data.priority != null) this.priority = Priority.fromName(data.priority);
    if (data.description != null) this.description = data.description;

    if (data.creator != null) this.creator = data.creator;
    if (data.assignee != null) this.assignee = data.assignee;

    if (data.createdate != null) this.createdate = new Date(data.createdate);
    if (data.duedate != null) this.duedate = new Date(data.duedate);
    if (data.notification != null) this.notification = new Date(data.notification);

    return this;
  }

  serialize(): any {
    var obj: any = super.serialize();

    obj.status = this.status.name;
    obj.priority = this.priority.name;
    obj.description = this.description;

    obj.creator = this.creator;
    obj.assignee = this.assignee;

    obj.createdate = this.createdate;
    obj.duedate = this.duedate;
    obj.notification = this.notification;

    return obj;
  }
}

export class TodoStatus {
  name: string = "";
  color: string;

  constructor(name: string, color: string) {
    this.name = name;
    this.color = color;
  }

  static fromName(name: string): TodoStatus {
    switch (name) {
      case "open":
        return this.OPEN;
      case "inprogress":
        return this.INPROGRESS;
      case "done":
        return this.DONE;
      case "canceled":
        return this.CANCELED;
      default:
        return null;
    }
  }

  static OPEN: TodoStatus = new TodoStatus("open", "#ffca28");
  static INPROGRESS = new TodoStatus("inprogress", "#29b6f6");
  static DONE = new TodoStatus("done", "#66bb6a");
  static CANCELED = new TodoStatus("canceled", "#757575");

  static ALL = [TodoStatus.OPEN, TodoStatus.INPROGRESS, TodoStatus.DONE, TodoStatus.CANCELED];
}

export class Priority {
  name: string;
  color: string;
  icon: string;

  constructor(name: string, color: string, icon: string) {
    this.name = name;
    this.color = color;
    this.icon = icon;
  }

  static fromName(name: string): Priority {
    switch (name) {
      case "low":
        return this.LOW;
      case "normal":
        return this.NORMAL;
      case "high":
        return this.HIGH;
      default:
        return null;
    }
  }

  static toValue(priority: Priority): number {
    switch (priority) {
      case this.LOW:
        return 1;
      case this.NORMAL:
        return 2;
      case this.HIGH:
        return 3;
      default:
        return 0;
    }
  }

  static LOW: Priority = new Priority("low", "#4caf50", "");
  static NORMAL: Priority = new Priority("normal", "#ffc107", "");
  static HIGH: Priority = new Priority("high", "#f44336", "");

  static ALL = [Priority.LOW, Priority.NORMAL, Priority.HIGH];
}
