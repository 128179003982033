import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { TAGInvItemGroup } from "../models/taginventory";
import { PrinterService } from "../printer.service";
import { GroupEditDialog } from "./groupEditDialog";
import { getInventoryInstanceByCode } from "./inventoryHelper";

export interface GroupListDialogData {}

@Component({
  selector: "int-inventory-grouplistdialog",
  templateUrl: "groupListDialog.html",
  styleUrls: ["./groupListDialog.scss"],
})
export class GroupListDialog {
  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  scannerForm = this.fb.group({
    input: [""],
  });

  filterText: string = "";
  filterItemID: string = "";
  filterInstID: string = "";

  groups: TAGInvItemGroup[] = [];

  constructor(
    private api: ApiService,
    private fb: UntypedFormBuilder,
    private printer: PrinterService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GroupListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: GroupListDialogData
  ) {}

  ngOnInit() {
    this.api.getInvItemGroups().then((result) => {
      this.groups = result;
    }).catch((reason) => {
      console.log(reason);
    });
  }

  ngAfterViewInit() {
    this.resetScannerField();
  }

  clearFilters() {
    this.filterText = "";
    this.filterItemID = "";
    this.filterInstID = "";
    this.scannerForm.setValue({ input: "" });
  }

  resetScannerField() {
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  filteredGroups(): TAGInvItemGroup[] {
    var filtered: TAGInvItemGroup[] = [];

    if (this.filterItemID == "" && this.filterInstID == "" && this.filterText == "") {
      filtered = this.groups;
    } else
      for (let group of this.groups) {
        if (this.filterItemID != "") {
          if (this.filterInstID != "") {
            if (group.containsItemInstance(this.filterItemID, this.filterInstID)) {
              if (filtered.findIndex((t) => t._id == group._id) == -1) filtered.push(group);
            }
          } else {
            if (group.containsItem(this.filterItemID)) {
              if (filtered.findIndex((t) => t._id == group._id) == -1) filtered.push(group);
            }
          }
        } else {
          if (
            group._id.indexOf(this.filterText.toLowerCase()) != -1 ||
            group.name.toLowerCase().indexOf(this.filterText.toLowerCase()) != -1
          ) {
            if (filtered.findIndex((t) => t._id == group._id) == -1) filtered.push(group);
          }
        }
      }

    return filtered.filter((group) => !group.deleted);;
  }

  showGroup(group: TAGInvItemGroup) {
    const dialogRef = this.dialog.open(GroupEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { group: group },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.delete) {
          this.groups = this.groups.filter((group) => group._id != result.group._id);
        } else {
          this.groups.splice(this.groups.findIndex((group) => group._id == result._id), 1, result);
        }
      } else {
        console.log("Cancelled");
      }

      this.resetScannerField();
    });
  }

  newGroup() {
    const dialogRef = this.dialog.open(GroupEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { group: new TAGInvItemGroup() },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && !result.delete) {
        this.groups.push(result);
      } else {
        console.log("Cancelled");
      }

      this.resetScannerField();
    });
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result != null) {
        if (result.error) {
          console.log(result.error);
        } else {
          this.codeScanned(result.code);
        }
      }
    });
  }

  codeScanned(code: string) {
    this.scannerForm.get("input")?.setValue(code);

    getInventoryInstanceByCode(code, this.api).then((result) => {
      let item = result.item;
      let instanceID = result.instanceID;

      console.log(result);

      if (item && instanceID) {
        this.filterText = "";
        this.filterItemID = item._id;
        this.filterInstID = instanceID;
      } else {
        this.filterText = "";
        this.filterItemID = item._id;
        this.filterInstID = "";
      }

      console.log("ItemID: " + this.filterItemID + " InstID: " + this.filterInstID + " Text: " + this.filterText);
    }).catch((reason) => {
      this.filterText = code;
      this.filterItemID = "";
      this.filterInstID = "";

      console.log("ItemID: " + this.filterItemID + " InstID: " + this.filterInstID + " Text: " + this.filterText);
    });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  printGroupLabel(group: TAGInvItemGroup) {
    if (!this.printer.isConnected())
      this.printer.connectToPrinter().then((result) => {
        this.printer.printGroupLabels([group]);
      }).catch((reason) => {
        console.error(reason);
      });
    else
      this.printer.printGroupLabels([group]);
  }

  printItemLabels(group: TAGInvItemGroup) {
    if (!this.printer.isConnected())
      this.printer.connectToPrinter().then((result) => {
        for (let item of group.items) {
          this.printSingleItemLabel(item.itemid, item.instanceid);
        }
      }).catch((reason) => {
        console.error(reason);
      });
    else
      for (let item of group.items) {
        this.printSingleItemLabel(item.itemid, item.instanceid);
      }
  }

  printSingleItemLabel(itemid: string, instanceid: string) {
    this.api.getInvItem(itemid).then((item) => {
      this.printer.printItemLabels(item, item.instances.filter((inst) => inst.itemid == instanceid));
    }).catch((reason) => {
      console.error(reason);
    });
  }
}
