<h2 mat-dialog-title>Scan Barcode</h2>
<mat-dialog-content class="mat-typography">
  <zxing-scanner
    #scanner
    [formats]="allowedFormats"
    [enable]="true"
    [autofocusEnabled]="true"
    [torch]="torch"
    (torchCompatible)="onTorchCompatible($event)"
    (camerasFound)="camerasFoundHandler($event)"
    (camerasNotFound)="camerasNotFoundHandler($event)"
    (scanSuccess)="scanSuccessHandler($event)"
    (scanError)="scanErrorHandler($event)"
    (scanFailure)="scanFailureHandler($event)"
    (scanComplete)="scanCompleteHandler($event)">
  </zxing-scanner>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="torchSupported" mat-icon-button (click)="this.torch = !this.torch">
    <mat-icon>flash_on</mat-icon>
  </button>

  <button mat-button [mat-dialog-close]="null">{{ "general.close" | translate }}</button>

  <mat-icon #successIcon class="success-icon"> check_circle </mat-icon>
</mat-dialog-actions>
