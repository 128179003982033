import { Component, OnInit } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { ApiService } from "../api.service";
import { Permission } from "../models/tagpermission";
import { TAGPresence } from "../models/tagpresence";
import { TAGUser } from "../models/taguser";

@Component({
  selector: "app-internal.presence",
  templateUrl: "./internal.presence.component.html",
  styleUrls: ["./internal.presence.component.scss"],
})
export class InternalPresenceComponent implements OnInit {
  schoolyears: Observable<number[]>;

  allPresence: TAGPresence[] = [];
  users: TAGUser[] = [];

  filteredPresence: { [year: string]: TAGPresence[] } = {};
  filteredUsers: { [year: string]: string[] } = {};

  constructor(private api: ApiService) {}

  getUserColor(presence: TAGPresence, user: string) {
    var alpha = this.canEdit(presence) ? "" : "99";
    return presence.getUserColor(user, alpha);
  }

  getUserIcon(presence: TAGPresence, user: string) {
    return presence.getUserIcon(user);
  }

  getPresenceColor(value: number) {
    return TAGPresence.getColor(value);
  }

  getPresenceIcon(value: number) {
    return TAGPresence.getIcon(value);
  }

  getUserName(username: string) {
    var usr = this.users.find((u) => u.username == username);
    if (usr != undefined) return usr.firstname + " " + usr.lastname;

    return username;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  canEdit(presence: TAGPresence) {
    return new Date().getTime() - presence.enddate.getTime() <= 24 * 60 * 60 * 1000 && this.api.hasPermission(Permission.edit_presence);
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.reloadPresence();
      this.api.getUsers().then((result) => {
        this.users = result;
      }).catch((reason) => {
        console.error(reason);
      });
    });
  }

  reloadPresence() {
    this.api.getPresences().then((result) => {
      this.allPresence = result.sort((a: TAGPresence, b: TAGPresence) => <any>a.startdate - <any>b.startdate);

      this.schoolyears = new Observable((observer: Observer<number[]>) => {
        var years = this.extractSchoolyears(this.allPresence);

        years.forEach((year) => {
          this.filteredPresence[year + ""] = this.filterYear(year);
          this.filteredUsers[year + ""] = this.filterUsers(year);
        });

        observer.next(years);
      });
    }).catch((reason) => {
      //TODO: Show Error
    });
  }

  getUserPercentage(user: string, year: number) {
    var presences = this.filterYear(year);
    var total = presences.length;
    var present = 0;

    presences.forEach((p) => {
      if ([1, 2, 3, 4].includes(p.presenceInfo[user])) present++;
      if ([5].includes(p.presenceInfo[user])) present += 0.75;
    });

    return Math.round((present / total) * 100).toFixed(0);
  }

  filterYear(year: number) {
    return this.allPresence.filter((p) => {
      return p.getSchoolYear() == year;
    });
  }

  filterUsers(year: number) {
    var users: string[] = [];

    this.filterYear(year).forEach((p) => {
      Object.keys(p.presenceInfo).forEach((key, val) => {
        if (!users.includes(key)) users.push(key);
      });
    });

    return users;
  }

  extractSchoolyears(presence: TAGPresence[]) {
    var years: number[] = [];
    presence.forEach((p) => {
      var y = p.getSchoolYear();
      if (!years.includes(y)) years.push(y);
    });
    return years;
  }
}
