import { ApiService } from "../api.service";
import { TAGInvItemType } from "../models/taginventory";

function extractItemInstanceID(barcode: string) {
    // Count the amount of dashes in code
    let dashCount = (barcode.match(/-/g) || []).length;

    if (dashCount == 1) {
        let itemID = barcode.split("-")[0].trim();
        let instanceID = barcode.split("-")[1].trim();

        return { itemID, instanceID };
    }

    return { itemID: undefined, instanceID: undefined };
}

export function getInventoryInstanceByCode(barcode: string, api: ApiService) {
    return new Promise<{item: TAGInvItemType, instanceID: string}>((resolve, reject) => {
        let { itemID, instanceID } = extractItemInstanceID(barcode);

        let promises = [];

        if (itemID && instanceID) {
            promises.push(new Promise<{item: TAGInvItemType, instanceID: string}>((resolve, reject) => {
                api.getInvItem(itemID).then((item) => {
                    resolve({ item, instanceID });
                }).catch((reason) => {
                    resolve(undefined);
                });
            }));
        }

        promises.push(new Promise<{item: TAGInvItemType, instanceID: string}>((resolve, reject) => {
            api.findInvItem(barcode).then((item) => {
                resolve({ item , instanceID: undefined});
            }).catch((reason) => {
                resolve(undefined);
            });
        }));

        Promise.all(promises).then((results) => {
            for (let result of results) {
                if (result) {
                    let item = result.item;

                    if (result.instanceID) {
                        resolve({item, instanceID: result.instanceID});
                    } else {
                        let instanceID = undefined;

                        for (let instance of item.instances) {
                            if (instance.barcodes.includes(barcode)) {
                                instanceID = instance.itemid;
                                break;
                            }
                        }

                        resolve({item, instanceID});
                    }

                    return;
                }
            }

            reject("No item found");
        }).catch((reason) => {
            reject(reason);
        });
    });
}

export function getLocalInventoryInstanceByCode(barcode: string, items: TAGInvItemType[]) {
    let { itemID, instanceID } = extractItemInstanceID(barcode);

    if (itemID) {
        let item = items.find((item) => item._id == itemID);
        if (item) {
            let instance = undefined;

            if (instanceID) {
                instance = item.instances.find((inst) => inst.itemid == instanceID);
            }

            return { item, instanceID: instance?.itemid };
        }
    } else {

        let item = items.find((item) => item._id == barcode);
        if (item) {
            return { item, instanceID: undefined };
        } else {
            let item = items.find((item) => item.instances.some((inst) => inst.barcodes.includes(barcode)));
            if (item) {
                let instance = item.instances.find((inst) => inst.barcodes.includes(barcode));
                return { item, instanceID: instance?.itemid };
            }
        }
    }

    return { item: undefined, instanceID: undefined };
}