import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { TAGInvTransaction } from "../models/taginventory";
import { getInventoryInstanceByCode } from "./inventoryHelper";

export interface TransactionListDialogData {}

@Component({
  selector: "int-inventory-translistdialog",
  templateUrl: "transactionListDialog.html",
  styleUrls: ["./transactionListDialog.scss"],
})
export class TransactionListDialog {
  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  scannerForm = this.fb.group({
    input: [""],
  });

  filterText: string = "";
  filterItemID: string = "";
  filterInstID: string = "";

  transactions: TAGInvTransaction[] = [];

  constructor(
    private api: ApiService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TransactionListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionListDialogData
  ) {}

  ngOnInit() {
    this.loadTransactions();
  }

  loadTransactions() {
    this.api.getInvTransactions().then((result) => {
      this.transactions = result;
      this.transactions.sort((a, b) => {
        return a.startdate > b.startdate ? -1 : 1;
      });
    }).catch((reason) => {
      console.log(reason);
    });
  }

  ngAfterViewInit() {
    this.resetScannerField();
  }

  clearFilters() {
    this.filterText = "";
    this.filterItemID = "";
    this.filterInstID = "";
    this.scannerForm.setValue({ input: "" });
  }

  resetScannerField() {
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  filteredTransactions(): TAGInvTransaction[] {
    var filtered: TAGInvTransaction[] = [];

    if (this.filterItemID == "" && this.filterInstID == "" && this.filterText == "") {
      filtered = this.transactions;
    } else
      for (let trans of this.transactions) {
        if (this.filterItemID != "") {
          if (this.filterInstID != "") {
            if (trans.containsItemInstance(this.filterItemID, this.filterInstID)) {
              if (filtered.findIndex((t) => t._id == trans._id) == -1) filtered.push(trans);
            }
          } else {
            if (trans.containsItem(this.filterItemID)) {
              if (filtered.findIndex((t) => t._id == trans._id) == -1) filtered.push(trans);
            }
          }
        } else {
          if (
            trans._id.indexOf(this.filterText.toLowerCase()) != -1 ||
            trans.location.toLowerCase().indexOf(this.filterText.toLowerCase()) != -1 ||
            trans.user.toLowerCase().indexOf(this.filterText.toLowerCase()) != -1
          ) {
            if (filtered.findIndex((t) => t._id == trans._id) == -1) filtered.push(trans);
          }
        }
      }

    return filtered;
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result != null) {
        if (result.error) {
          console.log(result.error);
        } else {
          this.codeScanned(result.code);
        }
      }
    });
  }

  codeScanned(code: string) {
    this.scannerForm.get("input")?.setValue(code);

    getInventoryInstanceByCode(code, this.api).then((result) => {
      let item = result.item;
      let instanceID = result.instanceID;

      console.log(result);

      if (item && instanceID) {
        this.filterText = "";
        this.filterItemID = item._id;
        this.filterInstID = instanceID;
      } else {
        this.filterText = "";
        this.filterItemID = item._id;
        this.filterInstID = "";
      }

      console.log("ItemID: " + this.filterItemID + " InstID: " + this.filterInstID + " Text: " + this.filterText);
    }).catch((reason) => {
      this.filterText = code;
      this.filterItemID = "";
      this.filterInstID = "";

      console.log("ItemID: " + this.filterItemID + " InstID: " + this.filterInstID + " Text: " + this.filterText);
    });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
