import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";
import { TAGInvItemType, TAGInvTransaction } from "../models/taginventory";
import { PrinterService } from "../printer.service";
import { TransactionEditDialog } from "./transactionEditDialog";

export interface MissingItemsDialogData {}

export interface MissingItem {
  item: TAGInvItemType;
  instanceID: string;
  transaction: TAGInvTransaction;
}

@Component({
  selector: "int-inventory-missingitemsdialog",
  templateUrl: "missingItemsDialog.html",
  styleUrls: ["./missingItemsDialog.scss"],
})
export class MissingItemsDialog {
  //TODO: Form with validation

  transactions: TAGInvTransaction[] = [];
  missingItems: MissingItem[] = [];
  itemTypes: TAGInvItemType[] = [];

  selectedItems: MissingItem[] = [];

  catControl = new FormControl("");
  filteredCategories: Observable<string[]>;

  constructor(
    private api: ApiService,
    private printer: PrinterService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MissingItemsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MissingItemsDialogData
  ) {}

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.api.getInvItems().then((allItems) => {
        this.api.getInvTransactions().then((allTransactions) => {
          this.transactions = allTransactions;
          for (let item of allItems.filter((item) => !item.deleted)) {
            for (let instance of item.instances.filter((instance) => !instance.deleted)) {
              if (!this.instanceStatus(item._id, instance.itemid)) {
                this.missingItems.push({
                  item: item,
                  instanceID: instance.itemid,
                  transaction: this.instanceTransaction(item._id, instance.itemid),
                });

                if (!this.itemTypes.includes(item)) this.itemTypes.push(item);
              }
            }
          }
        }).catch((reason) => {

        });
      }).catch((reason) => {
        console.error(reason);
      });
    });
  }

  getLatestTransaction(itemID: string, instanceID: string) {
    var filtered = this.transactions.filter((transaction) => transaction.findItem(itemID, instanceID) != null);
    filtered.sort((a, b) => {
      return a.startdate > b.startdate ? -1 : 1;
    });
    return filtered[0];
  }

  instanceStatus(itemID: string, instanceID: string) {
    var transaction = this.getLatestTransaction(itemID, instanceID);
    if (!transaction) return true;

    return transaction.findItem(itemID, instanceID).back != null;
  }

  instanceTransaction(itemID: string, instanceID: string) {
    return this.getLatestTransaction(itemID, instanceID);
  }

  showTransaction(transaction: TAGInvTransaction) {
    const dialogRef = this.dialog.open(TransactionEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { transaction: transaction },
    });
  }

  itemFilter(itemid: string) {
    return this.missingItems.filter((missingItem) => missingItem.item._id == itemid);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  instanceSelected(itemID: string, instanceID: string, checked: boolean) {
    var missingItem = this.missingItems.find((missingItem) => missingItem.item._id == itemID && missingItem.instanceID == instanceID);
    if (checked) {
      this.selectedItems.push(missingItem);
    } else {
      this.selectedItems = this.selectedItems.filter(
        (selectedItem) => selectedItem.item._id != missingItem.item._id || selectedItem.instanceID != missingItem.instanceID
      );
    }
  }

  printLabelsAll() {
    if (!this.printer.isConnected())
      this.printer
        .connectToPrinter()
        .then((result) => {
          for (let selItem of this.selectedItems) {
            this.printer.printItemLabels(selItem.item, [selItem.item.instances.find((instance) => instance.itemid == selItem.instanceID)]);
          }
        })
        .catch((reason) => {
          console.error(reason);
        });
    else
      for (let selItem of this.selectedItems) {
        this.printer.printItemLabels(selItem.item, [selItem.item.instances.find((instance) => instance.itemid == selItem.instanceID)]);
      }
  }
}
