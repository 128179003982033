<div class="transaction-item">
    <div class="tli-header">
        {{ 'internal.events.financeTotal' | translate }}
    </div>

    <span id="tli-amountPending">
        <span class="label">With Pending</span>
        <span class="value" [ngClass]="{'negative': totalPending < 0}">
            {{totalPending > 0? '' : '-'}} {{totalPending.toFixed(2) | mask: 'separator':',' }}€
        </span>
    </span>

    <span id="tli-amount">
        <span class="label">Without Pending</span>
        <span class="value" [ngClass]="{'negative': total < 0}">
            {{total > 0? '' : '-'}} {{total.toFixed(2) | mask: 'separator':',' }}€
        </span>
    </span>
</div>