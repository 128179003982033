import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { Permission } from "../models/tagpermission";
import { Priority, TAGTodo, TodoStatus } from "../models/tagtodo";
import { TAGUser } from "../models/taguser";
import { InternalMenuService } from "../internal-menu.service";
import { TodoEditDialog } from "./todoEditDialog";
import { MatSnackBar } from "@angular/material/snack-bar";

interface SelectedTodoData {
  element: HTMLElement;
  todo: TAGTodo;
  x: number;
  y: number;
}

@Component({
  selector: "app-internal.todos",
  templateUrl: "./internal.todos.component.html",
  styleUrls: ["./internal.todos.component.scss"],
})
export class InternalTodosComponent implements OnInit {
  @ViewChild("menu") menu : ElementRef;
  @ViewChildren("swimlane") swimlane: QueryList<ElementRef>;
  swimlanes: ElementRef[] = [];

  users: TAGUser[] = [];

  todos: TAGTodo[] = [];
  highlightedTodos: string[] = undefined;

  isDragging: boolean = false;
  reloadTimer: any = undefined;

  selectedTodo: SelectedTodoData;
  showTodoMenu: boolean = false;
  todoCopy: TAGTodo;
  menuItems: string[] = ["priority", "move"];
  selectedMenuItem: string = "priority";
  moveStartPosition: { x: number, y: number } = { x: 0, y: 0 };
  isMoving: boolean = false;

  constructor(private api: ApiService, public dialog: MatDialog, public menuSvc: InternalMenuService, public snackbar: MatSnackBar) {

  }

  onFilterKeyUp(filter: string) {
    console.log(filter);

    if (filter.trim() == "") {
      this.highlightedTodos = undefined;
      return;
    }

    this.highlightedTodos = this.todos.filter((t) => {
      return t.description.toLowerCase().includes(filter.toLowerCase()) ||
             this.userToName(t.assignee).toLowerCase().includes(filter.toLowerCase()) ||
             t.status.name.toLowerCase().includes(filter.toLowerCase()) ||
             t.priority.name.toLowerCase().includes(filter.toLowerCase());
    }).map((t) => t._id);
  }

  onKey(event: any) {
    console.log(event);
    if (!event.getModifierState("NumLock")) return;

    if (this.selectedTodo == undefined) {
      this.setSelected(0, 0);
      return;
    }

    this.setSelected(this.selectedTodo.x, this.selectedTodo.y);

    if (!this.showTodoMenu && !this.isMoving) {
      if (event.code === "Numpad2") {
        this.setSelected(this.selectedTodo.x, this.selectedTodo.y + 1);
        event.preventDefault();
      } else if (event.code === "Numpad8") {
        this.setSelected(this.selectedTodo.x, this.selectedTodo.y - 1);
        event.preventDefault();
      } else if (event.code === "Numpad4") {
        this.setSelected(this.selectedTodo.x - 1, this.selectedTodo.y);
        event.preventDefault();
      } else if (event.code === "Numpad6") {
        this.setSelected(this.selectedTodo.x + 1, this.selectedTodo.y);
        event.preventDefault();
      } else if (event.code === "Numpad5" || event.code === "NumpadEnter") {
        this.showTodoMenu = true;
        this.selectedMenuItem = this.menuItems[0];
        this.todoCopy = new TAGTodo(Object.assign({}, this.selectedTodo.todo.serialize()));
        event.preventDefault();
      }

    } else if (this.isMoving) {
      if (event.code === "Numpad4") {
        this.setTodoStatus(this.todos.find((t) => t._id == this.selectedTodo.todo._id), false);
        event.preventDefault();
      } else if (event.code === "Numpad6") {
        this.setTodoStatus(this.todos.find((t) => t._id == this.selectedTodo.todo._id), true);
        event.preventDefault();
      } else if (event.code === "Numpad5" || event.code === "NumpadEnter") {
        this.isMoving = false;
        this.saveTodo(this.selectedTodo.todo);
        event.preventDefault();
      } else if (event.code === "Numpad0") {
        this.isMoving = false;
        let index = this.todos.findIndex((t) => t._id == this.selectedTodo.todo._id);
        this.todos.splice(index, 1, this.todoCopy);
        this.selectedTodo.x = this.moveStartPosition.x;5
        this.selectedTodo.y = this.moveStartPosition.y;
        event.preventDefault();
      }

    } else {
      if (event.code === "Numpad0") {
        this.showTodoMenu = false;
        let index = this.todos.findIndex((t) => t._id == this.selectedTodo.todo._id);
        this.todos.splice(index, 1, this.todoCopy);
        event.preventDefault();
      } else if (event.code === "Numpad2") {
        this.selectMenuItem(true);
        event.preventDefault();
      } else if (event.code === "Numpad8") {
        this.selectMenuItem(false);
        event.preventDefault();
      } else if (event.code === "Numpad4") {
        if (this.selectedMenuItem == "priority") {
          this.setTodoPriority(this.todos.find((t) => t._id == this.selectedTodo.todo._id), false);
        }
        event.preventDefault();
      } else if (event.code === "Numpad6") {
        if (this.selectedMenuItem == "priority") {
          this.setTodoPriority(this.todos.find((t) => t._id == this.selectedTodo.todo._id), true);
        }
        event.preventDefault();
      } else if (event.code === "Numpad5" || event.code === "NumpadEnter") {
        if (this.selectedMenuItem == "move") {
          this.isMoving = true;
          this.showTodoMenu = false;

          this.moveStartPosition.x = this.selectedTodo.x;
          this.moveStartPosition.y = this.selectedTodo.y;
        } else {
          this.saveTodo(this.selectedTodo.todo);
          this.showTodoMenu = false;
        }
        event.preventDefault();
      }

      /* this.selectedTodo.element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });

      this.setMenuPosition(this.selectedTodo.element); */
    }
  }

  setSelected(x: number, y: number) {
    console.log(x, y);
    if (x < 0) x = 0;
    if (y <= 0) {
      y = 0;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (x > this.swimlanes.length - 1) x = this.swimlanes.length - 1;
    if (y > this.swimlanes[x].nativeElement.children.length - 2) y = this.swimlanes[x].nativeElement.children.length - 2;

    this.selectedTodo = {
      element: this.swimlanes[x].nativeElement.children[y + 1],
      todo: this.todos.find((t) => t._id == this.swimlanes[x].nativeElement.children[y + 1].getAttribute('data-todo')),
      x: x,
      y: y,
    };

    if (y > 0) {
      /* let windowTop = window.scrollY;
      let windowBottom = windowTop + window.innerHeight - 64;
      let todoTop = this.selectedTodo.element.getBoundingClientRect().top - 32;
      let todoBottom = this.selectedTodo.element.getBoundingClientRect().top + this.selectedTodo.element.getBoundingClientRect().height + 64;

      let scrollPos = 0;
      if (todoTop < windowTop) {
        scrollPos = todoTop - windowTop;
      } else if (todoBottom > windowBottom) {
        scrollPos = todoBottom - windowBottom;
      }

      if (scrollPos != 0) {
        window.scrollBy({
          top: scrollPos,
          behavior: "smooth",
        });
      } */

      this.selectedTodo.element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }

  selectMenuItem(next: boolean) {
    let currentIndex = this.menuItems.indexOf(this.selectedMenuItem);
    let newIndex = next ? currentIndex + 1 : currentIndex - 1;
    if (newIndex < 0) newIndex = 0;
    if (newIndex >= this.menuItems.length) newIndex = this.menuItems.length - 1;
    this.selectedMenuItem = this.menuItems[newIndex];
  }

  setTodoPriority(todo: TAGTodo, higher: boolean) {
    let newPrio = todo.priority;
    switch (newPrio) {
      case Priority.LOW:
        newPrio = higher ? Priority.NORMAL : Priority.LOW;
        break;
      case Priority.NORMAL:
        newPrio = higher ? Priority.HIGH : Priority.LOW;
        break;
      case Priority.HIGH:
        newPrio = higher ? Priority.HIGH : Priority.NORMAL;
        break;
    }

    todo.priority = newPrio;
  }

  setTodoStatus(todo: TAGTodo, higher: boolean) {
    let newStatus = todo.status;
    switch (newStatus) {
      case TodoStatus.OPEN:
        newStatus = higher ? TodoStatus.INPROGRESS : TodoStatus.OPEN;
        break;
      case TodoStatus.INPROGRESS:
        newStatus = higher ? TodoStatus.DONE : TodoStatus.OPEN;
        break;
      case TodoStatus.DONE:
        newStatus = higher ? TodoStatus.CANCELED : TodoStatus.INPROGRESS;
        break;
      case TodoStatus.CANCELED:
        newStatus = higher ? TodoStatus.CANCELED : TodoStatus.DONE;
        break;
    }

    todo.status = newStatus;

    if (higher && this.selectedTodo.x <= this.swimlanes.length - 1) {
      this.selectedTodo.x = this.selectedTodo.x + 1;
    } else if (!higher && this.selectedTodo.x > 0) {
      this.selectedTodo.x = this.selectedTodo.x - 1;
    }

    this.selectedTodo.y = this.filterByStatus(newStatus).findIndex((t) => t._id == todo._id);
  }

  tooltipPos() {
    if (!this.selectedTodo) return false;
    return this.filterByStatus(this.selectedTodo.todo.status).findIndex((t) => t._id == this.selectedTodo.todo._id) == 0;
  }

  saveTodo(todo: TAGTodo) {
    this.api.updateTodo(todo).then((result) => {
      this.reloadTodos();
    }).catch((reason) => {
      this.snackbar.open(reason.message, "OK", {
        duration: 5000
      });
    });
  }

  dragHandleDisabled() {
    return screen.width >= 1000;
  }

  allStatus() {
    return TodoStatus.ALL;
  }

  allPriorities() {
    return Priority.ALL;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  filterByStatus(status: TodoStatus) {
    return this.todos.filter((t) => t.status == status);
  }

  userToName(id: string) {
    return this.users.find((u) => u._id == id)?.fullname ?? "Deleted User";
  }

  duedateWarning(date: Date) {
    let diff = date.getTime() - new Date().getTime();
    if (diff < 0) {
      return "#f44336";
    } else if (diff < 1000 * 60 * 60 * 48) {
      return "#ff9800"; //#ffc107
    }
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.api.getUsers().then((result) => {
        this.users = result;
        this.reloadTodos();
      }).catch((reason) => {
        console.error(reason);
        this.reloadTodos();
      });

      this.toggleAutoReload({ checked: true });
    });
  }

  ngAfterViewInit(): void {
    this.swimlanes = this.swimlane.toArray();
    console.log(this.swimlanes);
  }

  ngOnDestroy(): void {
    if (this.reloadTimer) clearInterval(this.reloadTimer);
  }

  reloadTodos() {
    this.api.getTodos().then((result) => {
      if (this.isDragging || this.showTodoMenu || this.isMoving) return;

      this.todos = result.sort((a, b) => Priority.toValue(b.priority) - Priority.toValue(a.priority));

      if (this.selectedTodo) {
        this.selectedTodo.todo = this.todos.find((t) => t._id == this.selectedTodo.todo._id);
        let status = this.selectedTodo.todo.status;
        switch (status) {
          case TodoStatus.OPEN: this.selectedTodo.x = 0; break;
          case TodoStatus.INPROGRESS: this.selectedTodo.x = 1; break;
          case TodoStatus.DONE: this.selectedTodo.x = 2; break;
          case TodoStatus.CANCELED: this.selectedTodo.x = 3; break;
        }
        this.selectedTodo.y = this.filterByStatus(status).findIndex((t) => t._id == this.selectedTodo.todo._id);
      }

      console.log(this.todos);
    }).catch((reason) => {
      this.snackbar.open(reason.message, "OK", {
        duration: 5000
      })
    });
  }

  toggleAutoReload(event) {
    if (event.checked) {
      if (this.reloadTimer) clearInterval(this.reloadTimer);
      this.reloadTimer = setInterval(() => {
        this.reloadTodos();
      }, 5000);
    } else {
      if (this.reloadTimer) clearInterval(this.reloadTimer);
      this.reloadTimer = undefined;
    }
  }

  openTodo(todo: TAGTodo = new TAGTodo()) {
    const dialogRef = this.dialog.open(TodoEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { todo: todo, users: this.users },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) return;

      this.reloadTodos();
    });
  }

  startDrag(event) {
    this.isDragging = true;
  }

  endDrag(event) {
    this.isDragging = false;
  }

  drop(event) {
    console.log(event);
    let todo: TAGTodo = event.item.data;
    let targetState: TodoStatus = TodoStatus.fromName(event.container.data.name);

    if (todo.status == targetState) {
      return;
    }

    todo.status = targetState;

    this.api.updateTodo(todo).then((result) => {
      this.reloadTodos();
    }).catch((reason) => {
      console.error(reason);
    });
  }

}
