import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TAGInvItemType, TAGInvTransaction } from "../models/taginventory";
import { ItemInstanceCombo } from "./itemScanDialog";

export interface ItemBookDialogData {
  instances: ItemInstanceCombo[];
  eventid?: string;
  transactionName?: string;
}

@Component({
  selector: "int-inventory-itembookdialog",
  templateUrl: "itemBookDialog.html",
  styleUrls: ["./itemBookDialog.scss"],
})
export class ItemBookDialog {
  //TODO: Form with validation

  transaction: TAGInvTransaction = new TAGInvTransaction({});

  constructor(public dialogRef: MatDialogRef<ItemBookDialog>, @Inject(MAT_DIALOG_DATA) public data: ItemBookDialogData) {
    for (let combo of data.instances) {
      this.transaction.items.push({ itemid: combo.item._id, instanceid: combo.instanceID, back: null });
    }

    if (data.eventid) this.transaction.event = data.eventid;
    if (data.transactionName) this.transaction.location = data.transactionName;

    this.dialogRef.disableClose = true;

    console.log(this.transaction);
    console.log(data);
    console.log(this.itemTypes());
  }

  cancelEdit(): void {
    this.dialogRef.close(null);
  }

  saveEdit(): void {
    this.dialogRef.close(this.transaction);
  }

  itemTypes() {
    var types: TAGInvItemType[] = [];
    for (let combo of this.data.instances) {
      if (!types.find((type) => type._id == combo.item._id)) {
        types.push(combo.item);
      }
    }
    return types;
  }

  itemFilter(itemid: string) {
    return this.data.instances.filter((comb) => comb.item._id == itemid);
  }
}
