<div id="inv-content-topbar">
  <internal-menu-button id="menuBtn"></internal-menu-button>
  <button mat-stroked-button (click)="showTransactions()">{{ "internal.inventory.showTransactions" | translate }}</button>
  <button mat-stroked-button (click)="showMissingItems()">{{ "internal.inventory.showMissingItems" | translate }}</button>
  <button mat-stroked-button (click)="showGroups()">{{ "internal.inventory.showGroups" | translate }}</button>
  <button *ngIf="hasPerm('inventory_booking')" mat-stroked-button (click)="startInventoryCheck()">{{ "internal.inventory.startInventoryCheck" | translate }}</button>
  <button *ngIf="hasPerm('create_inventory_item')" mat-stroked-button (click)="newItem()">{{ "internal.inventory.newItem" | translate }}</button>
  <button *ngIf="hasPerm('create_inventory_item')" mat-stroked-button (click)="newItem(true)">{{ "internal.inventory.newItemBulk" | translate }}</button>
  <button *ngIf="hasPerm('inventory_booking')" mat-stroked-button (click)="openBookingScanDialog()">{{ "internal.inventory.booking" | translate }}</button>

  <div id="printer-status">
    <mat-icon *ngIf="!printerSupported()" class="nosupport" matTooltip="{{ 'internal.inventory.printer.noSupport' | translate }}">print</mat-icon>
    <mat-icon *ngIf="printerSupported() && !printerConnected()" class="notconnected" matTooltip="{{ 'internal.inventory.printer.notConnected' | translate }}" (click)="connectPrinter()">print</mat-icon>
    <mat-icon *ngIf="printerSupported() && printerConnected() && !printerPrinting()" class="connected" matTooltip="{{ 'internal.inventory.printer.connected' | translate }}">print</mat-icon>
    <mat-icon *ngIf="printerSupported() && printerConnected() && printerPrinting()" class="printing" matTooltip="{{ 'internal.inventory.printer.printing' | translate }}">print</mat-icon>
  </div>
</div>
<div id="inv-content">
  <form class="mobileScanner" [formGroup]="scannerForm" (ngSubmit)="scanCode()">
    <mat-form-field>
      <input #scannerInput matInput placeholder="{{ 'internal.inventory.scanner' | translate }}" formControlName="input" />
      <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
    </mat-form-field>
  </form>

  <div id="inv-categories" [ngClass]="{'hide': selectedCategory != undefined}">
    <div id="inv-categories-header">
      <div id="inv-categories-header-title">
        {{ "internal.inventory.categories" | translate }}
      </div>
    </div>
    <div id="inv-categories-list">
      <div *ngFor="let category of categories" class="inv-category" [class.active]="category == selectedCategory" (click)="selectCategory(category)">
        <div class="inv-category-name" [ngClass]="{'noitems': searchTerm != '' && countCategory(category) == 0}">
          {{ (category == "" ? "internal.inventory.noCategorySpecified" : category) | translate }}
          <span *ngIf="searchTerm != '' && countCategory(category) > 0">
            ({{countCategory(category)}})
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="v-spacer"></div>
  <div id="inv-items" [ngClass]="{'hide': selectedCategory == undefined}">
    <div id="inv-items-header">
      <button id="items-back-btn" mat-icon-button (click)="selectCategory(undefined)"><mat-icon>arrow_back</mat-icon></button>

      <div id="inv-items-header-title">
        {{ "internal.inventory.items" | translate }}
      </div>
      <div id="inv-items-header-search">
        <button class="desktop" mat-stroked-button [disabled]="selectedItems.length == 0" (click)="deleteSelected()">{{ "internal.inventory.deleteItems" | translate }}</button>
        <button class="mobile" mat-icon-button [disabled]="selectedItems.length == 0" (click)="deleteSelected()"><mat-icon>delete</mat-icon></button>

        <mat-form-field>
          <input matInput placeholder="{{ 'general.search' | translate }}" [(ngModel)]="searchTerm" />
          <button *ngIf="searchTerm != ''" matSuffix mat-icon-button (click)="searchTerm = ''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <form class="hide" [formGroup]="scannerForm" (ngSubmit)="scanCode()">
          <mat-form-field style="width: 80px">
            <input #scannerInput matInput placeholder="{{ 'internal.inventory.scanner' | translate }}" formControlName="input" />
            <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div id="inv-items-list">
      <div *ngFor="let item of filteredItems()" class="inv-item" [class.active]="selectedItems.includes(item._id)" (click)="selectItem(item)">
        <mat-checkbox [checked]="selectedItems.includes(item._id)" (change)="itemSelected(item._id, $event)" (click)="$event.stopPropagation()"></mat-checkbox>
        <div class="inv-item-name">{{ item.name }}</div>
        <div class="inv-item-addinfo">{{ item.additionalInfo }}</div>
      </div>
    </div>
  </div>
</div>
