<div class="transaction-item">
    <div class="tli-header">
        <span class="tli-date">{{transaction.date | date}}</span>
        <span class="tli-reason">{{transaction.reason}}</span>
    </div>
    <div class="tli-attachments">
        <div *ngIf="transaction.notes" class="tlia-item" [matTooltip]="transaction.notes">
            <mat-icon>info</mat-icon>
        </div>

        <div *ngFor="let f of transaction.files" class="tlia-item" [matTooltip]="f.fileName" (click)="$event.stopPropagation(); openDocument(transaction._id, f.fileName)">
            <mat-icon *ngIf="f.mimeType == 'application/pdf'">description</mat-icon>
            <mat-icon *ngIf="f.mimeType != 'application/pdf'">image</mat-icon>
        </div>
    </div>
    <span *ngIf="transaction.user" class="tli-user"><mat-icon>person</mat-icon> <span>{{getUserName(transaction.user)}}</span></span>
    <span *ngIf="transaction.event && showEvent" class="tli-event"><mat-icon>event</mat-icon> <span>{{getEventName(transaction.event)}}</span></span>
    <span *ngIf="transaction.pending" class="tli-pending large">{{ 'internal.finances.transaction.pending' | translate }}</span>
    <span *ngIf="transaction.pending" class="tli-pending small"><mat-icon>money_off</mat-icon></span>
    <span class="tli-amount" [ngClass]="{'negative': !transaction.isIncome}">{{transaction.isIncome? '' : '-'}} {{transaction.amount.toFixed(2) | mask: 'separator':',' }}€</span>
    <span class="tli-edit">
        <button *ngIf="showEditButton" mat-icon-button (click)="$event.stopPropagation(); editTransaction(transaction)">
            <mat-icon>edit</mat-icon>
        </button>
        <div *ngIf="!showEditButton" style="width: 40px; height: 40px;">

        </div>
        <button *ngIf="showDeleteButton" mat-icon-button color="warn" (click)="$event.stopPropagation(); deleteTransaction(transaction)">
            <mat-icon>delete</mat-icon>
        </button>
        <div *ngIf="!showDeleteButton" style="width: 40px; height: 40px;">

        </div>
    </span>
</div>