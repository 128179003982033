<h1 mat-dialog-title>{{ 'radio.suggestTitle' | translate}}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>{{ 'radio.suggestInput' | translate}}</mat-label>
    <input type="text" placeholder="{{ 'radio.suggestInput' | translate}}" matInput [(ngModel)]="query" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">{{ 'general.cancel' | translate}}</button>
  <button style="background-color: rgba(255, 255, 255, 0.2)" mat-button [mat-dialog-close]="query" cdkFocusInitial>{{ 'radio.suggest' | translate}}</button>
</div>
