import { DBModel } from "./DBModel";

export class TAGImage extends DBModel {
  title: string = "";
  description: string = "";
  owner: number = -1;
  isPublic: boolean = false;

  uploaddate: Date = new Date();
  categoryid: string = undefined;
  category?: string = "";

  watermark: boolean = true;

  constructor(info: any = {}) {
    super();
    this.deserialize(info);
  }

  serialize(): any {
    var obj: any = super.serialize();

    obj.title = this.title;
    obj.description = this.description;
    obj.owner = this.owner;
    obj.isPublic = this.isPublic;

    obj.uploaddate = this.uploaddate;
    obj.categoryid = this.categoryid;
    obj.category = this.category;

    obj.watermark = this.watermark;

    return obj;
  }

  deserialize(data: any) {
    if (data._id != undefined) this._id = data._id;
    if (data._rev != undefined) this._rev = data._rev;

    if (data.title != null) this.title = data.title;
    if (data.description != null) this.description = data.description;
    if (data.owner != null) this.owner = data.owner;
    if (data.isPublic != null) this.isPublic = data.isPublic;

    if (data.uploaddate != null) this.uploaddate = new Date(data.uploaddate);
    if (data.categoryid != null) this.categoryid = data.categoryid;
    if (data.category != null) this.category = data.category;

    if (data.watermark != null) this.watermark = data.watermark;
  }
}
