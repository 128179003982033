<div id="progress-wrapper">
  <div *ngFor="let step of data.steps" class="progress-item">
    <div class="progress-item-icon">
      <!-- Icons -->
      <!-- Skipped -->
      <svg *ngIf="step.state == 'skipped'" width="48" height="48" viewBox="-24,-24,48,48">
        <g fill="none" stroke="#7e7e7e" stroke-width="2" stroke-linecap="round" stroke-linejoin="miter" stroke-miterlimit="4" stroke-dasharray="">
          <circle cx="0" cy="0" r="23" />

          <g fill-opacity="1" fill="#ffffff">
            <path d="M-7,0h14" />
          </g>
        </g>
      </svg>

      <!-- Waiting Icon -->
      <svg *ngIf="step.state == 'waiting'" width="48" height="48" viewBox="-24,-24,48,48">
        <g
          fill="none"
          stroke="#7e7e7e"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="miter"
          stroke-miterlimit="4"
          stroke-dasharray="4.8,4.8">
          <circle cx="0" cy="0" r="23" />
        </g>
      </svg>

      <!-- Processing Icon -->
      <svg *ngIf="step.state == 'processing'" width="48" height="48" viewBox="-24,-24,48,48">
        <g
          fill="none"
          stroke="#ffffff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="miter"
          stroke-miterlimit="4"
          stroke-dasharray="4.8,4.8">
          <circle cx="0" cy="0" r="23" class="rotating" />
        </g>
      </svg>

      <!-- Error Icon -->
      <svg *ngIf="step.state == 'error'" width="48" height="48" viewBox="-24,-24,48,48">
        <g fill="none" stroke="#f44336" stroke-width="2" stroke-linecap="round" stroke-linejoin="miter" stroke-miterlimit="4" stroke-dasharray="">
          <circle cx="0" cy="0" r="23" />
          <g fill-opacity="1" fill="#ffffff">
            <path d="M-7,-7l14,14" />
            <path d="M-7,7l14,-14" />
          </g>
        </g>
      </svg>

      <!-- Success Icon -->
      <svg *ngIf="step.state == 'success'" width="48" height="48" viewBox="-24,-24,48,48">
        <g fill="none" stroke="#4caf50" stroke-width="2" stroke-linecap="round" stroke-linejoin="miter" stroke-miterlimit="4" stroke-dasharray="">
          <circle cx="0" cy="0" r="23" />
          <g fill-opacity="1" fill="#ffffff">
            <path d="M-6,-1l5,5" />
            <path d="M-1,4l7,-10" />
          </g>
        </g>
      </svg>
    </div>

    <!-- Has no Upload -> showing skip icon-->
    <div class="progress-item-content">
      <div class="progress-item-title">{{ step.title | translate }}</div>
      <div *ngIf="(!step.showProgress || step.state != 'processing') && step.state != 'error'" class="progress-item-subtitle">{{ step.subtitle | translate }}</div>
      <div *ngIf="(!step.showProgress || step.state != 'processing') && step.state == 'error'" class="progress-item-subtitle error">{{ step.subtitle | translate }}</div>
      <div *ngIf="step.showProgress && step.state == 'processing'" class="progress-item-bar">
        <div class="progress-item-bar-inner" [style.width.%]="step.progress"></div>
      </div>
    </div>
  </div>
</div>
