import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";

export interface BarcodeEditDialogData {
  barcodes: string[];
}

@Component({
  selector: "int-inventory-barcodeEditDialog",
  templateUrl: "barcodeEditDialog.html",
  styleUrls: ["./barcodeEditDialog.scss"],
})
export class BarcodeEditDialog {
  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  scannerForm = this.fb.group({
    input: [""],
  });

  barcodes: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BarcodeEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: BarcodeEditDialogData
  ) {}

  ngOnInit() {
    this.barcodes = this.data.barcodes || [];
  }

  ngAfterViewInit() {
    this.resetScannerField();
  }

  resetScannerField() {
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result != null) {
        if (result.error) {
          console.log(result.error);
        } else {
          this.codeScanned(result.code);
        }
      }
    });
  }

  codeScanned(code: string) {
    this.scannerForm.get("input")?.setValue(code);
    this.barcodes.push(code);
    this.resetScannerField();
    console.log(this.barcodes);
  }

  removeBarcode(index: number) {
    this.barcodes.splice(index, 1);
  }

  save() {
    this.dialogRef.close(this.barcodes);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
